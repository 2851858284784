import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";
import logo_for_qr from "./logo_for_qr.svg";

import "../../vendor/bootstrap.css";
import "../../vendor/new-age.min.css";

import Lottie from "react-lottie";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { QRCode } from "react-qr-svg";

import canvg from "canvg";
import ReactDOMServer from "react-dom/server";
import ReactDOM, { render } from "react-dom";

//import QRCode from "qrcode.react";

import { renderToStaticMarkup } from "react-dom/server";

import im from "./image.png";

import moment_time_zone from "moment-timezone";
import moment from "moment";

class AddNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      qr_screen: false,
      draw_number: "",
      draw: false,
      countdown: false,
      user_data: {},
      selected_program: "",
    };
  }

  componentDidMount() {
    /* var attendees_questions_template = {
      draw: true,
      email_required: false,
      general_prize_draw: "5",
      in_person_draw: true,
      questions: [
        [
          {
            question: "Overall, how satisfied were you with today’s workshop?"
          },
          {
            questionnaire_type: "MCQ"
          },
          [
            {
              choice: "Very satisfied"
            },
            {
              choice: "Satisfied"
            },
            {
              choice: "Neutral"
            },
            {
              choice: "Dissatisfied"
            },
            {
              choice: "Very dissatisfied"
            }
          ]
        ],
        [
          {
            question: "How did the session meet your expectations?"
          },
          {
            questionnaire_type: "MCQ"
          },
          [
            {
              choice: "Greatly exceeded my expectations"
            },
            {
              choice: "Exceeded my expectations"
            },
            {
              choice: "Met my expectations"
            },
            {
              choice: "Less than I expected"
            },
            {
              choice: "Much less than I expected"
            }
          ]
        ],
        [
          {
            question: "How informative and knowledgeable was the facilitator?"
          },
          {
            questionnaire_type: "MCQ"
          },
          [
            {
              choice: "Extremely informative"
            },
            {
              choice: "Very informative"
            },
            {
              choice: "Somewhat informative"
            },
            {
              choice: "Not so informative"
            },
            {
              choice: " Not at all informative"
            }
          ]
        ],
        [
          {
            question:
              "How clearly did your facilitator explain the course material?"
          },
          {
            questionnaire_type: "MCQ"
          },
          [
            {
              choice: "Extremely clearly"
            },
            {
              choice: "Very clearly"
            },
            {
              choice: "Somewhat clearly"
            },
            {
              choice: "Not so clearly"
            },
            {
              choice: "Not at all clearly"
            }
          ]
        ],
        [
          {
            question:
              "What was your favourite part of the session/ what did you find most valuable?"
          },
          {
            questionnaire_type: "Open Field"
          }
        ],
        [
          {
            question:
              "What was your least favourite part of the session/ what did you find least valuable?"
          },
          {
            questionnaire_type: "Open Field"
          }
        ],
        [
          {
            question:
              "How can we improve and make the session better for next time?"
          },
          {
            questionnaire_type: "Open Field"
          }
        ]
      ],
      subText:
        "How did you like the Power BI workshop? Tell us in this quick survey, and win a Starbucks gift card! (Psst! It takes less than 2 minutes to complete!)",
      surveyFor: "Student",
      survey_expiry: "Thursday, August 1, 2019 12:00 AM",
      type_of_email: "ryerson.ca",
      user_prize_draw: "5"
    };
    
    firebase
      .database()
      .ref(`QR_Code/questionnaire_templates/Ryerson/TRSM BootCamp/Workshops`)
      .update(attendees_questions_template);*/
    /*  var data_add = {
      "-abdsasfasdfijansd": {
        name: "Madhavi Tanotra",
        user_id: "dIM6ubGadvVDJIByzh2aq4GTWA62"
      },
      "-basdkojfnamsdsd": {
        name: "Tanya Skydan",
        user_id: "fvMSQUPacXe37EXNu0JXROC8KyC3"
      },
      "-casdjfnaksdfamskdf": {
        name: "Alethia Davis",
        user_id: "lruoxfkQqbVmHnQJj8WNbURGcFZ2"
      },
      "-djakfnsjkdfalsdf": {
        name: "Christine Mackay",
        user_id: "Q8XNEORgxPfrHT3FpaTNxCur8pk1"
      },
      "-ekasdnfaksdff": {
        name: "Ibiyemi Balogun",
        user_id: "d2h98v7tGUNDyJRmBXHjzax7Dsj1"
      }
    };

    firebase
      .database()
      .ref(
        `QR_Code/questionnaire_templates/Ryerson/TRSM BootCamp/`
      )
      .update(data_add);*/

    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function (snapshot) {
          var user_data = snapshot.val();
          this.setState({ user_data });
        }.bind(this)
      );

    /* var user = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`partner_user/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var database_val = snapshot.val();

          if (database_val !== undefined && database_val !== null) {
            var access = database_val.access;
            var company_or_institution_name =
              database_val.company_or_institution_name;
            firebase
              .database()
              .ref(
                `QR_Code/questionnaire/${company_or_institution_name}/${access}`
              )
              .on(
                "value",
                function(snap) {
                  var data_initial_pull = snap.val();
                  if (
                    data_initial_pull !== undefined &&
                    data_initial_pull !== null
                  ) {
                    this.setState({ data_initial_pull });
                  } else {
                    this.setState({ data_initial_pull });
                  }
                }.bind(this)
              );
          } else {
            alert("You do not have access to this page!");
            firebase
              .auth()
              .signOut()
              .then(
                function() {
                  console.log("success");
                },
                function(error) {
                  alert(error);
                }
              );
          }
        }.bind(this)
      );
      */
  }

  buttonSession({ data, data_id, array_id, data_initial_pull }) {
    var date_local = moment_time_zone()
      .tz("America/Toronto")
      .format("MM/DD/YYYY");

    var survey_expiry = moment(
      moment_time_zone().tz("America/Toronto").format()
    )
      .add(1, "days")
      .format("LLLL");

    confirmAlert({
      title: "Is this a new session?",
      message: "",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            firebase
              .database()
              .ref(
                `QR_Code/unique/${data[0].company_or_institution_name}/${data[0].department}/${data_initial_pull[array_id]}`
              )

              .once(
                "value",
                function (snap) {
                  const id = _.map(snap.val(), (val, uid) => {
                    return uid;
                  });

                  const value = _.map(snap.val(), (val, uid) => {
                    return val;
                  });

                  /*  console.log(snap.val());
                  console.log(
                    `QR_Code/unique/${data[0].company_or_institution_name}/${data[0].department}/${data_initial_pull[array_id]}`
                  );*/

                  if (value[0] !== undefined) {
                    if (value[0].current !== undefined) {
                      firebase
                        .database()
                        .ref(
                          `QR_Code/unique/${data[0].company_or_institution_name}/${data[0].department}/${data_initial_pull[array_id]}/${id[0]}/old`
                        )
                        .update(value[0].current)
                        .then(() => {
                          firebase
                            .database()
                            .ref(
                              `QR_Code/unique/${data[0].company_or_institution_name}/${data[0].department}/${data_initial_pull[array_id]}/${id[0]}/current`
                            )
                            .remove();
                        })
                        .catch(() => {
                          alert("Something Went Wrong! Please try again.");
                        });

                      firebase
                        .database()
                        .ref(
                          `QR_Code/questionnaire/${data[0].company_or_institution_name}/${data[0].department}/${data_initial_pull[array_id]}/${data_id}`
                        )
                        .update({ survey_expiry });

                      this.setState({
                        data,
                        qr_screen: true,
                      });
                    } else {
                      firebase
                        .database()
                        .ref(
                          `QR_Code/questionnaire/${data[0].company_or_institution_name}/${data[0].department}/${data_initial_pull[array_id]}/${data_id}`
                        )
                        .update({ survey_expiry });

                      this.setState({
                        data,
                        qr_screen: true,
                      });
                    }
                  } else {
                    firebase
                      .database()
                      .ref(
                        `QR_Code/questionnaire/${data[0].company_or_institution_name}/${data[0].department}/${data_initial_pull[array_id]}/${data_id}`
                      )
                      .update({ survey_expiry });

                    this.setState({
                      data,
                      qr_screen: true,
                    });
                  }
                }.bind(this)
              );
          },
        },
        {
          label: "No",
          onClick: () => {
            this.setState({
              data,
              qr_screen: true,
            });
          },
        },
      ],
    });
  }

  drawNowButtonPressed() {
    confirmAlert({
      title: "Do the draw!",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({
              draw_number: "",
              draw: false,
              countdown: true,
            });

            const { data } = this.state;

            var department_database = data[0].department;
            var survey_name_database = data[0].survey_name;
            var company_or_institution_name_database =
              data[0].company_or_institution_name;
            var survey_id =
              data[0].Dynamic_Link_Details.survey_id_for_QR_creation_push_id;
            firebase
              .database()
              .ref(
                `QR_Code/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/current/`
              )

              .once(
                "value",
                function (snapshot) {
                  var uniquie = _.map(snapshot.val(), (val, uid) => {
                    return { ...val };
                  });

                  if (uniquie.length === 0) {
                    alert("No participants yet!");
                  } else {
                    var randomenumber;

                    var checking;
                    var found;

                    do {
                      randomenumber = Math.floor(
                        Math.random() * Math.floor(200)
                      );
                      checking = randomenumber;
                      found = uniquie.find((element) => {
                        return checking === element.randomNumber;
                      });
                    } while (found === undefined);

                    this.setState({
                      draw_number: randomenumber,
                      draw: true,
                    });

                    setTimeout(() => {
                      this.setState({
                        countdown: false,
                      });
                    }, 6000); //10000);

                    if (found === undefined) {
                      console.log("good to go");
                    } else {
                      console.log("generate a new number");
                    }
                  }
                }.bind(this)
              );
          },
        },
        {
          label: "No",
          onClick: () => console.log("Click No"),
        },
      ],
    });
  }

  coundown_and_draw_render() {
    //countdown,playing_phone

    const defaultOptions_countdown = {
      loop: true,
      autoplay: true,
      animationData: require("./countdown.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    /*const defaultOptions_playing_phone = {
      loop: true,
      autoplay: true,
      animationData: require("./playing_phone.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const defaultOptions_fireworks = {
      loop: true,
      autoplay: true,
      animationData: require("./fireworks.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };*/

    if (this.state.countdown === true) {
      return (
        <div className="text-center">
          {/*<Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: require("./expire.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice"
                        }
                      }}
                      width="40%"
                    />*/}

          <Lottie options={defaultOptions_countdown} height={300} width={300} />

          {/*<Lottie
            options={defaultOptions_playing_phone}
            height={300}
            width={300}
         />*/}
        </div>
      );
    }

    return (
      <div className="text-center">
        {/*<Lottie options={defaultOptions_fireworks} height={300} width={300} />*/}
        <h1 style={{ color: "black", fontSize: 165, fontWeight: "bold" }}>
          {this.state.draw_number}
        </h1>
      </div>
    );
  }

  render_svg_to_pdf = async () => {
    //=> "/path/to/my-image.png"
    /*  <svg
    className="qr-screen-logo"
    viewBox="0 0 1379 746"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="A4">
        <g
          id="Group"
          transform="translate(0.000000, -11.000000)"
        >
          <g id="Rectangle">
            <use fill="#D8D8D8" fill-rule="evenodd"></use>
            <rect
              stroke="#979797"
              stroke-width="1"
              x="0.5"
              y="0.5"
              width="826"
              height="1178"
            ></rect>
          </g>
          <text
            id="INSERT-QR-HERE"
            font-family="Quicksand-Regular, Quicksand"
            font-size="72"
            font-weight="normal"
            letter-spacing="12"
            fill="#000000"
          >
            <tspan x="166.392" y="607">
              INSERT QR{" "}
            </tspan>
            <tspan x="298.56" y="691">
              HERE
            </tspan>
          </text>
        </g>
      </g>
    </g>
   
  </svg>*/
  };

  showDrawNowButton({ questionnaire_data }) {
    if (
      questionnaire_data.draw === false ||
      questionnaire_data.in_person_draw === false
    ) {
      return null;
    }

    return (
      <a
        onClick={() => {
          this.drawNowButtonPressed();
        }}
        className="btn_1 btn-outline_1 btn-xl_1 js-scroll-trigger"
      >
        Draw now!
      </a>
    );
  }

  render_SVG(link) {
    var size = 150;
    //`data:image/svg+xml,${svgString}`

    /*console.log(link);
    const canvas = document.getElementById("123456");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    console.log(pngUrl);*/

    /* const svgString = encodeURIComponent(
      renderToStaticMarkup(
        <svg>
          <QRCode value={link} renderAs="svg" level="Q" />
        </svg>
      )
    );

    console.log(svgString);*/
    /*
    return (
      <svg
        width="595px"
        height="842px"
        viewBox="0 0 595 842"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
        style="background: #FFFFFF;"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="A4">
            <image
              id="Bitmap"
              x="62"
              y="40"
              width="75"
              height="75"
              href=""
            ></image>
          </g>
        </g>
      </svg>
    );
*/
    /*  return (
<svg width="595px" height="842px" viewBox="0 0 595 842" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style="background: #FFFFFF;">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="A4">
        <image id="Bitmap" x="62" y="40" width="75" height="75" href=""></image>
        <image id="Bitmap" x="261" y="40" width="75" height="75" href=""></image>
        <image id="Bitmap" x="460" y="40" width="75" height="75" href=""></image>
        <image id="Bitmap" x="62" y="214" width="75" height="75" href=""></image>
        <image id="Bitmap" x="261" y="214" width="75" height="75" href=""></image>
        <image id="Bitmap" x="460" y="214" width="75" height="75" href=""></image>
        <image id="Bitmap" x="62" y="381" width="75" height="75"  href=""></image>
        <image id="Bitmap" x="261" y="381" width="75" height="75" href=""></image>
        <image id="Bitmap" x="460" y="381" width="75" height="75" href=""></image>
        <image id="Bitmap" x="62" y="548" width="75" height="75"  href=""></image>
        <image id="Bitmap" x="261" y="548" width="75" height="75" href=""></image>
        <image id="Bitmap" x="460" y="548" width="75" height="75" href=""></image>
        <image id="Bitmap" x="62" y="693" width="75" height="75"  href=""></image>
        <image id="Bitmap" x="261" y="693" width="75" height="75" href=""></image>
        <image id="Bitmap" x="460" y="693" width="75" height="75" href=""></image>
        </g>
    </g>
</svg>
  )*/

    return (
      <svg
        width="277px"
        height="228px"
        viewBox="0 0 277 228"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect id="path-1" x="0" y="0" width="277" height="228"></rect>
          <rect id="path-2" x="0" y="0" width="119" height="96" rx="8"></rect>
          <filter
            x="-4.6%"
            y="-17.2%"
            width="118.5%"
            height="122.9%"
            filterUnits="objectBoundingBox"
            id="filter-3"
          >
            <feOffset
              dx="11"
              dy="-11"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feComposite
              in="shadowOffsetOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowOffsetOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
              type="matrix"
              in="shadowOffsetOuter1"
            ></feColorMatrix>
          </filter>
          <rect id="path-4" x="75" y="161" width="183" height="29"></rect>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="A4">
            <g id="Group">
              <g id="Rectangle-10">
                <use fill="#FFFFFF" fill-rule="evenodd" href="#path-1"></use>
                <rect
                  stroke="#000000"
                  stroke-width="1"
                  x="0.5"
                  y="0.5"
                  width="276"
                  height="227"
                ></rect>
              </g>
              <g id="BOX" transform="translate(125.000000, 19.000000)">
                <g id="Rectangle">
                  <use
                    fill="black"
                    fill-opacity="1"
                    filter="url(#filter-3)"
                    href="#path-2"
                  ></use>
                  <use fill="#FFFFFF" fill-rule="evenodd" href="#path-2"></use>
                  <rect
                    stroke="#000000"
                    stroke-width="1"
                    x="0.5"
                    y="0.5"
                    width="118"
                    height="95"
                    rx="8"
                  ></rect>
                </g>

                <QRCode
                  x="-100"
                  y="-10"
                  value={link} //"http://facebook.github.io/react/"
                  // renderAs="svg"
                  level="Q"
                />
              </g>
              <text
                id="Your-feedback-helps"
                font-family="LucidaGrande-Bold, Lucida Grande"
                font-size="21"
                font-weight="bold"
                fill="#000000"
              >
                <tspan x="20" y="33">
                  Your{" "}
                </tspan>
                <tspan x="20" y="57">
                  feedback{" "}
                </tspan>
                <tspan x="20" y="81">
                  helps us{" "}
                </tspan>
                <tspan x="20" y="105">
                  improve
                </tspan>
              </text>
              <g id="Rectangle-10">
                <use fill="#000000" fill-rule="evenodd" href="#path-4"></use>
                <rect
                  stroke="#000000"
                  stroke-width="1"
                  x="75.5"
                  y="161.5"
                  width="182"
                  height="28"
                ></rect>
              </g>
              <text
                id="Powered-by"
                font-family="LucidaGrande, Lucida Grande"
                font-size="15"
                font-weight="normal"
                fill="#000000"
              >
                <tspan x="119.608594" y="208">
                  Powered
                </tspan>
                <tspan x="180.963574" y="208" font-family="AlNile, Al Nile">
                  {" "}
                </tspan>
                <tspan x="184.113574" y="208">
                  b
                </tspan>
                <tspan x="193.554492" y="208" letter-spacing="12">
                  y
                </tspan>
              </text>
              <text
                id="survey.getluup.com"
                font-family="LucidaGrande, Lucida Grande"
                font-size="18"
                font-weight="normal"
                fill="#FFFFFF"
              >
                <tspan x="81.1044922" y="180">
                  survey.getluup.com
                </tspan>
              </text>
              <text
                id="Scan-the-QR-code,-or"
                font-family="LucidaGrande, Lucida Grande"
                font-size="16"
                font-weight="normal"
                fill="#000000"
              >
                <tspan x="40.3320312" y="153">
                  Scan the QR code, or go on
                </tspan>
              </text>
              <text
                id="Your-feedback-helps"
                font-family="LucidaGrande-Bold, Lucida Grande"
                font-size="21"
                font-weight="bold"
                fill="#000000"
              >
                <tspan x="20" y="33">
                  Your{" "}
                </tspan>
                <tspan x="20" y="57">
                  feedback{" "}
                </tspan>
                <tspan x="20" y="81">
                  helps us{" "}
                </tspan>
                <tspan x="20" y="105">
                  improve
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    );

    return (
      <svg
        width="277px"
        height="228px"
        viewBox="0 0 277 228"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <rect id="path-1" x="0" y="0" width="277" height="228"></rect>
          <rect id="path-2" x="0" y="0" width="119" height="96" rx="8"></rect>
          <filter
            x="-4.6%"
            y="-17.2%"
            width="118.5%"
            height="122.9%"
            filterUnits="objectBoundingBox"
            id="filter-3"
          >
            <feOffset
              dx="11"
              dy="-11"
              in="SourceAlpha"
              result="shadowOffsetOuter1"
            ></feOffset>
            <feComposite
              in="shadowOffsetOuter1"
              in2="SourceAlpha"
              operator="out"
              result="shadowOffsetOuter1"
            ></feComposite>
            <feColorMatrix
              values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 1 0"
              type="matrix"
              in="shadowOffsetOuter1"
            ></feColorMatrix>
          </filter>
          <rect id="path-4" x="75" y="161" width="183" height="29"></rect>
        </defs>
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
        >
          <g id="A4">
            <g id="Group">
              <g id="Rectangle-10">
                <use fill="#FFFFFF" fill-rule="evenodd" href="#path-1"></use>
                <rect
                  stroke="#000000"
                  stroke-width="1"
                  x="0.5"
                  y="0.5"
                  width="276"
                  height="227"
                ></rect>
              </g>
              <g id="BOX" transform="translate(125.000000, 19.000000)">
                <g id="Rectangle">
                  <use
                    fill="black"
                    fill-opacity="1"
                    filter="url(#filter-3)"
                    href="#path-2"
                  ></use>
                  <use fill="#FFFFFF" fill-rule="evenodd" href="#path-2"></use>
                  <rect
                    stroke="#000000"
                    stroke-width="1"
                    x="0.5"
                    y="0.5"
                    width="118"
                    height="95"
                    rx="8"
                  ></rect>
                </g>
              </g>
              <text
                id="QR"
                font-family="LucidaGrande-Bold, Lucida Grande"
                font-size="21"
                font-weight="bold"
                fill="#000000"
              >
                <tspan x="167" y="73">
                  <QRCode
                    value={link} //"http://facebook.github.io/react/"
                    // renderAs="svg"
                    level="Q"
                  />
                </tspan>
              </text>
              <text
                id="Your-feedback-helps"
                font-family="LucidaGrande-Bold, Lucida Grande"
                font-size="21"
                font-weight="bold"
                fill="#000000"
              >
                <tspan x="20" y="33">
                  Your{" "}
                </tspan>
                <tspan x="20" y="57">
                  feedback{" "}
                </tspan>
                <tspan x="20" y="81">
                  helps us{" "}
                </tspan>
                <tspan x="20" y="105">
                  improve
                </tspan>
              </text>
              <g id="Rectangle-10">
                <use fill="#000000" fill-rule="evenodd" href="#path-4"></use>
                <rect
                  stroke="#000000"
                  stroke-width="1"
                  x="75.5"
                  y="161.5"
                  width="182"
                  height="28"
                ></rect>
              </g>
              <text
                id="Powered-by"
                font-family="LucidaGrande, Lucida Grande"
                font-size="15"
                font-weight="normal"
                fill="#000000"
              >
                <tspan x="119.608594" y="208">
                  Powered
                </tspan>
                <tspan x="180.963574" y="208" font-family="AlNile, Al Nile">
                  {" "}
                </tspan>
                <tspan x="184.113574" y="208">
                  b
                </tspan>
                <tspan x="193.554492" y="208" letter-spacing="12">
                  y
                </tspan>
              </text>
              <text
                id="survey.getluup.com"
                font-family="LucidaGrande, Lucida Grande"
                font-size="18"
                font-weight="normal"
                fill="#FFFFFF"
              >
                <tspan x="81.1044922" y="180">
                  survey.getluup.com
                </tspan>
              </text>
              <text
                id="Scan-the-QR-code,-or"
                font-family="LucidaGrande, Lucida Grande"
                font-size="16"
                font-weight="normal"
                fill="#000000"
              >
                <tspan x="40.3320312" y="153">
                  Scan the QR code, or go on
                </tspan>
              </text>
              <text
                id="Your-feedback-helps"
                font-family="LucidaGrande-Bold, Lucida Grande"
                font-size="21"
                font-weight="bold"
                fill="#000000"
              >
                <tspan x="20" y="33">
                  Your{" "}
                </tspan>
                <tspan x="20" y="57">
                  feedback{" "}
                </tspan>
                <tspan x="20" y="81">
                  helps us{" "}
                </tspan>
                <tspan x="20" y="105">
                  improve
                </tspan>
              </text>
            </g>
          </g>
        </g>
      </svg>
    );

    return (
      <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size}>
        <rect width={size} height={size} fill="#269" />
        <g fill="#6494b7">
          <rect width={size} height={1} y={20} />
          <rect width={size} height={1} y={40} />
          <rect width={size} height={1} y={60} />
          <rect width={size} height={1} y={80} />
          <rect width={1} height={size} x={20} />
          <rect width={1} height={size} x={40} />
          <rect width={1} height={size} x={60} />
          <rect width={1} height={size} x={80} />
        </g>
        <QRCode
          value={link} //"http://facebook.github.io/react/"
          // renderAs="svg"
          level="Q"
        />
        <rect
          width={size}
          height={size}
          fill="none"
          strokeWidth={2}
          stroke="#fff"
        />
      </svg>
    );
  }

  render() {
    const { user_data } = this.state;
    if (user_data.all_programs !== undefined) {
      const data_initial_pull_val = _.map(
        this.state.data_initial_pull,
        (val, uid) => {
          return val;
        }
      );

      const data_initial_pull = _.map(
        this.state.data_initial_pull,
        (val, uid) => {
          return uid;
        }
      );
      var options = [];
      if (data_initial_pull.length !== undefined) {
        for (var i = 0; i < data_initial_pull.length; i++) {
          var optionValue = data_initial_pull[i];
          options.push(<option value={i}>{optionValue}</option>);
        }
      }
      const { data } = this.state;

      const all_programs = _.map(user_data.all_programs, (val, uid) => {
        return val;
      });

      var list_of_programs = [];
      for (var p = 0; p < all_programs.length; p++) {
        list_of_programs.push(
          <option value={all_programs[p].access}>
            {all_programs[p].access}
          </option>
        );
      }

      if (this.state.selected_program === "") {
        const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50">
           <circle cx="25" cy="25" r="20"/>
        </svg>`;

        const styles = StyleSheet.create({
          page: {
            flexDirection: "row",
          },
          image: {
            width: "100%",
            padding: 10,
            backgroundColor: "red",
          },
          textWrapper: {
            width: "40%",
            height: "100%",
            backgroundColor: "#f0f0f0",
            paddingHorizontal: 50,
            paddingVertical: 30,
          },
          text: {
            color: "red",
          },
        });

        return (
          <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div className="text-center">
                      <img
                        src={logo_for_qr}
                        className="qr-screen-logo"
                        alt="logo"
                      />

                      <div
                        className="site-subscribe"
                        style={{
                          paddingTop: 10,
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 mx-auto text-center">
                              <div>
                                <div className="form-group">
                                  <label style={{ paddingBottom: 2 }}>
                                    Select the Program:
                                  </label>
                                  <select
                                    className="form-control"
                                    value={this.state.selected_program}
                                    onChange={(event) => {
                                      event.preventDefault();

                                      this.setState({
                                        selected_program: event.target.value,
                                      });
                                      var user = firebase.auth().currentUser;

                                      firebase
                                        .database()
                                        .ref(`partner/users/${user.uid}/`)
                                        .on(
                                          "value",
                                          function (snapshot) {
                                            var database_val = snapshot.val();

                                            firebase
                                              .database()
                                              .ref(
                                                `QR_Code/questionnaire/${database_val.company_or_institution_name}/${event.target.value}`
                                              )
                                              .on(
                                                "value",
                                                function (snap) {
                                                  var data_initial_pull = snap.val();
                                                  if (
                                                    data_initial_pull !==
                                                      undefined &&
                                                    data_initial_pull !== null
                                                  ) {
                                                    this.setState({
                                                      data_initial_pull,
                                                    });
                                                  } else {
                                                    this.setState({
                                                      data_initial_pull,
                                                    });
                                                  }
                                                }.bind(this)
                                              );
                                          }.bind(this)
                                        );
                                    }}
                                  >
                                    <option selected value="">
                                      Select....
                                    </option>
                                    {list_of_programs}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        );
      } else {
        if (this.state.qr_screen === true) {
          var link;
          if (
            this.state.data.length !== 0 &&
            this.state.data.length !== undefined
          ) {
            link = this.state.data[this.state.data.length - 1]
              .Dynamic_Link_Details.QR_Code_Link;
          }

          return (
            <header className="masthead_login">
              <div className="container h-100">
                <div className="row h-100">
                  <div className="col-lg-12 my-auto">
                    <div className="header-content mx-auto">
                      <div className="text-center">
                        <h1>YOUR FEEDBACK</h1>
                        <h2>{data[0].survey_name}</h2>
                        <div className="site-subscribe">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 mx-auto text-center">
                                <div className="device-container">
                                  <div className="device-mockup iphone6_plus portrait white">
                                    <div className="device">
                                      <div className="screen">
                                        {this.state.draw ? (
                                          <div className="shadow-lg p-3 mb-5 bg-white rounded">
                                            {this.coundown_and_draw_render()}
                                          </div>
                                        ) : (
                                          <div
                                            className="text-center"
                                            style={{
                                              padding: 15,

                                              backgroundColor: "white",
                                            }}
                                          >
                                            <QRCode
                                              id="123456"
                                              bgColor="#FFFFFF"
                                              fgColor="#000000"
                                              level="Q"
                                              className="size_qr"
                                              // style={{ width: 500 }}
                                              cellClassPrefix="my-qr-code"
                                              value={link}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1>survey.getluup.com</h1>
                          <div style={{ paddingTop: 15 }}>
                            {this.showDrawNowButton({
                              questionnaire_data: data[0],
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          );

          /* return (
            <header className="masthead">
              <div className="container h-100">
                <div className="row h-100">
                  <div className="col-lg-7 my-auto">
                    <div className="header-content mx-auto">
                      <div style={{ paddingBottom: 5 }}>
                        <img
                          src={logo_for_qr}
                          className="qr-screen-logo"
                          alt="logo"
                        />
                      </div>

                      <h1 className="mb-5">YOUR FEEDBACK MATTERS</h1>
                      <h2 className="mb-5">{data[0].survey_name}</h2>

                      {this.showDrawNowButton({ questionnaire_data: data[0] })}
                    </div>
                    <div style={{ paddingTop: 55 }} className="row"></div>
                  </div>
                  <div className="col-lg-5 my-auto">
                    <div className="device-container">
                      <div className="device-mockup iphone6_plus portrait white">
                        <div className="device">
                          <div className="screen">
                            {this.state.draw ? (
                              <div //className="countdown_and_draw_style"
                                className="shadow-lg p-3 mb-5 bg-white rounded"
                              >
                                {this.coundown_and_draw_render()}
                              </div>
                            ) : (
                              <div
                                className="text-center"
                                style={{
                                  padding: 15,

                                  backgroundColor: "white"
                                }}
                              >
                                <QRCode
                                  bgColor="#FFFFFF"
                                  fgColor="#000000"
                                  level="Q"
                                  className="size_qr"
                                  // style={{ width: 500 }}
                                  cellClassPrefix="my-qr-code"
                                  value={link}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          );*/
        }

        return (
          <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div className="text-center">
                      <img
                        src={logo_for_qr}
                        className="qr-screen-logo"
                        alt="logo"
                      />

                      <div
                        className="site-subscribe"
                        style={{
                          paddingTop: 10,
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 mx-auto text-center">
                              <div>
                                <div className="form-group">
                                  <label style={{ paddingBottom: 2 }}>
                                    Select the Program:
                                  </label>
                                  <select
                                    className="form-control"
                                    value={this.state.selected_program}
                                    onChange={(event) => {
                                      event.preventDefault();

                                      this.setState({
                                        selected_program: event.target.value,
                                      });
                                      /* var data = _.map(
                                        data_initial_pull_val[
                                          event.target.value
                                        ],
                                        (val, uid) => {
                                          return val;
                                        }
                                      );

                                      this.setState({ data, qr_screen: true });*/
                                    }}
                                  >
                                    <option selected value="">
                                      Select....
                                    </option>
                                    {list_of_programs}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 mx-auto text-center">
                              <div>
                                <div className="form-group">
                                  <label style={{ paddingBottom: 2 }}>
                                    Select Event/Workshop:
                                  </label>
                                  <select
                                    className="form-control"
                                    value={this.state.view_selection}
                                    onChange={(event) => {
                                      event.preventDefault();

                                      var data = _.map(
                                        data_initial_pull_val[
                                          event.target.value
                                        ],
                                        (val, uid) => {
                                          return val;
                                        }
                                      );

                                      var data_id = _.map(
                                        data_initial_pull_val[
                                          event.target.value
                                        ],
                                        (val, uid) => {
                                          return uid;
                                        }
                                      );

                                      if (
                                        data[data.length - 1]
                                          .Dynamic_Link_Details === undefined
                                      ) {
                                        confirmAlert({
                                          title: "Your QR is on its way!",
                                          message:
                                            "Refresh this page in a minute, and your shiny new QR should be ready to go!",
                                          buttons: [
                                            {
                                              label: "Okay",
                                              onClick: () => {
                                                console.log("Okay");
                                              },
                                            },
                                          ],
                                        });
                                      } else {
                                        this.buttonSession({
                                          data,
                                          data_id,
                                          array_id: event.target.value,
                                          data_initial_pull,
                                        });
                                        /*
                                       this.setState({
                                          data,
                                          qr_screen: true
                                        });
                                        */
                                      }
                                    }}
                                  >
                                    <option selected value="">
                                      Select....
                                    </option>
                                    {options}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        );
      }
    }
    return (
      <header className="masthead_login">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-12 my-auto">
              <div className="header-content mx-auto">
                <div className="text-center">
                  <img
                    src={logo_for_qr}
                    className="qr-screen-logo"
                    alt="logo"
                  />

                  <div
                    className="site-subscribe"
                    style={{
                      paddingTop: 10,
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 mx-auto text-center">
                          <div>
                            <div className="form-group">
                              <p style={{ color: "white" }}>
                                Your access is still in pending. Please ask your
                                manager/supervisor for authorization
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default AddNotification;
