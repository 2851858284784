import _ from "lodash";
import React, { Component } from "react";
import Footer from ".././Footer";
import * as firebase from "firebase";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import moment_time_zone from "moment-timezone";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
var timestamp;

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Results
  </span>
);

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
      data_array: [],
      initial_data_pull: {},
      MCQ_Array: [],
      Open_Field_Array: [],
      MCQ_Array_temp_answers: [],
      Open_Field_Array_temp_answers: [],
      MCQ_Array_col_answers_count: [],
      MCQ_Array_col_answers_shrinked: [],
      MCQ_Questions: [],
      Open_Field_Array_col_answers: [],
      Open_Field_Questions: [],
      store_selected: "",
      name_of_the_survey: "",

      data_initial_pull: {},
      second_pull_all_questionnaire: null,
      view_selection: "",
      user_data: {},
      selected_program: "",
      filter_name: "",
    };
  }

  componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var user_data = snapshot.val();

          if (user_data.QR_Only === true) {
            this.setState({
              selected_program: user_data.company_or_institution_name,
            });
            firebase
              .database()
              .ref(
                `QR_Code/Survey_Submitted/${user_data.company_or_institution_name}/Store Number/`
              )
              .on(
                "value",
                function(snappy) {
                  var data = snappy.val();

                  this.setState({ data_initial_pull: data });
                }.bind(this)
              );

            firebase
              .database()
              .ref(
                `QR_Code/Survey_Submitted/${user_data.company_or_institution_name}/Store Number/${user_data.access}/all_questionnaire`
              )
              .on(
                "value",
                function(snap) {
                  var data = snap.val();

                  this.setState({ data });

                  /*   if (data !== undefined && data !== null) {
                    this.setState({ data });
                  } else {
                    this.setState({ data });
                  }*/
                }.bind(this)
              );
          }

          this.setState({ user_data });
        }.bind(this)
      );
  }

  goBackToDashboard() {
    this.setState({
      data: {},
      data_array: [],
      initial_data_pull: {},
      MCQ_Array: [],
      Open_Field_Array: [],
      MCQ_Array_temp_answers: [],
      Open_Field_Array_temp_answers: [],
      MCQ_Array_col_answers_count: [],
      MCQ_Array_col_answers_shrinked: [],
      MCQ_Questions: [],
      Open_Field_Array_col_answers: [],
      Open_Field_Questions: [],
      store_selected: "",
      name_of_the_survey: "",
      data_initial_pull: {},
      second_pull_all_questionnaire: null,
      view_selection: "",
      selected_program: "",
      filter_name: "",
    });
  }

  selection_for_program() {
    const { user_data } = this.state;
    const all_programs = _.map(user_data.all_programs, (val, uid) => {
      return val;
    });

    var list_of_programs = [];
    for (var p = 0; p < all_programs.length; p++) {
      list_of_programs.push(
        <option value={all_programs[p].access}>{all_programs[p].access}</option>
      );
    }
    return (
      <div>
        <div className="form-group">
          <label>Select the Program:</label>
          <select
            className="form-control"
            value={this.state.selected_program}
            onChange={(event) => {
              event.preventDefault();

              var user = firebase.auth().currentUser;

              firebase
                .database()
                .ref(`partner/users/${user.uid}`)
                .on(
                  "value",
                  function(snapshot) {
                    var database_val = snapshot.val();

                    var company_or_institution_name =
                      database_val.company_or_institution_name;
                    firebase
                      .database()
                      .ref(
                        `QR_Code/Survey_Submitted/${company_or_institution_name}/${event.target.value}`
                      )
                      .on(
                        "value",
                        function(snap) {
                          var data_initial_pull = snap.val();
                          if (
                            data_initial_pull !== undefined &&
                            data_initial_pull !== null
                          ) {
                            this.setState({ data_initial_pull });
                          } else {
                            this.setState({ data_initial_pull });
                          }
                        }.bind(this)
                      );
                  }.bind(this)
                );

              this.setState({
                selected_program: event.target.value,
              });
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            {list_of_programs}
          </select>
        </div>
      </div>
    );
  }

  selection_for_displaying_data() {
    return (
      <div>
        <div className="form-group">
          <label>Select how you want to view the results:</label>
          <select
            className="form-control"
            value={this.state.view_selection}
            onChange={(event) => {
              event.preventDefault();
              if (event.target.value === "All") {
                //
                this.setState({
                  store_selected: "All",
                });
              } else {
                //
                this.setState({
                  data: this.state.data.By_Date,
                });
              }

              //this.state.data
              this.setState({
                view_selection: event.target.value,
                //   store_selected: "",
                //   data: {}
              });
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            <option value="All">All surveys to date</option>
            <option value="By_Date">Select surveys results by date</option>
          </select>
        </div>
      </div>
    );
  }
  top_portion() {
    return (
      <div>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a>Dashboard</a>
          </li>
        </ol>
      </div>
    );
  }
  filter_name_function() {
    if (this.state.filter_name !== "") {
      return <li class="breadcrumb-item active">{this.state.filter_name}</li>;
    }
    return null;
  }
  render_time_stamp() {
    return null;
  }
  render() {
    var todays_time_stamp = moment_time_zone()
      .tz("America/Toronto")
      .valueOf();

    var linked_card_time_stamp = moment_time_zone()
      .tz("America/Toronto")
      .valueOf();

    var todays_time_ADD_one_day = moment().valueOf();

    var isAfter = moment(todays_time_ADD_one_day).isAfter(
      linked_card_time_stamp
    );

    const { user_data } = this.state;
    //if (user_data.all_programs !== undefined) {
    // if (user_data.dashboard_access.access === true) {
    if (this.state.selected_program === "") {
      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container">{this.top_portion()}</div>

            <div className="container">
              <div className="row">
                <div class="col-xl-12 col-sm-12 mb-12"></div>
              </div>
            </div>

            <Footer />
          </div>
        </React.Fragment>
      );
    } else {
      const data_initial_pull_val = _.map(
        this.state.data_initial_pull,
        (val, uid) => {
          return val;
        }
      );

      //Initial Pull Start
      const data_initial_pull = _.map(
        this.state.data_initial_pull,
        (val, uid) => {
          return uid;
        }
      );

      var options_data_initial_pull = [];
      if (data_initial_pull.length !== undefined) {
        for (var i = 0; i < data_initial_pull.length; i++) {
          var optionValue_data_initial_pull = data_initial_pull[i];

          if (optionValue_data_initial_pull === "All_Responses_Combined") {
            options_data_initial_pull.push(
              <option value={"All Responses Combined," + i}>
                All Responses Combined
              </option>
            );
          } else {
            options_data_initial_pull.push(
              <option value={optionValue_data_initial_pull + "," + i}>
                {optionValue_data_initial_pull}
              </option>
            );
          }
        }
      }

      //Second Pull All Questionnaire End

      //END PULL START
      const data = _.map(this.state.data, (val, uid) => {
        return uid;
      });
      var options = [];
      if (data.length !== undefined) {
        for (var i = 0; i < data.length; i++) {
          var optionValue = data[i];
          if (this.state.view_selection === "By_Date") {
            options.push(
              <option value={optionValue}>
                {moment(parseInt(optionValue)).format("MMMM Do YYYY")}
              </option>
            );
          } else {
            options.push(<option value={optionValue}>{optionValue}</option>);
          }
        }
      }

      //END PULL END

      if (this.state.store_selected === "") {
        if (this.state.view_selection === "") {
          return (
            <React.Fragment>
              <div id="content-wrapper">
                <div class="container">{this.top_portion()}</div>
                <div className="container">
                  <div className="row">
                    <div class="col-xl-12 col-sm-12 mb-12">
                      {this.selection_for_displaying_data()}
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </React.Fragment>
          );
        } else {
          if (this.state.view_selection != "All") {
            return (
              <React.Fragment>
                <div id="content-wrapper">
                  <div class="container">{this.top_portion()}</div>
                  <div className="container">
                    <div className="row">
                      <div class="col-xl-12 col-sm-12 mb-12">
                        {this.selection_for_displaying_data()}

                        <div className="form-group">
                          <label>Select Date:</label>
                          <select
                            className="form-control"
                            onChange={(event) => {
                              event.preventDefault();
                              // console.log(event.target.value);
                              this.setState({
                                store_selected: event.target.value,
                                filter_name: moment(
                                  parseInt(event.target.value)
                                ).format("MMMM Do YYYY"),
                              });
                            }}
                          >
                            <option selected value="">
                              Please Choose
                            </option>
                            {options}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Footer />
                </div>
              </React.Fragment>
            );
          }
          return null;
        }
      } else {
        const { store_selected } = this.state;

        function isLargeNumber(element) {
          return element === store_selected;
        }

        var data_index = data.findIndex(isLargeNumber);

        const data_existing_offers_array = _.map(
          this.state.data,
          (val, uid) => {
            return { ...val, uid };
          }
        );

        var initial_data_pull = data_existing_offers_array[data_index];

        const data_existing_offers = _.map(initial_data_pull, (val, uid) => {
          return { ...val, uid };
        });

        timestamp = data_existing_offers[0].survey_submitted_on;

        var data_array = [];
        for (let i = 0; i < data_existing_offers.length - 1; i++) {
          var questionnaire_result =
            data_existing_offers[i].questionnaire_result;
          data_array.push(questionnaire_result);
        }

        var array_question = [];
        var array_answer = [];
        var question_1, question_2, question_3, question_4, question_5;
        var answer_1, answer_2, answer_3, answer_4, answer_5;
        var type_1, type_2, type_3, type_4, type_5;

        var question_added = false;

        var Open_Field_Questions = [];
        var Open_Field_Array_row_answers = [];
        var Open_Field_Array_col_answers = [];
        var MCQ_Questions = [];
        var MCQ_Array_row_answers_count = [];
        var MCQ_Array_col_answers_count = [];
        var MCQ_Array_col_answers_shrinked = [];

        var Open_Field_Array = [];
        var MCQ_Array = [];

        if (data_array.length !== 0) {
          if (data_existing_offers.length - 1 === data_array.length) {
            const unique = (value, index, self) => {
              return self.indexOf(value) === index;
            };

            var survey_length = data_array.map((x) => x.length);
            var count_MCQ = 0;
            var count_Open_Field = 0;
            for (let x = 0; x < data_array.length; x++) {
              for (let p = 0; p < survey_length[x]; p++) {
                if (data_array[x][p].type_of_question === "Open Field") {
                  var question_data_1 = data_array[x][p].question;
                  Open_Field_Array.push(question_data_1);
                }
                if (data_array[x][p].type_of_question === "MCQ") {
                  var question_data_2 = data_array[x][p].question;
                  MCQ_Array.push(question_data_2);
                }
              }
            }

            if (data_existing_offers.length - 1 === data_array.length) {
              MCQ_Array = MCQ_Array.filter(unique);
              Open_Field_Array = Open_Field_Array.filter(unique);

              var survey_length = data_array.map((x) => x.length);

              var MCQ_Array_row_answers = [];
              var MCQ_Array_col_answers = [];

              for (let q = 0; q < MCQ_Array.length; q++) {
                MCQ_Array_row_answers = [];

                for (let x = 0; x < data_array.length; x++) {
                  for (let p = 0; p < survey_length[x]; p++) {
                    if (data_array[x][p].question === MCQ_Array[q]) {
                      var data_1 = data_array[x][p].answer;
                      MCQ_Array_row_answers.push(data_1);
                    }
                  }
                }

                MCQ_Array_col_answers.push(MCQ_Array_row_answers);

                var MCQ_Array_row_answers_shrinked = MCQ_Array_row_answers.filter(
                  unique
                );

                MCQ_Array_row_answers_shrinked = MCQ_Array_row_answers_shrinked.sort();

                MCQ_Array_col_answers_shrinked.push(
                  MCQ_Array_row_answers_shrinked
                );
              }

              Open_Field_Array_row_answers = [];
              Open_Field_Array_col_answers = [];
              var open_field_count = 0;
              for (let g = 0; g < Open_Field_Array.length; g++) {
                open_field_count = 0;
                Open_Field_Array_row_answers = [];
                for (let x = 0; x < data_array.length; x++) {
                  for (let p = 0; p < survey_length[x]; p++) {
                    if (data_array[x][p].question === Open_Field_Array[g]) {
                      var data_2 = data_array[x][p].answer;
                      Open_Field_Array_row_answers.push({
                        id: open_field_count + 1,
                        response: data_2,
                      });
                      open_field_count++;
                    }
                  }
                }
                Open_Field_Array_col_answers.push(Open_Field_Array_row_answers);
              }

              for (let d = 0; d < MCQ_Array.length; d++) {
                MCQ_Array_row_answers_count = [];

                var array_elements = MCQ_Array_col_answers[d];
                var MCQ_Array_col_answers_shrinked_sorted = MCQ_Array_col_answers_shrinked[
                  d
                ].sort();
                array_elements.sort();

                var current = null;
                var cnt = 0;
                for (var i = 0; i < array_elements.length; i++) {
                  if (array_elements[i] != current) {
                    if (cnt > 0) {
                      MCQ_Array_row_answers_count.push(cnt);
                    }
                    current = array_elements[i];
                    cnt = 1;
                  } else {
                    cnt++;
                  }
                }
                if (cnt > 0) {
                  MCQ_Array_row_answers_count.push(cnt);
                }

                MCQ_Array_col_answers_count.push(MCQ_Array_row_answers_count);
              }
            }

            MCQ_Questions = MCQ_Array;
            Open_Field_Questions = Open_Field_Array;
          }
        }

        var item = [];
        var written_input_table = [];

        var item = [];
        var written_input_table = [];
        var array_color = [];
        var sum_array_number_of_responses;
        var label_in_percentage = [];
        if (data_existing_offers.length - 1 === data_array.length) {
          if (
            MCQ_Questions.length !== 0 &&
            MCQ_Array_col_answers_count !== 0 &&
            MCQ_Array_col_answers_shrinked !== 0
          ) {
            for (var i = 0; i < MCQ_Questions.length; i++) {
              array_color = [];
              label_in_percentage = [];

              sum_array_number_of_responses = MCQ_Array_col_answers_count[
                i
              ].reduce((a, b) => a + b, 0);

              for (
                var p = 0;
                p < MCQ_Array_col_answers_shrinked[i].length;
                p++
              ) {
                var average_of_response;
                average_of_response =
                  MCQ_Array_col_answers_count[i][p] /
                  sum_array_number_of_responses;

                label_in_percentage.push(
                  MCQ_Array_col_answers_shrinked[i][p] +
                    " (" +
                    Math.round(average_of_response * 100) +
                    "%)"
                );

                if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Very satisfied" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Greatly exceeded my expectations" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Extremely informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Extremely clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Highly Likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "It was exceptional!" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Very Satisfied"
                ) {
                  array_color.push("#3EDDD0"); //("#388E3C");
                } else if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Satisfied" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Exceeded my expectations" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Very informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Very clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "It was good"
                ) {
                  array_color.push("#15A5BB"); //("#81C784");
                } else if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Neutral" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Neither" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Met my expectations" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Somewhat informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Somewhat clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Somewhat Likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "It was neither good nor bad" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Neither Satisfied nor Dissatisfied"
                ) {
                  array_color.push("#0F79B5"); //("#FFF176");
                } else if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Dissatisfied" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Less than I expected" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Not so informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Not so clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Not very likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "It was bad" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Dissatisfied" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Unlikely"
                ) {
                  array_color.push("#0055C2"); //("#E57373");
                } else {
                  array_color.push("#3608C2"); //("#D32F2F");
                }
                /* if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Very satisfied" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Greatly exceeded my expectations" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Extremely informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Extremely clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Excellent" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Extremely likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Strongly Agree" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Yes" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Too long"
                ) {
                  array_color.push("#3EDDD0"); //("#388E3C");
                } else if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Satisfied" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Exceeded my expectations" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Very informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Very clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Good" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Agree" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "No" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Too short"
                ) {
                  array_color.push("#15A5BB"); //("#81C784");
                } else if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Neutral" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Met my expectations" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Somewhat informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Somewhat clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Fair" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Somewhat likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Neutral" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "About right"
                ) {
                  array_color.push("#0F79B5"); //("#FFF176");
                } else if (
                  MCQ_Array_col_answers_shrinked[i][p] === "Dissatisfied" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Less than I expected" ||
                  MCQ_Array_col_answers_shrinked[i][p] ===
                    "Not so informative" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Not so clearly" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Poor" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Not very likely" ||
                  MCQ_Array_col_answers_shrinked[i][p] === "Disagree"
                ) {
                  array_color.push("#0055C2"); //("#E57373");
                } else {
                  array_color.push("#3608C2"); //("#D32F2F");
                }*/
              }
              //  console.log(sum_array_number_of_responses);
              item.push(
                <div class="col-lg-6" key={i}>
                  <div className="card mb-3">
                    <div className="card-header">
                      <h3>{MCQ_Questions[i]}</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <Doughnut
                          data={{
                            labels: label_in_percentage, //MCQ_Array_col_answers_shrinked[i],
                            datalabels: {
                              color: "black",
                            },
                            datasets: [
                              {
                                data: MCQ_Array_col_answers_count[i], //label_in_percentage, //MCQ_Array_col_answers_count[i],
                                backgroundColor: array_color,
                              },
                            ],
                          }}
                          options={{ responsive: true }}
                        />
                      </div>
                    </div>
                    <div className="card-footer small text-muted">
                      Multiple Choice Response
                    </div>
                  </div>
                </div>
              );
            }
          }

          if (
            Open_Field_Array_col_answers.length !== 0 &&
            Open_Field_Questions.length !== 0
          ) {
            for (var r = 0; r < Open_Field_Questions.length; r++) {
              var len_open_field = Open_Field_Array_col_answers[r].length;

              written_input_table.push(
                <div key={r}>
                  <div style={{ paddingRight: 5, paddingLeft: 5 }}>
                    <div className="card mb-3">
                      <div className="card-header">
                        <h3>{Open_Field_Questions[r]}</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive">
                          <div style={{ paddingBottom: 85 }}>
                            <ToolkitProvider
                              keyField="Open_Field"
                              data={Open_Field_Array_col_answers[r]}
                              columns={[
                                {
                                  dataField: "id",
                                  text: "ID",
                                  sort: true,
                                },
                                {
                                  dataField: "response",
                                  text: "Response",
                                  sort: true,
                                },
                              ]}
                              search
                              exportCSV
                            >
                              {(props) => (
                                <div
                                  style={{
                                    paddingRight: 20,
                                    paddingLeft: 20,
                                  }}
                                >
                                  <SearchBar {...props.searchProps} />
                                  <hr />
                                  <ExportCSVButton
                                    {...props.csvProps}
                                    style={{ backgroundColor: "gray" }}
                                  >
                                    Export CSV!!
                                  </ExportCSVButton>
                                  <hr />
                                  <BootstrapTable
                                    {...props.baseProps}
                                    pagination={paginationFactory({
                                      paginationSize: 4,
                                      pageStartIndex: 0,
                                      // alwaysShowAllBtns: true, // Always show next and previous button
                                      // withFirstAndLast: false, // Hide the going to First and Last page button
                                      // hideSizePerPage: true, // Hide the sizePerPage dropdown always
                                      // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
                                      firstPageText: "First",
                                      prePageText: "Back",
                                      nextPageText: "Next",
                                      lastPageText: "Last",
                                      nextPageTitle: "First page",
                                      prePageTitle: "Pre page",
                                      firstPageTitle: "Next page",
                                      lastPageTitle: "Last page",
                                      showTotal: true,
                                      paginationTotalRenderer: customTotal,
                                      sizePerPageList: [
                                        {
                                          text: "5",
                                          value: 5,
                                        },
                                        {
                                          text: "10",
                                          value: 10,
                                        },
                                        {
                                          text: "All",
                                          value: 100, //Open_Field_Questions.length
                                        },
                                      ], // A numeric array is also available. the purpose of above example is custom the text
                                    })}
                                  />
                                </div>
                              )}
                            </ToolkitProvider>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer small text-muted">
                        Open Field Response
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          }
        }

        //   console.log(this.state.name_of_the_survey);

        return (
          <React.Fragment>
            <div id="content-wrapper">
              <div class="container">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a style={{ color: "#0277BD", cursor: "pointer" }}>
                      Dashboard
                    </a>
                  </li>
                  <li class="breadcrumb-item active">Survey Results</li>

                  {this.filter_name_function()}
                </ol>
              </div>

              {this.render_time_stamp()}

              <div class="container">
                <div style={{ paddingBottom: 25 }}>
                  <div
                    className="card-header"
                    style={{
                      paddingBottom: 25,
                      borderRadius: 10,
                      borderWidth: 1,
                    }}
                  >
                    <h3>Multiple Choice Response</h3>
                  </div>
                </div>
                <div class="row">{item}</div>
              </div>

              <div class="container">
                <div style={{ paddingBottom: 25 }}>
                  <div
                    className="card-header"
                    style={{
                      paddingBottom: 25,
                      borderRadius: 10,
                      borderWidth: 1,
                    }}
                  >
                    <h3>Open Field Response</h3>
                  </div>
                </div>
                {written_input_table}
              </div>

              <Footer />
            </div>
          </React.Fragment>
        );
      }
    }
    /*  } else {
        return (
          <React.Fragment>
            <div id="content-wrapper">
              <div class="container text-center">
                <p style={{ color: "red" }}>
                  You don't have permission for this page at this moment. Please
                  ask your manager/supervisor for authorization
                </p>
              </div>

              <Footer />
            </div>
          </React.Fragment>
        );
      }
    }
    return (
      <React.Fragment>
        <div id="content-wrapper">
          <div class="container text-center">
            <p style={{ color: "red" }}>
              Your access is still in pending. Please ask your
              manager/supervisor for authorization
            </p>
          </div>

          <Footer />
        </div>
      </React.Fragment>
    );*/
  }
}

export default Dashboard;
