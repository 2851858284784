import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "bootstrap/dist/css/bootstrap.css";
import "./vendor/fontawesome-free/css/all.min.css";
/*import "./vendor/sb-admin.css";*/
import * as firebase from "firebase";

//import "./vendor/sb-admin-2.css";

/*import "./vendor/chart.js/Chart.min.js";
import "./vendor/demo/datatables-demo.js";
import "./vendor/demo/chart-area-demo.js";*/
// Initialize Firebase
var config = {
  apiKey: "AIzaSyBVdd6YjprvexpP2FWF4Horp6IP_e-lh0M",
  authDomain: "luup-4a75f.firebaseapp.com",
  databaseURL: "https://luup-4a75f.firebaseio.com",
  projectId: "luup-4a75f",
  storageBucket: "luup-4a75f.appspot.com",
  messagingSenderId: "917644898278"
};
//Productions
/*
const config = {
  apiKey: "AIzaSyCWI25kXgjKR4bDwNGe0qirLWC-vMV-S6s",
  authDomain: "luuptesting.firebaseapp.com",
  databaseURL: "https://luuptesting.firebaseio.com",
  projectId: "luuptesting",
  storageBucket: "luuptesting.appspot.com",
  messagingSenderId: "7826707952"
}; //Development
*/
firebase.initializeApp(config);

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
