import _ from "lodash";
import React, { Component } from "react";
import Footer from "./Footer";
import * as firebase from "firebase";
import moment from "moment";
import { Button, Modal, Form } from "react-bootstrap";
import "../vendor/sb-admin.css";
import logo_for_login from "./logo_for_login.svg";
import Login from "./Login";

class ForgotPassword extends Component {
  //    this.handle_submit = this.handle_submit.bind(this);
  constructor(props, context) {
    super(props, context);
    this.handle_submit = this.handle_submit.bind(this);

    this.state = {
      email: "",
      password: "",
      user: {},
      back_to_login_page: false
    };
  }

  handle_submit = event => {
    event.preventDefault();

    const { email, password } = this.state;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...

        alert(errorMessage);
      });
  };

  render() {
    const { back_to_login_page } = this.state;

    if (back_to_login_page === true) {
      return <Login />;
    }
    return (
      <React.Fragment>
        <header className="masthead_login">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <div id="content-wrapper">
                    <div>
                      <div className="container">
                        <div className="card card-login mx-auto mt-5">
                          <div className="text-center">
                            <img
                              src={logo_for_login}
                              className="qr-screen-logo"
                              alt="logo"
                            />
                          </div>
                          <div className="card-header text-center">
                            <p style={{ color: "black", paddingTop: 5 }}>
                              Forgot my Password
                            </p>
                          </div>
                          <div className="card-body">
                            <form role="form" onSubmit={this.handle_submit}>
                              <div className="form-group">
                                <div className="form-label-group">
                                  <div className="form-group">
                                    <div className="form-group">
                                      <label style={{ color: "black" }}>
                                        Email address
                                      </label>
                                      <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email address"
                                        required
                                        onChange={event => {
                                          event.preventDefault();
                                          this.setState({
                                            email: event.target.value
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                              >
                                Forgot my Password
                              </button>
                            </form>
                            <div style={{ paddingTop: 5 }}>
                              <button
                                //    type="submit"
                                className="btn btn-light btn-block"
                                onClick={() => {
                                  this.setState({
                                    signUp: false,
                                    forgotPassword: false
                                  });
                                }}
                              >
                                Back to Login
                              </button>
                            </div>
                          </div>
                          <div
                            style={{ paddingTop: 2.5 }}
                            className="text-center"
                          >
                            <p style={{ color: "black", fontSize: 13 }}>
                              Luup Partner Portal <br />
                              ©Copyright 2019 Luup
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;
