import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";
import logo_for_qr from "./logo_for_qr.svg";

import "../../vendor/new-age.min.css";
import "../../vendor/bootstrap.css";

import Lottie from "react-lottie";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { QRCode } from "react-qr-svg";

//import QRCode from "qrcode.react";

class AddNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      qr_screen: false,
      draw_number: "",
      draw: false,
      countdown: false,
      user_data: {},
      selected_program: ""
    };
  }

  componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var user_data = snapshot.val();
          this.setState({ user_data });
        }.bind(this)
      );

    /* var user = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`partner_user/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var database_val = snapshot.val();

          if (database_val !== undefined && database_val !== null) {
            var access = database_val.access;
            var company_or_institution_name =
              database_val.company_or_institution_name;
            firebase
              .database()
              .ref(
                `QR_Code/questionnaire/${company_or_institution_name}/${access}`
              )
              .on(
                "value",
                function(snap) {
                  var data_initial_pull = snap.val();
                  if (
                    data_initial_pull !== undefined &&
                    data_initial_pull !== null
                  ) {
                    this.setState({ data_initial_pull });
                  } else {
                    this.setState({ data_initial_pull });
                  }
                }.bind(this)
              );
          } else {
            alert("You do not have access to this page!");
            firebase
              .auth()
              .signOut()
              .then(
                function() {
                  console.log("success");
                },
                function(error) {
                  alert(error);
                }
              );
          }
        }.bind(this)
      );
      */
  }

  drawNowButtonPressed() {
    confirmAlert({
      title: "Do the draw!",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({
              draw_number: "",
              draw: false,
              countdown: true
            });

            const { data } = this.state;

            var department_database = data[0].department;
            var survey_name_database = data[0].survey_name;
            var company_or_institution_name_database =
              data[0].company_or_institution_name;
            var survey_id =
              data[0].Dynamic_Link_Details.survey_id_for_QR_creation_push_id;
            firebase
              .database()
              .ref(
                `QR_Code/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/current/`
              )

              .once(
                "value",
                function(snapshot) {
                  var uniquie = _.map(snapshot.val(), (val, uid) => {
                    return { ...val };
                  });

                  if (uniquie.length === 0) {
                    alert("No participants yet!");
                  } else {
                    var randomenumber;

                    var checking;
                    var found;

                    do {
                      randomenumber = Math.floor(
                        Math.random() * Math.floor(200)
                      );
                      checking = randomenumber;
                      found = uniquie.find(element => {
                        return checking === element.randomNumber;
                      });
                    } while (found === undefined);

                    this.setState({
                      draw_number: randomenumber,
                      draw: true
                    });

                    setTimeout(() => {
                      this.setState({
                        countdown: false
                      });
                    }, 6000); //10000);

                    if (found === undefined) {
                      console.log("good to go");
                    } else {
                      console.log("generate a new number");
                    }
                  }
                }.bind(this)
              );
          }
        },
        {
          label: "No",
          onClick: () => console.log("Click No")
        }
      ]
    });
  }

  coundown_and_draw_render() {
    //countdown,playing_phone

    const defaultOptions_countdown = {
      loop: true,
      autoplay: true,
      animationData: require("./countdown.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    /*const defaultOptions_playing_phone = {
      loop: true,
      autoplay: true,
      animationData: require("./playing_phone.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const defaultOptions_fireworks = {
      loop: true,
      autoplay: true,
      animationData: require("./fireworks.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };*/

    if (this.state.countdown === true) {
      return (
        <div className="text-center">
          {/*<Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: require("./expire.json"),
                        rendererSettings: {
                          preserveAspectRatio: "xMidYMid slice"
                        }
                      }}
                      width="40%"
                    />*/}

          <Lottie options={defaultOptions_countdown} height={300} width={300} />

          {/*<Lottie
            options={defaultOptions_playing_phone}
            height={300}
            width={300}
         />*/}
        </div>
      );
    }

    return (
      <div className="text-center">
        {/*<Lottie options={defaultOptions_fireworks} height={300} width={300} />*/}
        <h1 style={{ color: "black", fontSize: 165, fontWeight: "bold" }}>
          {this.state.draw_number}
        </h1>
      </div>
    );
  }

  showDrawNowButton({ questionnaire_data }) {
    if (
      questionnaire_data.draw === false ||
      questionnaire_data.in_person_draw === false
    ) {
      return null;
    }
    return (
      <a
        onClick={() => {
          this.drawNowButtonPressed();
        }}
        className="btn_1 btn-outline_1 btn-xl_1 js-scroll-trigger"
      >
        Draw now!
      </a>
    );
  }

  render() {
    const { user_data } = this.state;
    if (user_data.all_programs !== undefined) {
      const data_initial_pull_val = _.map(
        this.state.data_initial_pull,
        (val, uid) => {
          return val;
        }
      );

      const data_initial_pull = _.map(
        this.state.data_initial_pull,
        (val, uid) => {
          return uid;
        }
      );
      var options = [];
      if (data_initial_pull.length !== undefined) {
        for (var i = 0; i < data_initial_pull.length; i++) {
          var optionValue = data_initial_pull[i];
          options.push(<option value={i}>{optionValue}</option>);
        }
      }
      const { data } = this.state;

      const all_programs = _.map(user_data.all_programs, (val, uid) => {
        return val;
      });

      var list_of_programs = [];
      for (var p = 0; p < all_programs.length; p++) {
        list_of_programs.push(
          <option value={all_programs[p].access}>
            {all_programs[p].access}
          </option>
        );
      }

      if (this.state.selected_program === "") {
        return (
          <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div className="text-center">
                      <img
                        src={logo_for_qr}
                        className="qr-screen-logo"
                        alt="logo"
                      />

                      <div
                        className="site-subscribe"
                        style={{
                          paddingTop: 10
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 mx-auto text-center">
                              <div>
                                <div className="form-group">
                                  <label style={{ paddingBottom: 2 }}>
                                    Select the Program:
                                  </label>
                                  <select
                                    className="form-control"
                                    value={this.state.selected_program}
                                    onChange={event => {
                                      event.preventDefault();

                                      this.setState({
                                        selected_program: event.target.value
                                      });
                                      var user = firebase.auth().currentUser;

                                      firebase
                                        .database()
                                        .ref(`partner/users/${user.uid}/`)
                                        .on(
                                          "value",
                                          function(snapshot) {
                                            var database_val = snapshot.val();

                                            firebase
                                              .database()
                                              .ref(
                                                `QR_Code/questionnaire/${database_val.company_or_institution_name}/${event.target.value}`
                                              )
                                              .on(
                                                "value",
                                                function(snap) {
                                                  var data_initial_pull = snap.val();
                                                  if (
                                                    data_initial_pull !==
                                                      undefined &&
                                                    data_initial_pull !== null
                                                  ) {
                                                    this.setState({
                                                      data_initial_pull
                                                    });
                                                  } else {
                                                    this.setState({
                                                      data_initial_pull
                                                    });
                                                  }
                                                }.bind(this)
                                              );

                                            /*
          if (database_val !== undefined && database_val !== null) {
            var access = database_val.access;
            var company_or_institution_name =
              database_val.company_or_institution_name;
            firebase
              .database()
              .ref(
                `QR_Code/questionnaire/${company_or_institution_name}/${access}`
              )
              .on(
                "value",
                function(snap) {
                  var data_initial_pull = snap.val();
                  if (
                    data_initial_pull !== undefined &&
                    data_initial_pull !== null
                  ) {
                    this.setState({ data_initial_pull });
                  } else {
                    this.setState({ data_initial_pull });
                  }
                }.bind(this)
              );
          } else {
            alert("You do not have access to this page!");
            firebase
              .auth()
              .signOut()
              .then(
                function() {
                  console.log("success");
                },
                function(error) {
                  alert(error);
                }
              );
          }
        
        */
                                          }.bind(this)
                                        );

                                      /* this.setState({
                                        selected_program: event.target.value
                                      });*/
                                    }}
                                  >
                                    <option selected value="">
                                      Select....
                                    </option>
                                    {list_of_programs}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        );
      } else {
        if (this.state.qr_screen === true) {
          var link;
          if (
            this.state.data.length !== 0 &&
            this.state.data.length !== undefined
          ) {
            link = this.state.data[this.state.data.length - 1]
              .Dynamic_Link_Details.QR_Code_Link;
          }

          return (
            <header className="masthead_login">
              <div className="container h-100">
                <div className="row h-100">
                  <div className="col-lg-12 my-auto">
                    <div className="header-content mx-auto">
                      <div className="text-center">
                        <h1 //className="mb-5"
                        >
                          YOUR FEEDBACK
                        </h1>
                        <h2 //className="mb-5"
                        >
                          {data[0].survey_name}
                        </h2>
                        <div className="site-subscribe">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12 mx-auto text-center">
                                {/** */}

                                <div className="device-container">
                                  <div className="device-mockup iphone6_plus portrait white">
                                    <div className="device">
                                      <div className="screen">
                                        {this.state.draw ? (
                                          <div //className="countdown_and_draw_style"
                                            className="shadow-lg p-3 mb-5 bg-white rounded"
                                          >
                                            {this.coundown_and_draw_render()}
                                          </div>
                                        ) : (
                                          <div
                                            className="text-center"
                                            style={{
                                              padding: 15,

                                              backgroundColor: "white"
                                            }}
                                          >
                                            <QRCode
                                              bgColor="#FFFFFF"
                                              fgColor="#000000"
                                              level="Q"
                                              className="size_qr"
                                              // style={{ width: 500 }}
                                              cellClassPrefix="my-qr-code"
                                              value={link}
                                            />
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h1 //className="mb-5"
                          >
                            survey.getluup.com
                          </h1>
                          {this.showDrawNowButton({
                            questionnaire_data: data[0]
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          );
          /*  return (
            <header className="masthead">
              <div className="container h-100">
                <div className="row h-100">
                  <div className="col-lg-7 my-auto">
                    <div className="header-content mx-auto">
                      <div style={{ paddingBottom: 5 }}>
                        <img
                          src={logo_for_qr}
                          className="qr-screen-logo"
                          alt="logo"
                        />
                      </div>

                      <h1 className="mb-5">YOUR FEEDBACK MATTERS</h1>
                      <h2 className="mb-5">{data[0].survey_name}</h2>

                      {this.showDrawNowButton({ questionnaire_data: data[0] })}
                    </div>
                  </div>
                  <div className="col-lg-5 my-auto">
                    <div className="device-container">
                      <div className="device-mockup iphone6_plus portrait white">
                        <div className="device">
                          <div className="screen">
                            {this.state.draw ? (
                              <div //className="countdown_and_draw_style"
                                className="shadow-lg p-3 mb-5 bg-white rounded"
                              >
                                {this.coundown_and_draw_render()}
                              </div>
                            ) : (
                              <div
                                className="text-center"
                                style={{
                                  padding: 15,

                                  backgroundColor: "white"
                                }}
                              >
                                <QRCode
                                  bgColor="#FFFFFF"
                                  fgColor="#000000"
                                  level="Q"
                                  className="size_qr"
                                  // style={{ width: 500 }}
                                  cellClassPrefix="my-qr-code"
                                  value={link}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
          );*/
        }

        return (
          <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div className="text-center">
                      <img
                        src={logo_for_qr}
                        className="qr-screen-logo"
                        alt="logo"
                      />

                      <div
                        className="site-subscribe"
                        style={{
                          paddingTop: 10
                        }}
                      >
                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 mx-auto text-center">
                              <div>
                                <div className="form-group">
                                  <label style={{ paddingBottom: 2 }}>
                                    Select the Program:
                                  </label>
                                  <select
                                    className="form-control"
                                    value={this.state.selected_program}
                                    onChange={event => {
                                      event.preventDefault();

                                      this.setState({
                                        selected_program: event.target.value
                                      });
                                      /* var data = _.map(
                                        data_initial_pull_val[
                                          event.target.value
                                        ],
                                        (val, uid) => {
                                          return val;
                                        }
                                      );

                                      this.setState({ data, qr_screen: true });*/
                                    }}
                                  >
                                    <option selected value="">
                                      Select....
                                    </option>
                                    {list_of_programs}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="container">
                          <div className="row">
                            <div className="col-lg-12 mx-auto text-center">
                              <div>
                                <div className="form-group">
                                  <label style={{ paddingBottom: 2 }}>
                                    Select Event/Workshop:
                                  </label>
                                  <select
                                    className="form-control"
                                    value={this.state.view_selection}
                                    onChange={event => {
                                      event.preventDefault();

                                      var data = _.map(
                                        data_initial_pull_val[
                                          event.target.value
                                        ],
                                        (val, uid) => {
                                          return val;
                                        }
                                      );

                                      this.setState({ data, qr_screen: true });

                                      //    this.generateQr();
                                    }}
                                  >
                                    <option selected value="">
                                      Select....
                                    </option>
                                    {options}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        );
      }
    }
    return (
      <header className="masthead_login">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-12 my-auto">
              <div className="header-content mx-auto">
                <div className="text-center">
                  <img
                    src={logo_for_qr}
                    className="qr-screen-logo"
                    alt="logo"
                  />

                  <div
                    className="site-subscribe"
                    style={{
                      paddingTop: 10
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 mx-auto text-center">
                          <div>
                            <div className="form-group">
                              <p style={{ color: "white" }}>
                                Your access is still in pending. Please ask your
                                manager/supervisor for authorization
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default AddNotification;

/*


 <div>
                            <div className="form-group">
                              <label style={{ paddingBottom: 2 }}>
                                Select the Program:
                              </label>
                              <select
                                className="form-control"
                                value={this.state.view_selection}
                                onChange={event => {
                                  event.preventDefault();

                                  var data = _.map(
                                    data_initial_pull_val[event.target.value],
                                    (val, uid) => {
                                      return val;
                                    }
                                  );

                                  this.setState({ data, qr_screen: true });

                                  //    this.generateQr();
                                }}
                              >
                                <option selected value="">
                                  Select....
                                </option>
                                {options}
                              </select>
                            </div>
                          </div>*/
