import React, { Component } from "react";
import "../vendor/sb-admin.css";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="sticky-footer" style={{ backgroundColor: "white" }}>
          <div className="container my-auto">
            <div className="copyright text-center my-auto">
              <span style={{ color: "black" }}>Powered by Luup</span>
              <span style={{ color: "black" }}>© Copyright 2020.</span>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
