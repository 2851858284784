import _ from "lodash";
import React, { Component } from "react";
import logo from ".././logo.svg";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Survey_QR from ".././Survey_QR";
import Dashboard from ".././Dashboard";
import Change_Password from ".././Change_Password";

import "../../vendor/sb-admin.css";
import Help from ".././Help";

import Giftcard from ".././Giftcard";
import App_Users from ".././App_Users";
import Unlink_Feedback from ".././Unlink_Feedback";

import * as firebase from "firebase";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: "",
    };
  }

  static defaultProps = {
    displayTitle: true,
    displayLegend: true,
    legendPosition: "right",
    location: "City",
  };

  componentWillMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var userInfo = snapshot.val();

          if (userInfo === null) {
            firebase
              .database()
              .ref(`Admin/admin_user/${user.uid}`)
              .on(
                "value",
                function(snapData) {
                  if (snapData.val() === null) {
                    this.logout();
                  } else {
                    userInfo = snapData.val();
                    this.setState({ userInfo });
                  }
                }.bind(this)
              );
          } else {
            this.setState({ userInfo });
          }
        }.bind(this)
      );
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(
        function() {
          // Sign-out successful.
          console.log("success");
        },
        function(error) {
          alert(error);
        }
      );
  }

  render() {
    const { userInfo } = this.state;
    return (
      <BrowserRouter>
        <div>
          <Navbar
            collapseOnSelect
            expand="lg"
            style={{ backgroundColor: "#311c92" }}
            variant="dark"
          >
            <Navbar.Brand

            //href="#home"
            >
              <img src={logo} className="App-logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto"></Nav>
              <Nav>
                <Nav.Link style={{ color: "white" }} href="" eventKey={1}>
                  <Link to={"/"}>
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      Dashboard
                    </span>
                  </Link>
                </Nav.Link>

                <Nav.Link style={{ color: "white" }} eventKey={2}>
                  <Link style={{ color: "white" }} to={"/QR"}>
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      Survey QR
                    </span>
                  </Link>
                </Nav.Link>

                {/*<Nav.Link style={{ color: "white" }} eventKey={3}>
                  <Link style={{ color: "white" }} to={"/Help"}>
                    <span style={{ paddingLeft: 5, color: "white" }}>Help</span>
                  </Link>
                </Nav.Link>*/}

                <Nav.Link style={{ color: "white" }} eventKey={3}>
                  <Link style={{ color: "white" }} to={"/Giftcard"}>
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      Giftcard
                    </span>
                  </Link>
                </Nav.Link>

                <Nav.Link style={{ color: "white" }} eventKey={3}>
                  <Link style={{ color: "white" }} to={"/App_Users"}>
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      App Users
                    </span>
                  </Link>
                </Nav.Link>

                <Nav.Link style={{ color: "white" }} eventKey={3}>
                  <Link style={{ color: "white" }} to={"/Unlink_Feedback"}>
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      Feedback
                    </span>
                  </Link>
                </Nav.Link>

                <Nav.Link style={{ color: "white" }} eventKey={3}>
                  <Link style={{ color: "white" }} to={"/ChangePassword"}>
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      Change Password
                    </span>
                  </Link>
                </Nav.Link>

                <Nav.Link
                  style={{ color: "white" }}
                  eventKey={4}
                  onClick={this.logout.bind(this)}
                >
                  <span style={{ paddingLeft: 5, color: "white" }}>Logout</span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>

          {/*<nav
            className="navbar navbar-expand static-top"
            style={{ background: "#311b92" }}
          >
            <img src={logo} className="App-logo" />

            <div class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
              <div class="input-group">
                <div class="input-group-append">
                  <a
                    style={{
                      background: "transparent",
                      border: "transparent",
                      cursor: "pointer"
                    }}
                    // onClick={this.logout.bind(this)}
                  >
                    <Link style={{ color: "white" }} to={"/"}>
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Dashboard
                      </span>
                    </Link>
                  </a>

                  <a
                    style={{
                      background: "transparent",
                      border: "transparent",
                      cursor: "pointer",
                      paddingRight: 10,
                      paddingLeft: 10
                    }}
                  >
                    <Link style={{ color: "white" }} to={"/QR"}>
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Survey QR
                      </span>
                    </Link>
                  </a>
                  <a
                    style={{
                      background: "transparent",
                      border: "transparent",
                      cursor: "pointer"
                    }}
                    onClick={this.logout.bind(this)}
                  >
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      Logout
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </nav>*/}

          <div id="wrapper">
            <Route
              path="/"
              exact
              strict
              render={() => {
                return <Dashboard />;
              }}
            />
            <Route
              path="/QR"
              exact
              strict
              render={() => {
                return <Survey_QR />;
              }}
            />

            <Route
              path="/Help"
              exact
              strict
              render={() => {
                return <Help />;
              }}
            />
            <Route
              path="/Giftcard"
              exact
              strict
              render={() => {
                return <Giftcard />;
              }}
            />

            <Route
              path="/App_Users"
              exact
              strict
              render={() => {
                return <App_Users />;
              }}
            />

            <Route
              path="/Unlink_Feedback"
              exact
              strict
              render={() => {
                return <Unlink_Feedback />;
              }}
            />

            <Route
              path="/ChangePassword"
              exact
              strict
              render={() => {
                return <Change_Password />;
              }}
            />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}

export default NavigationBar;
