import _ from "lodash";
import React, { Component } from "react";
import Footer from ".././Footer";
import * as firebase from "firebase";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import moment_time_zone from "moment-timezone";
import Lottie from "react-lottie";
import { Button, Modal, Form } from "react-bootstrap";
import { delay } from "lodash";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
var timestamp;
//
const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Results
  </span>
);
class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user_data: null,
      raw_all_data: null,
      new_raw_all_data: null,
      data: null,
      seond_set_of_data: null,
      third_set_of_data: null,
      showcase_data: null,
      list_of_programs: null,
      selected_program: "",
      first_filter: "",
      second_filter: "",
      third_filter: "",
      fourth_filter: "",
      admin_first_fiter: "",
      loading: false,
      show_modal: false,
      list_of_users_transactions: [],
    };
  }

  async componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on("value", (snapshot) => {
        var user_data = snapshot.val();

        this.setState({ user_data });
      });

    /* firebase
      .database()
      .ref(`users/`)
      .once("value", (snapshot) => {
        var user_data = snapshot.val();

        const snap_data = _.map(user_data, (val, uid) => {
          //
          return { uid, val };
        });

        snap_data.forEach((element, i) => {
          const surveys_finished = _.map(
            element.val.Survey_Submitted.pointsEarned,
            (val, uid) => {
              //
              return { uid, val };
            }
          );

          surveys_finished.forEach((second_element) => {
            if (
              second_element.val.transaction_info
                .company_or_institution_name !== "Ryerson"
            ) {
              if (
                second_element.val.transaction_info !== "N/A" &&
                second_element.val.questionnaire_result !== "N/A"
              ) {
                var name = second_element.val.name;
                // var points = second_element.val.points;
                var questionnaire_result =
                  second_element.val.questionnaire_result;
                var survey_submitted_on =
                  second_element.val.survey_submitted_on;
                var transaction_info = second_element.val.transaction_info;
                var user_id = element.uid;

                firebase.database().ref(`Retail_Surveys/${name}/All`).push({
                  // name,
                  //   points,
                  questionnaire_result,
                  survey_submitted_on,
                  transaction_info,
                  user_id,
                });
              }
            }
          });
        });
        //  console.log(snap_data);
      });
      */

    // firebase.database().ref(`TEST/RETAIL_TESTT/`).remove();
    //  firebase.database().ref(`Retail_Surveys/`).remove();

    //Retail_Surveys
    var new_question = {
      0: {
        0: {
          question: "What is your program/major?",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Accounting" },
          1: { choice: "Business Technology Management" },
          2: { choice: "Economics & Management Science" },
          3: { choice: "Entrepreneurship & Strategy" },
          4: { choice: "Finance" },
          5: { choice: "Global Management Studies" },
          6: { choice: "Hospitality & Tourism Management" },
          7: { choice: "Human Resources & Organizational Behaviour" },
          8: { choice: "Law & Business" },
          9: { choice: "Marketing Management" },
          10: { choice: "Real Estate Management" },
          11: { choice: "Retail Management" },
        },
      },

      1: {
        0: {
          question:
            "I have a better understanding of what I need to do in order to be successful in the Ted Rogers Co-op program.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      2: {
        0: {
          question:
            "For my Lumina workshop, I was in the breakout room with the following Co-op Team Facilitator:",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Donna" },
          1: { choice: "Laura" },
          2: { choice: "Medhat" },
          3: { choice: "Melody" },
          4: { choice: "Ray" },
          4: { choice: "Shab" },
        },
      },
      3: {
        0: {
          question:
            "The Lumina workshop provided me with a higher level of self-awareness and how to leverage other people's strengths for collaborating with those of different preferences.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      4: {
        0: {
          question:
            "The knowledge I gained about myself from the Lumina workshop will be beneficial in my job search/career journey.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      5: {
        0: {
          question:
            "I found that hearing a senior co-op student integrate their story in the Tell Me About Yourself question was helpful to understand how improvements can be made.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      6: {
        0: {
          question:
            "The practice during the Tell Me Your Story workshop will allow me to give stronger answers in interviews.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      7: {
        0: {
          question:
            "After practicing my story with others, I have a better understanding of how to improve my pitch.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      8: {
        0: {
          question:
            "I have a better understanding of how to be a good mentee and how to get the best value from my mentor relationships.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      9: {
        0: {
          question:
            "I have a better understanding of how RUCSA is beneficial to me while in the co-op program.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      10: {
        0: {
          question: "Overall, I was satisfied with the quality of the event.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      11: {
        0: {
          question: "I found the virtual format to be engaging and valuable.",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Strongly Agree" },
          1: { choice: "Agree" },
          2: { choice: "Undecided" },
          3: { choice: "Disagree" },
          4: { choice: "Strongly Disagree" },
        },
      },

      12: {
        0: {
          question: "What aspects of the event were most useful?",
        },
        1: { questionnaire_type: "MCQ" },
        2: {
          0: { choice: "Meeting the Co-op Team" },
          1: { choice: "Lumina Workshop" },
          2: { choice: "Mentee Training" },
          3: { choice: "Tell Me Your Story Session" },
        },
      },

      13: {
        0: {
          question: "What specific improvements would you recommend?",
        },
        1: { questionnaire_type: "Open Field" },
      },

      14: {
        0: {
          question:
            "Optional: Include a testimonial we can use in our social media. (including your name is also optional)",
        },
        1: { questionnaire_type: "Open Field" },
      },
    };

    var add_template = {
      draw: false,
      email_required: false,
      general_prize_draw: "5",
      in_person_draw: false,
      questions: new_question,
      subText:
        "Thank you for participating in our event hosted by the Business Career Hub (BCH). Please take 4 minutes to provide us with your anonymous feedback so we can ensure you are gaining the most value from these events.",
      //   "How did you like the Workshop? Tell us in this super quick survey, and get a chance to win a surprise draw!",
      surveyFor: "Student",
      survey_expiry: "Tuesday, September 17, 2019 12:00 AM",
      type_of_email: "ryerson.ca",
      unique_entry_required: false,
      user_prize_draw: "5",
    };

    //   firebase
    //   .database()
    //   .ref(`QR_Code/questionnaire_templates/Ryerson/Recruiting Events/Coop Symposium Template`)
    //  .update(add_template);
  }

  goBackToDashboard() {
    this.setState({
      //  user_data: null,
      // raw_all_data: null,
      // data: null,
      // seond_set_of_data: null,
      // third_set_of_data: null,
      showcase_data: null,
      //  selected_program: "",
      //   first_filter: "",
      //  second_filter: "",
      //  third_filter: "",
      //  fourth_filter: "",
    });
  }

  top_portion() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    var first = "";
    var second = "";
    var third = "";

    if (showcase_data !== null) {
      if (first_filter === "all") {
        //   console.log({ first_filter }); //all
        //  console.log({ second_filter }); //by_month, by_date, by_year, all
        //  console.log({ showcase_data }); //showcase_data.uid is the specific month

        first = "All Surveys";
        //  third = "";
      } else {
        // console.log({ first_filter }); //specific
        // console.log({ second_filter }); //Name of the survey
        // console.log({ third_filter }); //by_month, by_date, by_year, all
        // console.log({ showcase_data }); //showcase_data.uid is the specific month

        first = second_filter;
        //    third = showcase_data.uid;
      }
      third = showcase_data.uid;
      if (second_filter === "by_month" || third_filter === "by_month") {
        //
        second = "By Month";
      } else if (second_filter === "by_date" || third_filter === "by_date") {
        //
        second = "By Date";
      } else if (second_filter === "by_year" || third_filter === "by_year") {
        //
        second = "By Year";
      } else {
        //
        second = "All to Date";
      }
    }

    return (
      <div>
        {showcase_data === null ? (
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a>Dashboard</a>
            </li>
          </ol>
        ) : (
          <ol class="breadcrumb">
            <li

            //class="breadcrumb-item"
            >
              <a
                style={{ color: "#0277BD", cursor: "pointer" }}
                onClick={this.goBackToDashboard.bind(this)}
              >
                GO BACK
              </a>
            </li>

            <li style={{ paddingRight: 10, paddingLeft: 10 }}> | </li>

            <li class="breadcrumb-item">{first}</li>
            <li class="breadcrumb-item">{second}</li>
            {second === "All to Date" ? null : (
              <li class="breadcrumb-item">{third}</li>
            )}
          </ol>
        )}
      </div>
    );
  }

  admin_first_fiter() {
    return (
      <div>
        <div className="form-group">
          <label>Select Category:</label>
          <select
            className="form-control"
            value={this.state.admin_first_fiter}
            onChange={(event) => {
              event.preventDefault();
              var value = event.target.value;

              this.setState({
                admin_first_fiter: event.target.value,
              });

              if (value !== "") {
                this.setState({ loading: true });
                if (value === "retail") {
                  firebase
                    .database()
                    .ref(`Retail_Surveys/`)
                    .on("value", (snap) => {
                      this.setState({
                        raw_all_data: snap.val(),
                      });
                      this.setState({ loading: false });
                    });
                } else {
                  firebase
                    .database()
                    .ref(`QR_Code/Survey_Submitted/`)
                    .on("value", (snap) => {
                      this.setState({
                        raw_all_data: snap.val(),
                      });
                      this.setState({ loading: false });
                    });
                }
              }
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            <option value="retail">Retail</option>
            <option value="qr_code">QR Code</option>
          </select>
        </div>
      </div>
    );
  }
  selection_for_program() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      admin_first_fiter,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;
    var array_specific = [];
    const all_programs = _.map(raw_all_data, (val, uid) => {
      return { ...val, uid };
    });

    const all_programs_content = _.map(raw_all_data, (val, uid) => {
      return { ...val };
      // return { ...val, uid };
    });

    var list_of_programs = [];
    var total_collected_to_date = 0;
    all_programs.forEach((element, i) => {
      const each_retailer = _.map(element.All, (val, uid) => {
        return { ...val, uid };
      });

      total_collected_to_date += each_retailer.length;
      list_of_programs.push(
        <option value={i}>
          {element.uid} (
          {each_retailer.length
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
          surveys)
        </option>
      );
    });

    if (admin_first_fiter === "") {
      return null;
    } else {
      if (this.state.loading === true) {
        const defaultOptions_countdown = {
          loop: true,
          autoplay: true,
          animationData: require("./loading.json"),

          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        };

        return (
          <div>
            <Lottie
              options={defaultOptions_countdown}
              height={300}
              width={300}
            />
          </div>
        );
      }

      return (
        <div>
          <div className="form-group">
            <label>
              Select Retailer/Institute [total # of surveys:{" "}
              {total_collected_to_date
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              ]:
            </label>
            <select
              className="form-control"
              //  value={this.state.admin_first_fiter}
              onChange={(event) => {
                event.preventDefault();
                var value = event.target.value;

                if (admin_first_fiter === "retail") {
                  //  console.log(all_programs[value].All);

                  var new_raw_all_data = all_programs[value].All;
                  this.setState({
                    new_raw_all_data,
                  });

                  var all_data = _.map(new_raw_all_data, (val, uid) => {
                    var all = {
                      label: uid,
                      data: {
                        uid,
                        val: { ...val },
                      },
                    };

                    return all;
                  });

                  var all_data_by_date = _.map(new_raw_all_data, (val, uid) => {
                    var date_local = moment_time_zone(val.survey_submitted_on)
                      .tz("America/Toronto")
                      .format("MMMM Do YYYY");

                    var all_data_by_date_as_label = {
                      label: date_local,
                      data: {
                        uid,
                        val: { ...val },
                      },
                    };

                    return all_data_by_date_as_label;
                  });

                  var all_data_by_month = _.map(
                    new_raw_all_data,
                    (val, uid) => {
                      var month = moment_time_zone(val.survey_submitted_on)
                        .tz("America/Toronto")
                        .format("MMMM YYYY");

                      var all_data_by_month_as_label = {
                        label: month,
                        data: {
                          uid,
                          val: { ...val },
                        },
                      };

                      return all_data_by_month_as_label;
                    }
                  );

                  var all_data_by_year = _.map(new_raw_all_data, (val, uid) => {
                    var year = moment_time_zone(val.survey_submitted_on)
                      .tz("America/Toronto")
                      .format("YYYY");

                    var all_data_by_year_as_label = {
                      label: year,
                      data: {
                        uid,
                        val: { ...val },
                      },
                    };

                    return all_data_by_year_as_label;
                  });

                  var all_data_by_survey_name = _.map(
                    new_raw_all_data,
                    (val, uid) => {
                      var all_data_by_survey_name_as_label = {};

                      if (val.transaction_info.location !== "Not Provided") {
                        if (
                          val.transaction_info.location.store_number ===
                          undefined
                        ) {
                          all_data_by_survey_name_as_label = {
                            label: val.transaction_info.location.state,
                            data: {
                              uid,
                              val: { ...val },
                            },
                          };
                        } else {
                          all_data_by_survey_name_as_label = {
                            label: val.transaction_info.location.store_number,
                            data: {
                              uid,
                              val: { ...val },
                            },
                          };
                        }
                      } else {
                        all_data_by_survey_name_as_label = {
                          label: val.transaction_info.real_name,
                          data: {
                            uid,
                            val: { ...val },
                          },
                        };
                      }

                      return all_data_by_survey_name_as_label;
                    }
                  );

                  //
                  var seen_all_data = new Map();
                  var seen_all_data_by_date = new Map();
                  var seen_all_data_by_month = new Map();
                  var seen_all_data_by_year = new Map();
                  var seen_all_data_by_survey_name = new Map();

                  all_data = all_data.filter((entry) => {
                    var previous;
                    if (seen_all_data.hasOwnProperty(entry.label)) {
                      previous = seen_all_data[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_all_data[entry.label] = entry;

                    return true;
                  });
                  //

                  //
                  all_data_by_date = all_data_by_date.filter((entry) => {
                    var previous;
                    if (seen_all_data_by_date.hasOwnProperty(entry.label)) {
                      previous = seen_all_data_by_date[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_all_data_by_date[entry.label] = entry;

                    return true;
                  });
                  //

                  //
                  all_data_by_month = all_data_by_month.filter((entry) => {
                    var previous;
                    if (seen_all_data_by_month.hasOwnProperty(entry.label)) {
                      previous = seen_all_data_by_month[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_all_data_by_month[entry.label] = entry;

                    return true;
                  });
                  //

                  //
                  all_data_by_year = all_data_by_year.filter((entry) => {
                    var previous;
                    if (seen_all_data_by_year.hasOwnProperty(entry.label)) {
                      previous = seen_all_data_by_year[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_all_data_by_year[entry.label] = entry;

                    return true;
                  });
                  //

                  //
                  all_data_by_survey_name = all_data_by_survey_name.filter(
                    (entry) => {
                      var previous;
                      if (
                        seen_all_data_by_survey_name.hasOwnProperty(entry.label)
                      ) {
                        previous = seen_all_data_by_survey_name[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_all_data_by_survey_name[entry.label] = entry;

                      return true;
                    }
                  );
                  //

                  // CONVERTING START

                  var raw_data_to_extract_users;
                  raw_data_to_extract_users = all_data;
                  raw_data_to_extract_users = _.fromPairs(
                    _.map(raw_data_to_extract_users, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  raw_data_to_extract_users = _.map(
                    raw_data_to_extract_users,
                    (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return ["user_id", i.val.user_id];
                        })
                      );

                      //    console.log(array_converted);
                      //   return array_converted; //{ ...array_converted };

                      return { ...array_converted };
                    }
                  );
                  //CONVERTING END

                  // CONVERTING START
                  all_data = _.fromPairs(
                    _.map(all_data, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  all_data = _.map(all_data, (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  });

                  //CONVERTING END

                  //CONVERTING START
                  all_data_by_date = _.fromPairs(
                    _.map(all_data_by_date, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  all_data_by_date = _.map(all_data_by_date, (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  });
                  //CONVERTING END

                  // CONVERTING START
                  all_data_by_month = _.fromPairs(
                    _.map(all_data_by_month, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  all_data_by_month = _.map(all_data_by_month, (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  });
                  //CONVERTING END

                  // CONVERTING START
                  all_data_by_year = _.fromPairs(
                    _.map(all_data_by_year, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  all_data_by_year = _.map(all_data_by_year, (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  });
                  //CONVERTING END

                  //CONVERTING START
                  all_data_by_survey_name = _.fromPairs(
                    _.map(all_data_by_survey_name, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  all_data_by_survey_name = _.map(
                    all_data_by_survey_name,
                    (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    }
                  );
                  // CONVERTING END

                  all_data_by_survey_name.forEach((survey_name_data) => {
                    var specific_data_by_date = _.map(
                      survey_name_data.combined_data,
                      (val, uid) => {
                        var date_local = moment_time_zone(
                          val.survey_submitted_on
                        )
                          .tz("America/Toronto")
                          .format("MMMM Do YYYY");

                        var specific_data_by_date_as_label = {
                          label: date_local,
                          data: {
                            uid,
                            val: { ...val },
                          },
                        };

                        return specific_data_by_date_as_label;
                      }
                    );

                    var specific_data_by_month = _.map(
                      survey_name_data.combined_data,
                      (val, uid) => {
                        var month = moment_time_zone(val.survey_submitted_on)
                          .tz("America/Toronto")
                          .format("MMMM YYYY");

                        var specific_data_by_month_as_label = {
                          label: month,
                          data: {
                            uid,
                            val: { ...val },
                          },
                        };

                        return specific_data_by_month_as_label;
                      }
                    );

                    var specific_data_by_year = _.map(
                      survey_name_data.combined_data,
                      (val, uid) => {
                        var year = moment_time_zone(val.survey_submitted_on)
                          .tz("America/Toronto")
                          .format("YYYY");

                        var specific_data_by_year_as_label = {
                          label: year,
                          data: {
                            uid,
                            val: { ...val },
                          },
                        };

                        return specific_data_by_year_as_label;
                      }
                    );

                    var seen_specific_data_by_date = new Map();
                    var seen_specific_data_by_month = new Map();
                    var seen_specific_data_by_year = new Map();

                    //
                    specific_data_by_date = specific_data_by_date.filter(
                      (entry) => {
                        var previous;
                        if (
                          seen_specific_data_by_date.hasOwnProperty(entry.label)
                        ) {
                          previous = seen_specific_data_by_date[entry.label];
                          previous.data.push(entry.data);

                          return false;
                        }
                        if (!Array.isArray(entry.data)) {
                          entry.data = [entry.data];
                        }
                        seen_specific_data_by_date[entry.label] = entry;

                        return true;
                      }
                    );
                    //

                    //
                    specific_data_by_month = specific_data_by_month.filter(
                      (entry) => {
                        var previous;
                        if (
                          seen_specific_data_by_month.hasOwnProperty(
                            entry.label
                          )
                        ) {
                          previous = seen_specific_data_by_month[entry.label];
                          previous.data.push(entry.data);

                          return false;
                        }
                        if (!Array.isArray(entry.data)) {
                          entry.data = [entry.data];
                        }
                        seen_specific_data_by_month[entry.label] = entry;

                        return true;
                      }
                    );
                    //

                    //
                    specific_data_by_year = specific_data_by_year.filter(
                      (entry) => {
                        var previous;
                        if (
                          seen_specific_data_by_year.hasOwnProperty(entry.label)
                        ) {
                          previous = seen_specific_data_by_year[entry.label];
                          previous.data.push(entry.data);

                          return false;
                        }
                        if (!Array.isArray(entry.data)) {
                          entry.data = [entry.data];
                        }
                        seen_specific_data_by_year[entry.label] = entry;

                        return true;
                      }
                    );
                    //

                    //CONVERTING START
                    specific_data_by_date = _.fromPairs(
                      _.map(specific_data_by_date, (i) => {
                        return [i.label, i.data];
                      })
                    );

                    specific_data_by_date = _.map(
                      specific_data_by_date,
                      (val, uid) => {
                        const array_converted = _.fromPairs(
                          _.map(val, (i) => {
                            return [i.uid, i.val];
                          })
                        );

                        return { uid, combined_data: { ...array_converted } };
                      }
                    );
                    //CONVERTING END

                    // CONVERTING START
                    specific_data_by_month = _.fromPairs(
                      _.map(specific_data_by_month, (i) => {
                        return [i.label, i.data];
                      })
                    );

                    specific_data_by_month = _.map(
                      specific_data_by_month,
                      (val, uid) => {
                        const array_converted = _.fromPairs(
                          _.map(val, (i) => {
                            return [i.uid, i.val];
                          })
                        );

                        return { uid, combined_data: { ...array_converted } };
                      }
                    );
                    // CONVERTING END

                    // CONVERTING START
                    specific_data_by_year = _.fromPairs(
                      _.map(specific_data_by_year, (i) => {
                        return [i.label, i.data];
                      })
                    );

                    specific_data_by_year = _.map(
                      specific_data_by_year,
                      (val, uid) => {
                        const array_converted = _.fromPairs(
                          _.map(val, (i) => {
                            return [i.uid, i.val];
                          })
                        );

                        return { uid, combined_data: { ...array_converted } };
                      }
                    );
                    // CONVERTING END

                    array_specific.push({
                      survey_name: survey_name_data.uid,
                      specific_data_by_date,
                      specific_data_by_month,
                      specific_data_by_year,
                    });
                  });

                  /*
                  this.setState({
                    data: {
                      array_specific,
                      all_data,
                      all_data_by_date,
                      all_data_by_month,
                      all_data_by_year,
                      all_data_by_survey_name,
                    },
                  });
                  */

                  var send_over_data = {
                    array_specific,
                    all_data,
                    all_data_by_date,
                    all_data_by_month,
                    all_data_by_year,
                    all_data_by_survey_name,
                  };

                  var list_of_users_submitted_surveys = raw_data_to_extract_users;

                  var list_of_unique_users = [];
                  list_of_users_submitted_surveys.forEach((element, i) => {
                    list_of_unique_users.push(element.user_id);
                  });

                  list_of_unique_users = _.uniq(list_of_unique_users);

                  this.fetch_user_data(list_of_unique_users, send_over_data);
                } else {
                  //
                  //  console.log(all_programs[value]);

                  this.setState({
                    list_of_programs: all_programs_content[value], //all_programs[value],
                  });
                  //   console.log(all_programs[value]);
                }
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              {list_of_programs}
            </select>
          </div>
        </div>
      );
    }
  }

  async fetch_user_data(list_of_unique_users, send_over_data) {
    var user_transactional_data = [];

    //  console.log(list_of_unique_users);
    list_of_unique_users.forEach(async (id, i) => {
      try {
        var data = await firebase
          .database()
          .ref(`users/${id}/plaid_transactions`)
          .once("value", (snapshot) => {
            user_transactional_data.push({
              user_id: id,
              data: snapshot.val(),
            });
            return snapshot.val();
          });
      } catch (err) {
        console.log(err);
      }
    });

    this.setState({
      data: {
        list_of_unique_users,
        user_transactional_data,
        array_specific: send_over_data.array_specific,
        all_data: send_over_data.all_data,
        all_data_by_date: send_over_data.all_data_by_date,
        all_data_by_month: send_over_data.all_data_by_month,
        all_data_by_year: send_over_data.all_data_by_year,
        all_data_by_survey_name: send_over_data.all_data_by_survey_name,
      },
    });
  }

  admin_second_filter() {
    const { list_of_programs } = this.state;
    var array_specific = [];

    if (list_of_programs === null) {
      return null;
    }
    // console.log(list_of_programs);

    const all_programs = _.map(list_of_programs, (val, uid) => {
      return { ...val, uid };
    });

    var list_of_programs_ = [];

    all_programs.forEach((element, i) => {
      // if (element.uid !== "uid") {
      list_of_programs_.push(<option value={i}>{element.uid}</option>);
      //  }
    });

    return (
      <div>
        <div className="form-group">
          <label>Select Institute/Company:</label>
          <select
            className="form-control"
            //    value={this.state.admin_first_fiter}
            onChange={(event) => {
              event.preventDefault();
              var value = event.target.value;
              //  this.setState({
              //    admin_first_fiter: event.target.value,
              //    });

              if (value !== "") {
                //  console.log(
                //    all_programs[value].All_Responses_Combined.all_questionnaire
                //      .All
                //  );

                var new_raw_all_data =
                  all_programs[value].All_Responses_Combined.all_questionnaire
                    .All;

                //all_programs[value].All;
                this.setState({
                  new_raw_all_data,
                });

                var all_data = _.map(new_raw_all_data, (val, uid) => {
                  var all = {
                    label: uid,
                    data: {
                      uid,
                      val: { ...val },
                    },
                  };

                  return all;
                });

                var all_data_by_date = _.map(new_raw_all_data, (val, uid) => {
                  var date_local = moment_time_zone(val.survey_submitted_on)
                    .tz("America/Toronto")
                    .format("MMMM Do YYYY");

                  var all_data_by_date_as_label = {
                    label: date_local,
                    data: {
                      uid,
                      val: { ...val },
                    },
                  };

                  return all_data_by_date_as_label;
                });

                var all_data_by_month = _.map(new_raw_all_data, (val, uid) => {
                  var month = moment_time_zone(val.survey_submitted_on)
                    .tz("America/Toronto")
                    .format("MMMM YYYY");

                  var all_data_by_month_as_label = {
                    label: month,
                    data: {
                      uid,
                      val: { ...val },
                    },
                  };

                  return all_data_by_month_as_label;
                });

                var all_data_by_year = _.map(new_raw_all_data, (val, uid) => {
                  var year = moment_time_zone(val.survey_submitted_on)
                    .tz("America/Toronto")
                    .format("YYYY");

                  var all_data_by_year_as_label = {
                    label: year,
                    data: {
                      uid,
                      val: { ...val },
                    },
                  };

                  return all_data_by_year_as_label;
                });

                var all_data_by_survey_name = _.map(
                  new_raw_all_data,
                  (val, uid) => {
                    var all_data_by_survey_name_as_label = {
                      label: val.transaction_info.survey_name,
                      data: {
                        uid,
                        val: { ...val },
                      },
                    };

                    return all_data_by_survey_name_as_label;
                  }
                );

                //
                var seen_all_data = new Map();
                var seen_all_data_by_date = new Map();
                var seen_all_data_by_month = new Map();
                var seen_all_data_by_year = new Map();
                var seen_all_data_by_survey_name = new Map();

                all_data = all_data.filter((entry) => {
                  var previous;
                  if (seen_all_data.hasOwnProperty(entry.label)) {
                    previous = seen_all_data[entry.label];
                    previous.data.push(entry.data);

                    return false;
                  }
                  if (!Array.isArray(entry.data)) {
                    entry.data = [entry.data];
                  }
                  seen_all_data[entry.label] = entry;

                  return true;
                });
                //

                //
                all_data_by_date = all_data_by_date.filter((entry) => {
                  var previous;
                  if (seen_all_data_by_date.hasOwnProperty(entry.label)) {
                    previous = seen_all_data_by_date[entry.label];
                    previous.data.push(entry.data);

                    return false;
                  }
                  if (!Array.isArray(entry.data)) {
                    entry.data = [entry.data];
                  }
                  seen_all_data_by_date[entry.label] = entry;

                  return true;
                });
                //

                //
                all_data_by_month = all_data_by_month.filter((entry) => {
                  var previous;
                  if (seen_all_data_by_month.hasOwnProperty(entry.label)) {
                    previous = seen_all_data_by_month[entry.label];
                    previous.data.push(entry.data);

                    return false;
                  }
                  if (!Array.isArray(entry.data)) {
                    entry.data = [entry.data];
                  }
                  seen_all_data_by_month[entry.label] = entry;

                  return true;
                });
                //

                //
                all_data_by_year = all_data_by_year.filter((entry) => {
                  var previous;
                  if (seen_all_data_by_year.hasOwnProperty(entry.label)) {
                    previous = seen_all_data_by_year[entry.label];
                    previous.data.push(entry.data);

                    return false;
                  }
                  if (!Array.isArray(entry.data)) {
                    entry.data = [entry.data];
                  }
                  seen_all_data_by_year[entry.label] = entry;

                  return true;
                });
                //

                //
                all_data_by_survey_name = all_data_by_survey_name.filter(
                  (entry) => {
                    var previous;
                    if (
                      seen_all_data_by_survey_name.hasOwnProperty(entry.label)
                    ) {
                      previous = seen_all_data_by_survey_name[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_all_data_by_survey_name[entry.label] = entry;

                    return true;
                  }
                );
                //

                // CONVERTING START
                all_data = _.fromPairs(
                  _.map(all_data, (i) => {
                    return [i.label, i.data];
                  })
                );

                all_data = _.map(all_data, (val, uid) => {
                  const array_converted = _.fromPairs(
                    _.map(val, (i) => {
                      return [i.uid, i.val];
                    })
                  );

                  return { uid, combined_data: { ...array_converted } };
                });

                //CONVERTING END

                //CONVERTING START
                all_data_by_date = _.fromPairs(
                  _.map(all_data_by_date, (i) => {
                    return [i.label, i.data];
                  })
                );

                all_data_by_date = _.map(all_data_by_date, (val, uid) => {
                  const array_converted = _.fromPairs(
                    _.map(val, (i) => {
                      return [i.uid, i.val];
                    })
                  );

                  return { uid, combined_data: { ...array_converted } };
                });
                //CONVERTING END

                // CONVERTING START
                all_data_by_month = _.fromPairs(
                  _.map(all_data_by_month, (i) => {
                    return [i.label, i.data];
                  })
                );

                all_data_by_month = _.map(all_data_by_month, (val, uid) => {
                  const array_converted = _.fromPairs(
                    _.map(val, (i) => {
                      return [i.uid, i.val];
                    })
                  );

                  return { uid, combined_data: { ...array_converted } };
                });
                //CONVERTING END

                // CONVERTING START
                all_data_by_year = _.fromPairs(
                  _.map(all_data_by_year, (i) => {
                    return [i.label, i.data];
                  })
                );

                all_data_by_year = _.map(all_data_by_year, (val, uid) => {
                  const array_converted = _.fromPairs(
                    _.map(val, (i) => {
                      return [i.uid, i.val];
                    })
                  );

                  return { uid, combined_data: { ...array_converted } };
                });
                //CONVERTING END

                //CONVERTING START
                all_data_by_survey_name = _.fromPairs(
                  _.map(all_data_by_survey_name, (i) => {
                    return [i.label, i.data];
                  })
                );

                all_data_by_survey_name = _.map(
                  all_data_by_survey_name,
                  (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  }
                );
                // CONVERTING END

                all_data_by_survey_name.forEach((survey_name_data) => {
                  var specific_data_by_date = _.map(
                    survey_name_data.combined_data,
                    (val, uid) => {
                      var date_local = moment_time_zone(val.survey_submitted_on)
                        .tz("America/Toronto")
                        .format("MMMM Do YYYY");

                      var specific_data_by_date_as_label = {
                        label: date_local,
                        data: {
                          uid,
                          val: { ...val },
                        },
                      };

                      return specific_data_by_date_as_label;
                    }
                  );

                  all_data_by_survey_name = _.orderBy(
                    all_data_by_survey_name,
                    ["uid"],
                    ["asc"]
                  ); // sorting alphabetically

                  var specific_data_by_month = _.map(
                    survey_name_data.combined_data,
                    (val, uid) => {
                      var month = moment_time_zone(val.survey_submitted_on)
                        .tz("America/Toronto")
                        .format("MMMM YYYY");

                      var specific_data_by_month_as_label = {
                        label: month,
                        data: {
                          uid,
                          val: { ...val },
                        },
                      };

                      return specific_data_by_month_as_label;
                    }
                  );

                  var specific_data_by_year = _.map(
                    survey_name_data.combined_data,
                    (val, uid) => {
                      var year = moment_time_zone(val.survey_submitted_on)
                        .tz("America/Toronto")
                        .format("YYYY");

                      var specific_data_by_year_as_label = {
                        label: year,
                        data: {
                          uid,
                          val: { ...val },
                        },
                      };

                      return specific_data_by_year_as_label;
                    }
                  );

                  var seen_specific_data_by_date = new Map();
                  var seen_specific_data_by_month = new Map();
                  var seen_specific_data_by_year = new Map();

                  //
                  specific_data_by_date = specific_data_by_date.filter(
                    (entry) => {
                      var previous;
                      if (
                        seen_specific_data_by_date.hasOwnProperty(entry.label)
                      ) {
                        previous = seen_specific_data_by_date[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_specific_data_by_date[entry.label] = entry;

                      return true;
                    }
                  );
                  //

                  //
                  specific_data_by_month = specific_data_by_month.filter(
                    (entry) => {
                      var previous;
                      if (
                        seen_specific_data_by_month.hasOwnProperty(entry.label)
                      ) {
                        previous = seen_specific_data_by_month[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_specific_data_by_month[entry.label] = entry;

                      return true;
                    }
                  );
                  //

                  //
                  specific_data_by_year = specific_data_by_year.filter(
                    (entry) => {
                      var previous;
                      if (
                        seen_specific_data_by_year.hasOwnProperty(entry.label)
                      ) {
                        previous = seen_specific_data_by_year[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_specific_data_by_year[entry.label] = entry;

                      return true;
                    }
                  );
                  //

                  //CONVERTING START
                  specific_data_by_date = _.fromPairs(
                    _.map(specific_data_by_date, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  specific_data_by_date = _.map(
                    specific_data_by_date,
                    (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    }
                  );
                  //CONVERTING END

                  // CONVERTING START
                  specific_data_by_month = _.fromPairs(
                    _.map(specific_data_by_month, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  specific_data_by_month = _.map(
                    specific_data_by_month,
                    (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    }
                  );
                  // CONVERTING END

                  // CONVERTING START
                  specific_data_by_year = _.fromPairs(
                    _.map(specific_data_by_year, (i) => {
                      return [i.label, i.data];
                    })
                  );

                  specific_data_by_year = _.map(
                    specific_data_by_year,
                    (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    }
                  );
                  // CONVERTING END

                  array_specific.push({
                    survey_name: survey_name_data.uid,
                    specific_data_by_date,
                    specific_data_by_month,
                    specific_data_by_year,
                  });
                });
                this.setState({
                  data: {
                    array_specific,
                    all_data,
                    all_data_by_date,
                    all_data_by_month,
                    all_data_by_year,
                    all_data_by_survey_name,
                  },
                });
              }
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            {list_of_programs_}
          </select>
        </div>
      </div>
    );
  }

  first_filter() {
    // const { data } = this.state;
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (data === null) {
      return null;
    }
    return (
      <div>
        <div className="form-group">
          <label>Select how you want to view the results:</label>

          {this.state.admin_first_fiter === "retail" ? (
            <select
              className="form-control"
              value={this.state.first_filter}
              onChange={(event) => {
                event.preventDefault();

                this.setState({
                  first_filter: event.target.value,
                });
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              <option value="specific">By Store</option>
              <option value="all">All Surveys</option>
            </select>
          ) : (
            <select
              className="form-control"
              value={this.state.first_filter}
              onChange={(event) => {
                event.preventDefault();

                this.setState({
                  first_filter: event.target.value,
                });
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              <option value="specific">Surveys By Name</option>
              <option value="all">All Surveys</option>
            </select>
          )}

          {/*
          <select
            className="form-control"
            value={this.state.first_filter}
            onChange={(event) => {
              event.preventDefault();

              this.setState({
                first_filter: event.target.value,
              });
            }}
          >
            <option selected value="">
              Please Choose
            </option>
             <option value="specific">Surveys By Name</option>
            <option value="all">All Surveys</option>
        
          </select>

          */}
        </div>
      </div>
    );
  }

  second_filter() {
    // const { data, first_filter, seond_set_of_data } = this.state;
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (first_filter === "") {
      return null;
    } else if (first_filter === "all") {
      return (
        <div>
          <div className="form-group">
            <label>Select Filter:</label>
            <select
              className="form-control"
              value={this.state.second_filter}
              onChange={(event) => {
                event.preventDefault();
                var value = event.target.value;

                this.setState({
                  second_filter: event.target.value,
                });
                if (value !== "") {
                  if (value === "all") {
                    //show all data - add functionality
                    this.setState({
                      seond_set_of_data: data.all_data,
                    });

                    this.setState({ showcase_data: raw_all_data });
                  } else if (value === "by_date") {
                    this.setState({
                      seond_set_of_data: data.all_data_by_date,
                    });
                  } else if (value === "by_month") {
                    this.setState({
                      seond_set_of_data: data.all_data_by_month,
                    });
                  } else {
                    this.setState({
                      seond_set_of_data: data.all_data_by_year,
                    });
                  }
                }
              }}
            >
              <option selected value="">
                Please Choose
              </option>

              <option value="all">All</option>
              <option value="by_date">By Date</option>
              <option value="by_month">By Month</option>
              <option value="by_year">By Year</option>
            </select>
          </div>
        </div>
      );
    }
    const all_data_by_survey_name = _.map(
      data.all_data_by_survey_name,
      (val, uid) => {
        return val;
      }
    );

    const array_specific = _.map(data.array_specific, (val, uid) => {
      return val;
    });

    var list_of_programs = [];

    all_data_by_survey_name.forEach((element, i) => {
      list_of_programs.push(<option value={i}>{element.uid}</option>);
    });

    return (
      <div>
        <div className="form-group">
          {this.state.admin_first_fiter === "retail" ? (
            <label>Select Store Number/Name:</label>
          ) : (
            <label>Select Survey Name:</label>
          )}
          <select
            className="form-control"
            onChange={(event) => {
              event.preventDefault();
              var value = event.target.value;
              var name = all_data_by_survey_name[value].uid;

              this.setState({
                second_filter: name,
              });

              this.setState({
                seond_set_of_data: {
                  all_data_by_survey_name: all_data_by_survey_name[value],
                  array_specific: array_specific[value],
                },
              });
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            {list_of_programs}
          </select>
        </div>
      </div>
    );
  }

  third_filter() {
    //  const { data, first_filter, second_filter, seond_set_of_data } = this.state;
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (second_filter === "") {
      return null;
    } else {
      if (first_filter === "all") {
        if (second_filter === "all") {
          //show all data - functionality to show all data is in second_filter()

          return null;
        }

        const all_programs = _.map(seond_set_of_data, (val, uid) => {
          return val;
        });

        var list_of_programs = [];

        all_programs.forEach((element, i) => {
          list_of_programs.push(<option value={i}>{element.uid}</option>);
        });

        var show_label = "";
        if (second_filter === "by_month") {
          show_label = "Select Month:";
        } else if (second_filter === "by_year") {
          show_label = "Select Year:";
        } else {
          show_label = "Select Date:";
        }

        return (
          <div>
            <div className="form-group">
              <label>{show_label}</label>
              <select
                className="form-control"
                onChange={(event) => {
                  //show all data by filter choice
                  event.preventDefault();
                  var value = event.target.value;

                  this.setState({ showcase_data: all_programs[value] });
                }}
              >
                <option selected value="">
                  Please Choose
                </option>
                {list_of_programs}
              </select>
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div className="form-group">
              <label>Select Filter:</label>
              <select
                className="form-control"
                // value={this.state.third_filter}
                onChange={(event) => {
                  event.preventDefault();
                  var value = event.target.value;

                  this.setState({
                    third_filter: event.target.value,
                  });

                  if (value !== "") {
                    if (value === "all") {
                      //show all data - add functionality

                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.all_data_by_survey_name,
                        // .combined_data,
                      });

                      this.setState({
                        showcase_data:
                          seond_set_of_data.all_data_by_survey_name,
                      });

                      //  this.setState({ showcase_data: all_programs[value] });
                    } else if (value === "by_date") {
                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.array_specific
                            .specific_data_by_date,
                      });
                    } else if (value === "by_month") {
                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.array_specific
                            .specific_data_by_month,
                      });
                    } else {
                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.array_specific
                            .specific_data_by_year,
                      });
                    }
                  }
                }}
              >
                <option selected value="">
                  Please Choose
                </option>

                <option value="all">All</option>
                <option value="by_date">By Date</option>
                <option value="by_month">By Month</option>
                <option value="by_year">By Year</option>
              </select>
            </div>
          </div>
        );
      }
    }
  }

  fourth_filter() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (third_filter === "" || first_filter === "all") {
      return null;
    } else {
      if (third_filter === "all") {
        //show all data - functionality to show all data is in third_filter()
        return null;
      }

      const all_programs = _.map(third_set_of_data, (val, uid) => {
        return val;
      });

      var list_of_programs = [];

      all_programs.forEach((element, i) => {
        list_of_programs.push(<option value={i}>{element.uid}</option>);
      });

      var show_label = "";
      if (third_filter === "by_month") {
        show_label = "Select Month:";
      } else if (third_filter === "by_year") {
        show_label = "Select Year:";
      } else {
        show_label = "Select Date:";
      }

      return (
        <div>
          <div className="form-group">
            <label>{show_label}</label>
            <select
              className="form-control"
              onChange={(event) => {
                event.preventDefault();
                var value = event.target.value;
                //show all data by filter choice
                this.setState({ showcase_data: all_programs[value] });
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              {list_of_programs}
            </select>
          </div>
        </div>
      );
    }
  }

  showcase_data() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
      new_raw_all_data,
    } = this.state;

    var multiple_choice_item_render = [];
    var open_text_item_render = [];
    var all_results_culstered_array = [];
    var all_item_render = [];

    var multiple_choice = [];
    var open_text = [];

    var data_output = [];
    if (first_filter === "all" && second_filter === "all") {
      data_output = _.map(new_raw_all_data, (val, uid) => {
        return { ...val, uid };
      });
    } else {
      data_output = _.map(showcase_data.combined_data, (val, uid) => {
        return { ...val, uid };
      });
    }

    data_output.forEach((first_element) => {
      var questionnaire_result = first_element.questionnaire_result;
      var transaction_info = first_element.transaction_info;
      var survey_submitted_on = first_element.survey_submitted_on;
      var user_id = first_element.user_id;
      questionnaire_result.forEach((second_element) => {
        var answer = second_element.answer;
        var question = second_element.question;
        var type_of_question = second_element.type_of_question;

        if (type_of_question === "MCQ") {
          multiple_choice.push({
            question,
            data: {
              question,
              answer,
              transaction_info,
              survey_submitted_on,
              type_of_question,
              user_id,
            },
          });
        } else {
          open_text.push({
            question,
            data: {
              question,
              answer,
              transaction_info,
              survey_submitted_on,
              type_of_question,
              user_id,
            },
          });
        }
      });
    });

    //
    var seen_multiple_choice = new Map();
    var cluster_multiple_choice = multiple_choice.filter((entry) => {
      var previous;
      if (seen_multiple_choice.hasOwnProperty(entry.question)) {
        previous = seen_multiple_choice[entry.question];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_multiple_choice[entry.question] = entry;

      return true;
    });
    //

    //
    var seen_open_text = new Map();
    var cluster_open_text = open_text.filter((entry) => {
      var previous;
      if (seen_open_text.hasOwnProperty(entry.question)) {
        previous = seen_open_text[entry.question];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_open_text[entry.question] = entry;

      return true;
    });

    /**ADDING TOGETHER BOTTOM*/

    cluster_multiple_choice.forEach((element_5) => {
      all_results_culstered_array.push(element_5);
    });

    cluster_open_text.forEach((element_5) => {
      all_results_culstered_array.push(element_5);
    });
    /**ADDING TOGETHER TOP*/

    //Bottom Only for multiple choice START
    cluster_multiple_choice.forEach((first_element, p) => {
      var array = [];
      first_element.data.forEach((second_element) => {
        array.push(second_element.answer);
      });

      array.sort();
      var array_uniqBy_and_count = [];
      var array_uniqBy_choice_only = [];
      var array_uniqBy_count_only = [];

      var current = null;
      var cnt = 0;

      array.forEach((third_element) => {
        if (third_element != current) {
          if (cnt > 0) {
            array_uniqBy_and_count.push({
              choice: current,
              qty: cnt,
            });

            array_uniqBy_choice_only.push(current);
            array_uniqBy_count_only.push(cnt);
          }
          current = third_element;
          cnt = 1;
        } else {
          cnt++;
        }
      });

      if (cnt > 0) {
        array_uniqBy_and_count.push({
          choice: current,
          qty: cnt,
        });
        array_uniqBy_choice_only.push(current);
        array_uniqBy_count_only.push(cnt);
      }

      multiple_choice_item_render.push(
        <div class="col-lg-6" key={p}>
          <div className="card mb-3">
            <div className="card-header">
              <h3>{first_element.question}</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <Doughnut
                  data={{
                    labels: array_uniqBy_choice_only,
                    datalabels: {
                      color: "black",
                    },
                    datasets: [
                      {
                        data: array_uniqBy_count_only,

                        backgroundColor: [
                          "#998ce3",
                          "#39c2c9",
                          "#fcce01",
                          "#fba71a",
                          "#6aedc7",
                          "#f866b9",
                        ],

                        // backgroundColor: array_color,
                      },
                    ],
                  }}
                  options={{ responsive: true }}
                />
              </div>
            </div>
            <div className="card-footer small text-muted">
              Multiple Choice Response
            </div>
          </div>
        </div>
      );
    });
    //TOP Only for multiple choice END

    cluster_open_text.forEach((first_element, q) => {
      var open_text_data = [];

      //var account_id_array = [];
      first_element.data.forEach((second_element, p) => {
        if (this.state.admin_first_fiter === "retail") {
          var location = "";

          if (second_element.transaction_info.location === "Not Provided") {
            location = second_element.transaction_info.real_name;
          } else {
            //
            location = second_element.transaction_info.location.store_number;
          }
          open_text_data.push({
            id: p + 1,
            response: second_element.answer,
            spent: second_element.transaction_info.amount.toFixed(2),
            purchase_date: moment(second_element.transaction_info.date).format(
              "dddd, MMMM Do YYYY"
            ),

            transaction: second_element.transaction_info.transaction_id,
            user_id: second_element.user_id, //dummy_user_name, //second_element.transaction_info.account_id,

            location_id: location,

            submitted_date: moment(second_element.survey_submitted_on).format(
              "dddd, MMMM Do YYYY"
            ),
          });
        } else {
          open_text_data.push({
            id: p + 1,
            response: second_element.answer,
            workshop: second_element.transaction_info.survey_name,
            date: moment(second_element.survey_submitted_on).format(
              "dddd, MMMM Do YYYY"
            ),
          });
        }
      });

      open_text_item_render.push(
        <div key={1}>
          <div style={{ paddingRight: 5, paddingLeft: 5 }}>
            <div className="card mb-3">
              <div className="card-header">
                <h3>{first_element.question}</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div style={{ paddingBottom: 85 }}>
                    {this.state.admin_first_fiter === "retail" ? (
                      <ToolkitProvider
                        keyField="Open_Field"
                        data={open_text_data}
                        columns={[
                          {
                            dataField: "id",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "response",
                            text: "Response",
                            sort: true,
                          },
                          {
                            dataField: "spent",
                            text: "Spent ($)",
                            sort: true,
                          },
                          {
                            dataField: "purchase_date",
                            text: "Purchase Date",
                            sort: true,
                          },

                          {
                            dataField: "transaction",
                            text: "Transaction ID",
                            sort: true,
                          },

                          {
                            dataField: "user_id",
                            text: "User",
                            sort: true,
                          },

                          {
                            dataField: "location_id",
                            text: "Location",
                            sort: true,
                          },

                          {
                            dataField: "submitted_date",
                            text: "Survey Submitted Date",
                            sort: true,
                          },
                        ]}
                        search
                        exportCSV
                      >
                        {(props) => (
                          <div
                            style={{
                              paddingRight: 20,
                              paddingLeft: 20,
                            }}
                          >
                            <SearchBar {...props.searchProps} />
                            <hr />
                            <ExportCSVButton
                              {...props.csvProps}
                              style={{ backgroundColor: "gray" }}
                            >
                              Export CSV!!
                            </ExportCSVButton>
                            <hr />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory({
                                paginationSize: 4,
                                pageStartIndex: 0,
                                firstPageText: "First",
                                prePageText: "Back",
                                nextPageText: "Next",
                                lastPageText: "Last",
                                nextPageTitle: "First page",
                                prePageTitle: "Pre page",
                                firstPageTitle: "Next page",
                                lastPageTitle: "Last page",
                                showTotal: true,
                                paginationTotalRenderer: customTotal,

                                sizePerPageList: [
                                  {
                                    text: "5",
                                    value: 5,
                                  },
                                  {
                                    text: "25",
                                    value: 25,
                                  },
                                  {
                                    text: "All",
                                    value: open_text_data.length, //Open_Field_Questions.length
                                  },
                                ],
                                // A numeric array is also available. the purpose of above example is custom the text
                              })}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <ToolkitProvider
                        keyField="Open_Field"
                        data={open_text_data}
                        columns={[
                          {
                            dataField: "id",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "response",
                            text: "Response",
                            sort: true,
                          },
                          {
                            dataField: "workshop",
                            text: "Workshop",
                            sort: true,
                          },
                          {
                            dataField: "date",
                            text: "Date",
                            sort: true,
                          },
                        ]}
                        search
                        exportCSV
                      >
                        {(props) => (
                          <div
                            style={{
                              paddingRight: 20,
                              paddingLeft: 20,
                            }}
                          >
                            <SearchBar {...props.searchProps} />
                            <hr />
                            <ExportCSVButton
                              {...props.csvProps}
                              style={{ backgroundColor: "gray" }}
                            >
                              Export CSV!!
                            </ExportCSVButton>
                            <hr />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory({
                                paginationSize: 4,
                                pageStartIndex: 0,
                                firstPageText: "First",
                                prePageText: "Back",
                                nextPageText: "Next",
                                lastPageText: "Last",
                                nextPageTitle: "First page",
                                prePageTitle: "Pre page",
                                firstPageTitle: "Next page",
                                lastPageTitle: "Last page",
                                showTotal: true,
                                paginationTotalRenderer: customTotal,

                                sizePerPageList: [
                                  {
                                    text: "5",
                                    value: 5,
                                  },
                                  {
                                    text: "25",
                                    value: 25,
                                  },
                                  {
                                    text: "All",
                                    value: open_text_data.length, //Open_Field_Questions.length
                                  },
                                ],
                                // A numeric array is also available. the purpose of above example is custom the text
                              })}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer small text-muted">
                Open Field Response
              </div>
            </div>
          </div>
        </div>
      );
    });

    all_results_culstered_array.forEach((first_element, q) => {
      var all_data = [];

      //var account_id_array = [];
      first_element.data.forEach((second_element, p) => {
        if (this.state.admin_first_fiter === "retail") {
          var location = "";

          if (second_element.transaction_info.location === "Not Provided") {
            location = second_element.transaction_info.real_name;
          } else {
            //
            location = second_element.transaction_info.location.store_number;
          }
          all_data.push({
            id: p + 1,
            response: second_element.answer,
            spent: second_element.transaction_info.amount.toFixed(2),
            purchase_date: moment(second_element.transaction_info.date).format(
              "dddd, MMMM Do YYYY"
            ),

            transaction: second_element.transaction_info.transaction_id,
            user_id: second_element.user_id, //dummy_user_name, //second_element.transaction_info.account_id,

            location_id: location,

            submitted_date: moment(second_element.survey_submitted_on).format(
              "dddd, MMMM Do YYYY"
            ),
          });
        } else {
          all_data.push({
            id: p + 1,
            response: second_element.answer,
            workshop: second_element.transaction_info.survey_name,
            date: moment(second_element.survey_submitted_on).format(
              "dddd, MMMM Do YYYY"
            ),
          });
        }
      });

      all_item_render.push(
        <div key={q}>
          <div style={{ paddingRight: 5, paddingLeft: 5 }}>
            <div className="card mb-3">
              <div className="card-header">
                <h3>{first_element.question}</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div style={{ paddingBottom: 85 }}>
                    {this.state.admin_first_fiter === "retail" ? (
                      <ToolkitProvider
                        keyField="Open_Field"
                        data={all_data}
                        columns={[
                          {
                            dataField: "id",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "response",
                            text: "Response",
                            sort: true,
                          },
                          {
                            dataField: "spent",
                            text: "Spent ($)",
                            sort: true,
                          },
                          {
                            dataField: "purchase_date",
                            text: "Purchase Date",
                            sort: true,
                          },

                          {
                            dataField: "transaction",
                            text: "Transaction ID",
                            sort: true,
                          },

                          {
                            dataField: "user_id",
                            text: "User",
                            sort: true,
                          },

                          {
                            dataField: "location_id",
                            text: "Location",
                            sort: true,
                          },

                          {
                            dataField: "submitted_date",
                            text: "Survey Submitted Date",
                            sort: true,
                          },
                        ]}
                        search
                        exportCSV
                      >
                        {(props) => (
                          <div
                            style={{
                              paddingRight: 20,
                              paddingLeft: 20,
                            }}
                          >
                            <SearchBar {...props.searchProps} />
                            <hr />
                            <ExportCSVButton
                              {...props.csvProps}
                              style={{ backgroundColor: "gray" }}
                            >
                              Export CSV!!
                            </ExportCSVButton>
                            <hr />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory({
                                paginationSize: 4,
                                pageStartIndex: 0,
                                firstPageText: "First",
                                prePageText: "Back",
                                nextPageText: "Next",
                                lastPageText: "Last",
                                nextPageTitle: "First page",
                                prePageTitle: "Pre page",
                                firstPageTitle: "Next page",
                                lastPageTitle: "Last page",
                                showTotal: true,
                                paginationTotalRenderer: customTotal,

                                sizePerPageList: [
                                  {
                                    text: "5",
                                    value: 5,
                                  },
                                  {
                                    text: "25",
                                    value: 25,
                                  },
                                  {
                                    text: "All",
                                    value: all_data.length, //Open_Field_Questions.length
                                  },
                                ],
                                // A numeric array is also available. the purpose of above example is custom the text
                              })}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    ) : (
                      <ToolkitProvider
                        keyField="Open_Field"
                        data={all_data}
                        columns={[
                          {
                            dataField: "id",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "response",
                            text: "Response",
                            sort: true,
                          },
                          {
                            dataField: "workshop",
                            text: "Workshop",
                            sort: true,
                          },
                          {
                            dataField: "date",
                            text: "Date",
                            sort: true,
                          },
                        ]}
                        search
                        exportCSV
                      >
                        {(props) => (
                          <div
                            style={{
                              paddingRight: 20,
                              paddingLeft: 20,
                            }}
                          >
                            <SearchBar {...props.searchProps} />
                            <hr />
                            <ExportCSVButton
                              {...props.csvProps}
                              style={{ backgroundColor: "gray" }}
                            >
                              Export CSV!!
                            </ExportCSVButton>
                            <hr />
                            <BootstrapTable
                              {...props.baseProps}
                              pagination={paginationFactory({
                                paginationSize: 4,
                                pageStartIndex: 0,
                                firstPageText: "First",
                                prePageText: "Back",
                                nextPageText: "Next",
                                lastPageText: "Last",
                                nextPageTitle: "First page",
                                prePageTitle: "Pre page",
                                firstPageTitle: "Next page",
                                lastPageTitle: "Last page",
                                showTotal: true,
                                paginationTotalRenderer: customTotal,

                                sizePerPageList: [
                                  {
                                    text: "5",
                                    value: 5,
                                  },
                                  {
                                    text: "25",
                                    value: 25,
                                  },
                                  {
                                    text: "All",
                                    value: all_data.length, //Open_Field_Questions.length
                                  },
                                ],
                                // A numeric array is also available. the purpose of above example is custom the text
                              })}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer small text-muted">
                Open Field Response
              </div>
            </div>
          </div>
        </div>
      );
    });

    var list_of_users_transactions = [];
    var user_id_and_transactions = [];

    var all_user_transaction_combined = [];

    if (this.state.admin_first_fiter === "retail") {
      data.user_transactional_data.forEach((element, q) => {
        /* list_of_unique_users.push({
          id: q + 1,
          user_id: element.user_id,
        });

        */

        var HISTORICAL_UPDATE_all_users = element.data.HISTORICAL_UPDATE;

        var INITIAL_UPDATE_all_users = element.data.INITIAL_UPDATE;

        var DEFAULT_UPDATE_all_users = element.data.DEFAULT_UPDATE;

        // HISTORICAL_UPDATE =
        _.map(HISTORICAL_UPDATE_all_users, (val, uid) => {
          // return { ...val, uid };

          all_user_transaction_combined = all_user_transaction_combined.concat({
            userid: element.user_id,
            transactions: val.transactions,
          });
        });

        //   INITIAL_UPDATE =
        _.map(INITIAL_UPDATE_all_users, (val, uid) => {
          // return { ...val, uid };
          all_user_transaction_combined = all_user_transaction_combined.concat(
            // val.transactions
            { userid: element.user_id, transactions: val.transactions }
          );
        });

        //  DEFAULT_UPDATE =
        _.map(DEFAULT_UPDATE_all_users, (val, uid) => {
          //   return { ...val, uid };
          all_user_transaction_combined = all_user_transaction_combined.concat(
            //   val.transactions
            { userid: element.user_id, transactions: val.transactions }
          );
        });

        user_id_and_transactions.push(
          <tr key={q}>
            <th scope="row">{q + 1}</th>
            <td>{element.user_id}</td>

            <td>
              <>
                <Button
                  variant="btn btn-success" //onClick={this.handleShow}
                  onClick={() => {
                    //  console.log(data.user_transactional_data[q]);
                    var DEFAULT_UPDATE =
                      data.user_transactional_data[q].data.DEFAULT_UPDATE;
                    var HISTORICAL_UPDATE =
                      data.user_transactional_data[q].data.HISTORICAL_UPDATE;
                    var INITIAL_UPDATE =
                      data.user_transactional_data[q].data.INITIAL_UPDATE;

                    // HISTORICAL_UPDATE =
                    _.map(HISTORICAL_UPDATE, (val, uid) => {
                      // return { ...val, uid };

                      list_of_users_transactions = list_of_users_transactions.concat(
                        val.transactions
                      );
                    });

                    //   INITIAL_UPDATE =
                    _.map(INITIAL_UPDATE, (val, uid) => {
                      list_of_users_transactions = list_of_users_transactions.concat(
                        val.transactions
                      );
                    });

                    //  DEFAULT_UPDATE =
                    _.map(DEFAULT_UPDATE, (val, uid) => {
                      //   return { ...val, uid };
                      list_of_users_transactions = list_of_users_transactions.concat(
                        val.transactions
                      );
                    });

                    //  console.log(list_of_users_transactions);

                    this.setState({
                      show_modal: !this.state.show_modal,
                    });

                    this.setState({ list_of_users_transactions });

                    //this.handleShow_accepted(q)
                  }}
                >
                  Show
                </Button>

                <Modal
                  show={this.state.show_modal}
                  onHide={() => {
                    this.setState({
                      show_modal: !this.state.show_modal,
                    });
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Transactional Data</Modal.Title>
                  </Modal.Header>

                  <div class="container">
                    <div style={{ paddingBottom: 25 }}>
                      <div
                        className="card-header"
                        style={{
                          paddingBottom: 25,
                          borderRadius: 10,
                          borderWidth: 1,
                        }}
                      >
                        <h3>Data</h3>
                      </div>
                    </div>

                    <div key={1}>
                      <div>
                        <div className="card mb-3">
                          <div className="card-header">
                            <h3>Transaction Data</h3>
                          </div>
                          <div className="card-body">
                            <div className="table-responsive">
                              <div style={{ paddingBottom: 85 }}>
                                <ToolkitProvider
                                  keyField="Open_Field"
                                  data={this.state.list_of_users_transactions}
                                  columns={[
                                    {
                                      dataField: "name",
                                      text: "Store Name",
                                      sort: true,
                                    },

                                    {
                                      dataField: "amount",
                                      text: "Spent $",
                                      sort: true,
                                    },

                                    {
                                      dataField: "date",
                                      text: "Date",
                                      sort: true,
                                    },
                                  ]}
                                  search
                                  exportCSV
                                >
                                  {(props) => (
                                    <div
                                      style={{
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                      }}
                                    >
                                      <ExportCSVButton
                                        {...props.csvProps}
                                        style={{ backgroundColor: "gray" }}
                                      >
                                        Export CSV!!
                                      </ExportCSVButton>
                                      <hr />
                                      <BootstrapTable
                                        {...props.baseProps}
                                        pagination={paginationFactory({
                                          paginationSize: 4,
                                          pageStartIndex: 0,
                                          firstPageText: "First",
                                          prePageText: "Back",
                                          nextPageText: "Next",
                                          lastPageText: "Last",
                                          nextPageTitle: "First page",
                                          prePageTitle: "Pre page",
                                          firstPageTitle: "Next page",
                                          lastPageTitle: "Last page",
                                          showTotal: true,
                                          paginationTotalRenderer: customTotal,

                                          sizePerPageList: [
                                            {
                                              text: "5",
                                              value: 5,
                                            },
                                            {
                                              text: "25",
                                              value: 25,
                                            },
                                            {
                                              text: "All",
                                              value: this.state
                                                .list_of_users_transactions
                                                .length, //Open_Field_Questions.length
                                            },
                                          ],
                                          // A numeric array is also available. the purpose of above example is custom the text
                                        })}
                                      />
                                    </div>
                                  )}
                                </ToolkitProvider>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer small text-muted">
                            Data
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        console.log(this.state.list_of_users_transactions);
                        /* this.setState({
                          show_modal: !this.state.show_modal,
                        });*/
                      }}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </td>
          </tr>
        );
      });
    }

    // all_user_transaction_combined
    // var all_transaction_view = [];
    var filtered_all_transaction_data = [];
    //  console.log(all_user_transaction_combined);

    all_user_transaction_combined.forEach((ele) => {
      if (ele !== undefined) {
        if (ele.transactions !== undefined) {
          // console.log(ele);

          ele.transactions.forEach((element) => {
            //   console.log(element);
            if (
              element.name !== undefined &&
              element.amount !== undefined &&
              element.date !== undefined
            ) {
              var merchant_name = element.name;
              var category = "Not Provided";
              var category_id = "Not Provided";
              var location = element.name;
              var payment_channel = "Not Provided";
              var iso_currency_code = "Not Provided";
              var pending = "Not Provided";
              var transaction_id = "Not Provided";
              var transaction_type = "Not Provided";

              if (element.merchant_name !== undefined) {
                merchant_name = element.merchant_name;
              }
              if (element.category !== undefined) {
                if (element.category.length === 2) {
                  category = element.category[0] + ", " + element.category[1];
                } else if (element.category.length === 3) {
                  category =
                    element.category[0] +
                    ", " +
                    element.category[1] +
                    ", " +
                    element.category[2];
                } else {
                  category = element.category[0];
                }
              }
              if (element.category_id !== undefined) {
                category_id = element.category_id;
              }
              if (element.location !== undefined) {
                location = element.location.store_number;
              }

              if (element.payment_channel !== undefined) {
                payment_channel = element.payment_channel;
              }

              if (element.iso_currency_code !== undefined) {
                iso_currency_code = element.iso_currency_code;
              }
              if (element.pending !== undefined) {
                pending = element.pending;
              }
              if (element.transaction_id !== undefined) {
                transaction_id = element.transaction_id;
              }

              if (element.transaction_type !== undefined) {
                transaction_type = element.transaction_type;
              }

              filtered_all_transaction_data.push({
                userid: ele.userid,
                name: element.name,
                amount: element.amount,
                iso_currency_code,

                date: element.date,

                merchant_name,
                category,
                category_id,
                location,
                payment_channel,
                pending,
                transaction_id,
                transaction_type,
              });
            }
          });
        }
      }
    });

    var all_transaction_view = (
      <div class="container">
        <div style={{ paddingBottom: 25 }}>
          <div
            className="card-header"
            style={{
              paddingBottom: 25,
              borderRadius: 10,
              borderWidth: 1,
            }}
          >
            <h3>Data</h3>
          </div>
        </div>

        <div>
          <div>
            <div className="card mb-3">
              <div className="card-header">
                <h3>Transaction Data</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div style={{ paddingBottom: 85 }}>
                    <ToolkitProvider
                      keyField="Open_Field"
                      data={filtered_all_transaction_data}
                      columns={[
                        {
                          dataField: "userid",
                          text: "User ID",
                          sort: true,
                        },

                        {
                          dataField: "name",
                          text: "Store Name",
                          sort: true,
                        },

                        {
                          dataField: "amount",
                          text: "Spent $",
                          sort: true,
                        },

                        {
                          dataField: "iso_currency_code",
                          text: "Currency",
                          sort: true,
                        },

                        {
                          dataField: "date",
                          text: "Date",
                          sort: true,
                        },

                        {
                          dataField: "merchant_name",
                          text: "Merchant Name",
                          sort: true,
                        },
                        {
                          dataField: "category",
                          text: "Category",
                          sort: true,
                        },
                        {
                          dataField: "category_id",
                          text: "Category ID",
                          sort: true,
                        },
                        {
                          dataField: "location",
                          text: "Location",
                          sort: true,
                        },
                        {
                          dataField: "payment_channel",
                          text: "Payment Channel",
                          sort: true,
                        },
                        {
                          dataField: "pending",
                          text: "Pending",
                          sort: true,
                        },
                        {
                          dataField: "transaction_id",
                          text: "Transaction ID",
                          sort: true,
                        },
                        {
                          dataField: "transaction_type",
                          text: "Transaction Type",
                          sort: true,
                        },
                      ]}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div
                          style={{
                            paddingRight: 20,
                            paddingLeft: 20,
                          }}
                        >
                          <ExportCSVButton
                            {...props.csvProps}
                            style={{ backgroundColor: "gray" }}
                          >
                            Export CSV!!
                          </ExportCSVButton>
                          <hr />
                          <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory({
                              paginationSize: 4,
                              pageStartIndex: 0,
                              firstPageText: "First",
                              prePageText: "Back",
                              nextPageText: "Next",
                              lastPageText: "Last",
                              nextPageTitle: "First page",
                              prePageTitle: "Pre page",
                              firstPageTitle: "Next page",
                              lastPageTitle: "Last page",
                              showTotal: true,
                              paginationTotalRenderer: customTotal,

                              sizePerPageList: [
                                {
                                  text: "5",
                                  value: 5,
                                },
                                {
                                  text: "25",
                                  value: 25,
                                },
                              ],
                              // A numeric array is also available. the purpose of above example is custom the text
                            })}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
              <div className="card-footer small text-muted">Data</div>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <div class="container">
          <div style={{ paddingBottom: 25 }}>
            <div
              className="card-header"
              style={{
                paddingBottom: 25,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <h3>Multiple Choice Response</h3>
            </div>
          </div>
          <div class="row">{multiple_choice_item_render}</div>
        </div>

        <div class="container">
          <div style={{ paddingBottom: 25 }}>
            <div
              className="card-header"
              style={{
                paddingBottom: 25,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <h3>Open Field Response</h3>
            </div>
          </div>
          {open_text_item_render}
        </div>

        <div class="container">
          <div style={{ paddingBottom: 25 }}>
            <div
              className="card-header"
              style={{
                paddingBottom: 25,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <h3>All Question (Below)</h3>
            </div>
          </div>
          {all_item_render}
        </div>

        {this.state.admin_first_fiter === "retail" ? (
          <div>
            <div class="container">
              <div style={{ paddingBottom: 25 }}>
                <div
                  className="card-header"
                  style={{
                    paddingBottom: 25,
                    borderRadius: 10,
                    borderWidth: 1,
                  }}
                >
                  <h3>All Transaction Data By Users</h3>
                </div>
              </div>
              {all_transaction_view}
            </div>

            <div class="container">
              <div style={{ paddingBottom: 25 }}>
                <div
                  className="card-header"
                  style={{
                    paddingBottom: 25,
                    borderRadius: 10,
                    borderWidth: 1,
                  }}
                >
                  <h3>All Transaction Data By Users</h3>
                </div>
              </div>

              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">User ID</th>

                    <th scope="col">Show Data</th>
                  </tr>
                </thead>
                <tbody>{user_id_and_transactions}</tbody>
              </table>
            </div>
          </div>
        ) : null}
      </div>
    );
  }

  /*
       <div class="container">
            <div style={{ paddingBottom: 25 }}>
              <div
                className="card-header"
                style={{
                  paddingBottom: 25,
                  borderRadius: 10,
                  borderWidth: 1,
                }}
              >
                <h3>User Data</h3>
              </div>
            </div>
            {user_id_and_transactions}
          </div>
          
          */
  render() {
    const { user_data, data, showcase_data } = this.state;

    //  console.log(data);
    /* if (user_data === null) {
      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container">
              <div>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a>LOADING</a>
                  </li>
                </ol>
              </div>
            </div>
            <Footer />
          </div>
        </React.Fragment>
      );
    } else */ {
      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container">{this.top_portion()}</div>

            <div className="container">
              <div className="row">
                <div class="col-xl-12 col-sm-12 mb-12">
                  {showcase_data === null ? (
                    <div>
                      {this.admin_first_fiter()}
                      {this.selection_for_program()}
                      {this.admin_second_filter()}
                      {this.first_filter()}
                      {this.second_filter()}
                      {this.third_filter()}
                      {this.fourth_filter()}
                    </div>
                  ) : (
                    <div>{this.showcase_data()}</div>
                  )}
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Dashboard;
