import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";
import { Button } from "react-bootstrap";
import moment from "moment";
import moment_timezone from "moment-timezone";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Footer from ".././Footer";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

class AddRedeemOffer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
    };
  }

  componentWillMount() {
    var all_user_array = [];
    firebase
      .database()
      .ref(`Admin/Feedback/Unlinked_Card`)
      .once("value", (snapshot) => {
        if (snapshot.val() !== null) {
          this.setState({
            data: snapshot.val(),
          });
        }
      });
  }

  render() {
    //console.log(all_users.length);
    // console.log(this.state.all_users);
    var data_array = [];

    const { data } = this.state;

    const feedback = _.map(data, (val, uid) => {
      return {
        uid,
        ...val,
      };
    });

    console.log(feedback);
    if (feedback.length !== 0) {
      feedback.forEach((element, i) => {
        // console.log(element);
        data_array.push({
          id: i + 1,
          feedback: element.message.reason,
          user_uid: element.userID,
        });
      });
      //   });
    }

    return (
      <React.Fragment>
        <div id="content-wrapper">
          <div class="container">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">Users</li>
            </ol>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div className="card mb-3">
                  <div className="card-header">
                    <h3>App Users</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <ToolkitProvider
                        keyField="id"
                        data={data_array}
                        columns={[
                          {
                            dataField: "id",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "feedback",
                            text: "Feedback",
                            sort: true,
                          },
                          {
                            dataField: "user_uid",
                            text: "User ID",
                            sort: true,
                          },
                        ]}
                        exportCSV
                        search
                      >
                        {(props) => (
                          <div>
                            <Button
                              variant="outline-secondary"
                              style={{ padding: 0 }}
                            >
                              <ExportCSVButton {...props.csvProps}>
                                Export CSV!!
                              </ExportCSVButton>
                            </Button>

                            <SearchBar {...props.searchProps} />

                            <hr />
                            <BootstrapTable {...props.baseProps} />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                  <div className="card-footer small text-muted">Users</div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default AddRedeemOffer;
