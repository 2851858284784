import React, { Component } from "react";
import logo from "./logo.svg";
import "./App.css";
import * as firebase from "firebase";

//import Body from "./components/scripts/Body";

import NavigationBar from "./components/NavigationBar";
import Lottie from "react-lottie";

import Login from "./components/Login";

class App extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user: "",
    };
  }

  componentDidMount() {
    firebase.auth().onAuthStateChanged(
      function(user) {
        if (user) {
          // User is signed in.
          this.setState({ user });
        } else {
          // No user is signed in.
          this.setState({ user: null });
        }
      }.bind(this)
    );
  }

  render() {
    if (this.state.user === "") {
      const defaultOptions_countdown = {
        loop: true,
        autoplay: true,
        animationData: require("./loading.json"),
        //   style: { background: "red" },

        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };

      return (
        <React.Fragment>
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Lottie
              options={defaultOptions_countdown}
              height={400}
              width={400}
            />
          </div>

          {/* <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div id="content-wrapper">
                      <div>
                        <div className="container">
                          <div className="card card-login mx-auto mt-5">
                            <div className="text-center">
                              <Lottie
                                options={defaultOptions_countdown}
                                height={300}
                                width={300}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
    */}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.state.user ? <NavigationBar /> : <Login />}
      </React.Fragment>
    );

    /*return (
      <React.Fragment>
        <NavigationBar />
      </React.Fragment>
    );*/
  }
}

export default App;
