import _ from "lodash";
import React, { Component } from "react";
import Footer from "./Footer";
import * as firebase from "firebase";
import moment from "moment";
import { Button, Modal, Form } from "react-bootstrap";
import "../vendor/sb-admin.css";
import logo_for_login from "./logo_for_login.svg";
import SignUp from "./SignUp";
import ForgotPassword from "./ForgotPassword";
import moment_time_zone from "moment-timezone";

class Login extends Component {
  //    this.handle_submit = this.handle_submit.bind(this);
  constructor(props, context) {
    super(props, context);
    this.handle_submit = this.handle_submit.bind(this);
    this.handle_submit_forgotPassword = this.handle_submit_forgotPassword.bind(
      this
    );

    this.state = {
      email: "",
      password: "",
      user: {},
      signUp: false,
      forgotPassword: false,
    };
  }

  handle_submit_forgotPassword = (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .catch((error) => {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...

        alert(errorMessage);
      })
      .then(() => {
        alert("Please check your email for your password reset link!");
      });
  };

  handle_submit = (event) => {
    event.preventDefault();

    const { email, password } = this.state;

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...

        alert(errorMessage);
      });
  };

  render() {
    const { forgotPassword } = this.state;
    return (
      <React.Fragment>
        <header className="masthead_login">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <div id="content-wrapper">
                    <div>
                      <div className="container">
                        <div className="card card-login mx-auto mt-5">
                          <div className="text-center">
                            <img
                              src={logo_for_login}
                              className="screen-logo_login_screen"
                              alt="logo"
                            />
                          </div>

                          <div className="card-body">
                            {forgotPassword !== true ? (
                              <form role="form" onSubmit={this.handle_submit}>
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <div className="form-group">
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Email address
                                        </label>
                                        <input
                                          className="form-control"
                                          type="email"
                                          placeholder="Email address"
                                          required
                                          onChange={(event) => {
                                            event.preventDefault();
                                            this.setState({
                                              email: event.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Password
                                        </label>
                                        <input
                                          required
                                          className="form-control"
                                          type="password"
                                          placeholder="Password"
                                          onChange={(event) => {
                                            event.preventDefault();
                                            this.setState({
                                              password: event.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Login
                                </button>

                                <a
                                  //  type="submit"
                                  className="btn btn-primary btn-block"
                                  onClick={() => {
                                    this.setState({
                                      forgotPassword: !forgotPassword,
                                    });
                                  }}
                                >
                                  Forgot your password?
                                </a>
                              </form>
                            ) : (
                              <form
                                role="form"
                                onSubmit={this.handle_submit_forgotPassword}
                              >
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <div className="form-group">
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Email address
                                        </label>
                                        <input
                                          className="form-control"
                                          type="email"
                                          placeholder="Email address"
                                          required
                                          onChange={(event) => {
                                            event.preventDefault();
                                            this.setState({
                                              email: event.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Send Reset Link
                                </button>

                                <a
                                  //  type="submit"
                                  className="btn btn-primary btn-block"
                                  onClick={() => {
                                    this.setState({
                                      forgotPassword: !forgotPassword,
                                    });
                                  }}
                                >
                                  Back to Login
                                </a>
                              </form>
                            )}
                          </div>
                          <div
                            style={{ paddingTop: 2.5 }}
                            className="text-center"
                          >
                            <p style={{ color: "black", fontSize: 13 }}>
                              Luup Partner Portal <br />
                              ©Copyright 2019 Luup
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/*
            
            
               <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
          
          <div class="text-center" data-gr-c-s-loaded="true">
              <form class="form-signin">
                <img
                  src={logo_for_login}
                  className="screen-logo_login_screen"
                  alt="logo"
                />
                <h1 class="h3 mb-3 font-weight-normal">Sign In</h1>
                <label for="inputEmail" class="sr-only">
                  Email address
                </label>
                <input
                  type="email"
                  id="inputEmail"
                  class="form-control"
                  placeholder="Email address"
                  required=""
                  autofocus=""
                />
                <label for="inputPassword" class="sr-only">
                  Password
                </label>
                <input
                  type="password"
                  id="inputPassword"
                  class="form-control"
                  placeholder="Password"
                  required=""
                />

                <button class="btn btn-lg btn-primary btn-block" type="submit">
                  Sign in
                </button>
                <p class="mt-5 mb-3 text-muted">© 2017-2018</p>
              </form>
            </div>
                  </div>*/}
      </React.Fragment>
    );
  }

  /*render() {
    const { signUp, forgotPassword } = this.state;
    if (signUp === false && forgotPassword === false) {
      return (
        <React.Fragment>
          <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div id="content-wrapper">
                      <div>
                        <div className="container">
                          <div className="card card-login mx-auto mt-5">
                            <div className="text-center">
                              <img
                                src={logo_for_login}
                                className="qr-screen-logo"
                                alt="logo"
                              />
                            </div>
                     
                            <div className="card-body">
                              <form role="form" onSubmit={this.handle_submit}>
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <div className="form-group">
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Email address
                                        </label>
                                        <input
                                          className="form-control"
                                          type="email"
                                          placeholder="Email address"
                                          required
                                          onChange={event => {
                                            event.preventDefault();
                                            this.setState({
                                              email: event.target.value
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Password
                                        </label>
                                        <input
                                          required
                                          className="form-control"
                                          type="password"
                                          placeholder="Password"
                                          onChange={event => {
                                            event.preventDefault();
                                            this.setState({
                                              password: event.target.value
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Login
                                </button>
                              </form>
                            </div>
                            <div
                              style={{ paddingTop: 2.5 }}
                              className="text-center"
                            >
                              <p style={{ color: "black", fontSize: 13 }}>
                                Luup Partner Portal <br />
                                ©Copyright 2019 Luup
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </React.Fragment>
      );
    } else if (signUp === true && forgotPassword === false) {
      return <SignUp />;
    }

    return <ForgotPassword />;
  }*/
}

export default Login;
