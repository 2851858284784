import _ from "lodash";
import React, { Component } from "react";
import Footer from ".././Footer";
import * as firebase from "firebase";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import moment_time_zone from "moment-timezone";
import Lottie from "react-lottie";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
var timestamp;

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Results
  </span>
);
class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user_data: null,
      raw_all_data: null,
      data: null,
      seond_set_of_data: null,
      third_set_of_data: null,
      showcase_data: null,
      selected_program: "",
      first_filter: "",
      second_filter: "",
      third_filter: "",
      fourth_filter: "",
      loading: false,
      time_stamp: null,
    };
  }

  async componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on("value", (snapshot) => {
        var user_data = snapshot.val();

        this.setState({ user_data });
      });
  }

  goBackToDashboard() {
    this.setState({
      //  user_data: null,
      // raw_all_data: null,
      // data: null,
      // seond_set_of_data: null,
      // third_set_of_data: null,
      showcase_data: null,
      //  selected_program: "",
      //   first_filter: "",
      //  second_filter: "",
      //  third_filter: "",
      //  fourth_filter: "",
    });
  }

  top_portion() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    /* console.log({
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    });*/

    var first = "";
    var second = "";
    var third = "";

    if (showcase_data !== null) {
      if (first_filter === "all") {
        //  console.log({ first_filter }); //all
        //  console.log({ second_filter }); //by_month, by_date, by_year, all
        //  console.log({ showcase_data }); //showcase_data.uid is the specific month

        first = "All Surveys";
        //  third = "";
      } else {
        //console.log({ first_filter }); //specific
        //console.log({ second_filter }); //Name of the survey
        //console.log({ third_filter }); //by_month, by_date, by_year, all
        //console.log({ showcase_data }); //showcase_data.uid is the specific month

        first = second_filter;
        //    third = showcase_data.uid;
      }
      third = showcase_data.uid;
      if (second_filter === "by_month" || third_filter === "by_month") {
        //
        second = "By Month";
      } else if (second_filter === "by_date" || third_filter === "by_date") {
        //
        second = "By Date";
      } else if (second_filter === "by_year" || third_filter === "by_year") {
        //
        second = "By Year";
      } else {
        //
        second = "All to Date";
      }

      // console.log(third);
      /*   console.log({ first_filter });
      console.log({ second_filter });
  
      console.log({ third_filter });
      console.log({ fourth_filter });
      console.log({ showcase_data });*/
    }

    /*
    if (first_filter === "all" && second_filter === "all") {
      var data_output = raw_all_data;
    } else {
      var data_output = _.map(showcase_data.combined_data, (val, uid) => {
        return { ...val, uid };
      });
    }*/

    return (
      <div>
        {/*} <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a>Dashboard</a>
          </li>
        </ol>*/}
        {showcase_data === null ? (
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a>Dashboard</a>
            </li>
          </ol>
        ) : (
          <ol class="breadcrumb">
            <li

            //class="breadcrumb-item"
            >
              <a
                style={{ color: "#0277BD", cursor: "pointer" }}
                onClick={this.goBackToDashboard.bind(this)}
              >
                GO BACK
              </a>
            </li>

            <li style={{ paddingRight: 10, paddingLeft: 10 }}> | </li>

            <li class="breadcrumb-item">{first}</li>
            <li class="breadcrumb-item">{second}</li>
            {second === "All to Date" ? null : (
              <li class="breadcrumb-item">{third}</li>
            )}

            {this.state.time_stamp === null ? null : (
              <li class="breadcrumb-item">{this.state.time_stamp}</li>
            )}
          </ol>
        )}
      </div>
    );
  }
  selection_for_program() {
    const { user_data } = this.state;
    var array_specific = [];

    const all_programs = _.map(user_data.all_programs, (val, uid) => {
      return val;
    });

    var list_of_programs = [];
    for (var p = 0; p < all_programs.length; p++) {
      list_of_programs.push(
        <option value={all_programs[p].access}>{all_programs[p].access}</option>
      );
    }
    return (
      <div>
        <div className="form-group">
          <label>Select the Program:</label>
          <select
            className="form-control"
            value={this.state.selected_program}
            onChange={(event) => {
              event.preventDefault();

              if (event.target.value !== "") {
                this.setState({ loading: true });
                var value = event.target.value;
                var company_or_institution_name =
                  user_data.company_or_institution_name;
                this.setState({
                  selected_program: value,
                });

                firebase
                  .database()
                  .ref(
                    `QR_Code/Survey_Submitted/${company_or_institution_name}/${value}/All_Responses_Combined/all_questionnaire/All`
                  )
                  .once("value", (snapshot) => {
                    var raw_all_data = _.map(snapshot.val(), (val, uid) => {
                      return { ...val, uid };
                    });
                    this.setState({
                      raw_all_data,
                    });

                    var all_data = _.map(snapshot.val(), (val, uid) => {
                      var all = {
                        label: uid,
                        data: {
                          uid,
                          val: { ...val },
                        },
                      };

                      return all;
                    });

                    var all_data_by_date = _.map(snapshot.val(), (val, uid) => {
                      var date_local = moment_time_zone(val.survey_submitted_on)
                        .tz("America/Toronto")
                        .format("MMMM Do YYYY");

                      var all_data_by_date_as_label = {
                        label: date_local,
                        data: {
                          uid,
                          val: { ...val },
                        },
                      };

                      return all_data_by_date_as_label;
                    });

                    var all_data_by_month = _.map(
                      snapshot.val(),
                      (val, uid) => {
                        var month = moment_time_zone(val.survey_submitted_on)
                          .tz("America/Toronto")
                          .format("MMMM YYYY");

                        var all_data_by_month_as_label = {
                          label: month,
                          data: {
                            uid,
                            val: { ...val },
                          },
                        };

                        return all_data_by_month_as_label;
                      }
                    );

                    var all_data_by_year = _.map(snapshot.val(), (val, uid) => {
                      var year = moment_time_zone(val.survey_submitted_on)
                        .tz("America/Toronto")
                        .format("YYYY");

                      var all_data_by_year_as_label = {
                        label: year,
                        data: {
                          uid,
                          val: { ...val },
                        },
                      };

                      return all_data_by_year_as_label;
                    });

                    var all_data_by_survey_name = _.map(
                      snapshot.val(),
                      (val, uid) => {
                        var all_data_by_survey_name_as_label = {};

                        if (val.transaction_info.branch_survey_name) {
                          all_data_by_survey_name_as_label = {
                            label:
                              val.transaction_info.survey_name +
                              " : " +
                              val.transaction_info.branch_survey_name,
                            data: {
                              uid,
                              val: { ...val },
                            },
                          };
                        } else {
                          all_data_by_survey_name_as_label = {
                            label: val.transaction_info.survey_name,
                            data: {
                              uid,
                              val: { ...val },
                            },
                          };
                        }

                        return all_data_by_survey_name_as_label;
                      }
                    );

                    //
                    var seen_all_data = new Map();
                    var seen_all_data_by_date = new Map();
                    var seen_all_data_by_month = new Map();
                    var seen_all_data_by_year = new Map();
                    var seen_all_data_by_survey_name = new Map();

                    all_data = all_data.filter((entry) => {
                      var previous;
                      if (seen_all_data.hasOwnProperty(entry.label)) {
                        previous = seen_all_data[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_all_data[entry.label] = entry;

                      return true;
                    });
                    //

                    //
                    all_data_by_date = all_data_by_date.filter((entry) => {
                      var previous;
                      if (seen_all_data_by_date.hasOwnProperty(entry.label)) {
                        previous = seen_all_data_by_date[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_all_data_by_date[entry.label] = entry;

                      return true;
                    });
                    //

                    //
                    all_data_by_month = all_data_by_month.filter((entry) => {
                      var previous;
                      if (seen_all_data_by_month.hasOwnProperty(entry.label)) {
                        previous = seen_all_data_by_month[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_all_data_by_month[entry.label] = entry;

                      return true;
                    });
                    //

                    //
                    all_data_by_year = all_data_by_year.filter((entry) => {
                      var previous;
                      if (seen_all_data_by_year.hasOwnProperty(entry.label)) {
                        previous = seen_all_data_by_year[entry.label];
                        previous.data.push(entry.data);

                        return false;
                      }
                      if (!Array.isArray(entry.data)) {
                        entry.data = [entry.data];
                      }
                      seen_all_data_by_year[entry.label] = entry;

                      return true;
                    });
                    //

                    //
                    all_data_by_survey_name = all_data_by_survey_name.filter(
                      (entry) => {
                        var previous;
                        if (
                          seen_all_data_by_survey_name.hasOwnProperty(
                            entry.label
                          )
                        ) {
                          previous = seen_all_data_by_survey_name[entry.label];
                          previous.data.push(entry.data);

                          return false;
                        }
                        if (!Array.isArray(entry.data)) {
                          entry.data = [entry.data];
                        }
                        seen_all_data_by_survey_name[entry.label] = entry;

                        return true;
                      }
                    );
                    //

                    /* CONVERTING START */
                    all_data = _.fromPairs(
                      _.map(all_data, (i) => {
                        return [i.label, i.data];
                      })
                    );

                    all_data = _.map(all_data, (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    });

                    /* CONVERTING END */

                    /* CONVERTING START */
                    all_data_by_date = _.fromPairs(
                      _.map(all_data_by_date, (i) => {
                        return [i.label, i.data];
                      })
                    );

                    all_data_by_date = _.map(all_data_by_date, (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    });
                    /* CONVERTING END */

                    /* CONVERTING START */
                    all_data_by_month = _.fromPairs(
                      _.map(all_data_by_month, (i) => {
                        return [i.label, i.data];
                      })
                    );

                    all_data_by_month = _.map(all_data_by_month, (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    });
                    /* CONVERTING END */

                    /* CONVERTING START */
                    all_data_by_year = _.fromPairs(
                      _.map(all_data_by_year, (i) => {
                        return [i.label, i.data];
                      })
                    );

                    all_data_by_year = _.map(all_data_by_year, (val, uid) => {
                      const array_converted = _.fromPairs(
                        _.map(val, (i) => {
                          return [i.uid, i.val];
                        })
                      );

                      return { uid, combined_data: { ...array_converted } };
                    });
                    /* CONVERTING END */

                    /* CONVERTING START */
                    all_data_by_survey_name = _.fromPairs(
                      _.map(all_data_by_survey_name, (i) => {
                        return [i.label, i.data];
                      })
                    );
                    // var old_check = all_data_by_survey_name;
                    //  var new_check = all_data_by_survey_name.sort();

                    // console.log(old_check.sort());
                    //  console.log(new_check);

                    all_data_by_survey_name = _.map(
                      all_data_by_survey_name,
                      (val, uid) => {
                        const array_converted = _.fromPairs(
                          _.map(val, (i) => {
                            return [i.uid, i.val];
                          })
                        );

                        return { uid, combined_data: { ...array_converted } };
                      }
                    );

                    all_data_by_survey_name = _.orderBy(
                      all_data_by_survey_name,
                      ["uid"],
                      ["asc"]
                    ); // sorting alphabetically

                    /* CONVERTING END */

                    all_data_by_survey_name.forEach((survey_name_data) => {
                      var specific_data_by_date = _.map(
                        survey_name_data.combined_data,
                        (val, uid) => {
                          var date_local = moment_time_zone(
                            val.survey_submitted_on
                          )
                            .tz("America/Toronto")
                            .format("MMMM Do YYYY");

                          var specific_data_by_date_as_label = {
                            label: date_local,
                            data: {
                              uid,
                              val: { ...val },
                            },
                          };

                          return specific_data_by_date_as_label;
                        }
                      );

                      var specific_data_by_month = _.map(
                        survey_name_data.combined_data,
                        (val, uid) => {
                          var month = moment_time_zone(val.survey_submitted_on)
                            .tz("America/Toronto")
                            .format("MMMM YYYY");

                          var specific_data_by_month_as_label = {
                            label: month,
                            data: {
                              uid,
                              val: { ...val },
                            },
                          };

                          return specific_data_by_month_as_label;
                        }
                      );

                      var specific_data_by_year = _.map(
                        survey_name_data.combined_data,
                        (val, uid) => {
                          var year = moment_time_zone(val.survey_submitted_on)
                            .tz("America/Toronto")
                            .format("YYYY");

                          var specific_data_by_year_as_label = {
                            label: year,
                            data: {
                              uid,
                              val: { ...val },
                            },
                          };

                          return specific_data_by_year_as_label;
                        }
                      );

                      var seen_specific_data_by_date = new Map();
                      var seen_specific_data_by_month = new Map();
                      var seen_specific_data_by_year = new Map();

                      //
                      specific_data_by_date = specific_data_by_date.filter(
                        (entry) => {
                          var previous;
                          if (
                            seen_specific_data_by_date.hasOwnProperty(
                              entry.label
                            )
                          ) {
                            previous = seen_specific_data_by_date[entry.label];
                            previous.data.push(entry.data);

                            return false;
                          }
                          if (!Array.isArray(entry.data)) {
                            entry.data = [entry.data];
                          }
                          seen_specific_data_by_date[entry.label] = entry;

                          return true;
                        }
                      );
                      //

                      //
                      specific_data_by_month = specific_data_by_month.filter(
                        (entry) => {
                          var previous;
                          if (
                            seen_specific_data_by_month.hasOwnProperty(
                              entry.label
                            )
                          ) {
                            previous = seen_specific_data_by_month[entry.label];
                            previous.data.push(entry.data);

                            return false;
                          }
                          if (!Array.isArray(entry.data)) {
                            entry.data = [entry.data];
                          }
                          seen_specific_data_by_month[entry.label] = entry;

                          return true;
                        }
                      );
                      //

                      //
                      specific_data_by_year = specific_data_by_year.filter(
                        (entry) => {
                          var previous;
                          if (
                            seen_specific_data_by_year.hasOwnProperty(
                              entry.label
                            )
                          ) {
                            previous = seen_specific_data_by_year[entry.label];
                            previous.data.push(entry.data);

                            return false;
                          }
                          if (!Array.isArray(entry.data)) {
                            entry.data = [entry.data];
                          }
                          seen_specific_data_by_year[entry.label] = entry;

                          return true;
                        }
                      );
                      //

                      /* CONVERTING START */
                      specific_data_by_date = _.fromPairs(
                        _.map(specific_data_by_date, (i) => {
                          return [i.label, i.data];
                        })
                      );

                      specific_data_by_date = _.map(
                        specific_data_by_date,
                        (val, uid) => {
                          const array_converted = _.fromPairs(
                            _.map(val, (i) => {
                              return [i.uid, i.val];
                            })
                          );

                          return { uid, combined_data: { ...array_converted } };
                        }
                      );
                      /* CONVERTING END */

                      /* CONVERTING START */
                      specific_data_by_month = _.fromPairs(
                        _.map(specific_data_by_month, (i) => {
                          return [i.label, i.data];
                        })
                      );

                      specific_data_by_month = _.map(
                        specific_data_by_month,
                        (val, uid) => {
                          const array_converted = _.fromPairs(
                            _.map(val, (i) => {
                              return [i.uid, i.val];
                            })
                          );

                          return { uid, combined_data: { ...array_converted } };
                        }
                      );
                      /* CONVERTING END */

                      /* CONVERTING START */
                      specific_data_by_year = _.fromPairs(
                        _.map(specific_data_by_year, (i) => {
                          return [i.label, i.data];
                        })
                      );

                      specific_data_by_year = _.map(
                        specific_data_by_year,
                        (val, uid) => {
                          const array_converted = _.fromPairs(
                            _.map(val, (i) => {
                              return [i.uid, i.val];
                            })
                          );

                          return { uid, combined_data: { ...array_converted } };
                        }
                      );
                      /* CONVERTING END */

                      array_specific.push({
                        survey_name: survey_name_data.uid,
                        specific_data_by_date,
                        specific_data_by_month,
                        specific_data_by_year,
                      });
                    });
                    this.setState({
                      data: {
                        array_specific,
                        all_data,
                        all_data_by_date,
                        all_data_by_month,
                        all_data_by_year,
                        all_data_by_survey_name,
                      },
                      loading: false,
                      first_filter: "specific",
                    });
                  });
              }
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            {list_of_programs}
          </select>
        </div>
      </div>
    );
  }

  first_filter() {
    // const { data } = this.state;
    const {
      selected_program,
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    /*if (data === null) {
      return null;
    }*/

    if (selected_program === "") {
      return null;
    } else {
      if (this.state.loading === true) {
        const defaultOptions_countdown = {
          loop: true,
          autoplay: true,
          animationData: require("./loading.json"),

          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        };

        return (
          <div>
            <Lottie
              options={defaultOptions_countdown}
              height={300}
              width={300}
            />
          </div>
        );
      } else {
        if (raw_all_data === null) {
          return null;
        } else if (raw_all_data.length === 0) {
          return (
            <div>
              <p style={{ fontWeight: "bold", textAlign: "center" }}>
                No Surveys!
              </p>
            </div>
          );
        } else {
          return (
            <div>
              <div className="form-group">
                <label>Select how you want to view the results:</label>
                <select
                  className="form-control"
                  value={this.state.first_filter}
                  onChange={(event) => {
                    event.preventDefault();

                    this.setState({
                      first_filter: event.target.value,
                    });
                  }}
                >
                  <option selected value="">
                    Please Choose
                  </option>
                  <option value="specific">Surveys by Event Name</option>
                  <option value="all">
                    All Surveys for {this.state.selected_program}
                  </option>
                </select>
              </div>
            </div>
          );
        }
      }
    }
  }

  second_filter() {
    // const { data, first_filter, seond_set_of_data } = this.state;
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (first_filter === "") {
      return null;
    } else if (first_filter === "all") {
      return (
        <div>
          <div className="form-group">
            <label>Select Filter:</label>
            <select
              className="form-control"
              value={this.state.second_filter}
              onChange={(event) => {
                event.preventDefault();
                var value = event.target.value;

                this.setState({
                  second_filter: event.target.value,
                });
                if (value !== "") {
                  if (value === "all") {
                    //show all data - add functionality
                    this.setState({
                      seond_set_of_data: data.all_data,
                      time_stamp: null,
                    });

                    this.setState({ showcase_data: raw_all_data });
                  } else if (value === "by_date") {
                    this.setState({
                      seond_set_of_data: data.all_data_by_date,
                      time_stamp: null, //
                    });
                  } else if (value === "by_month") {
                    this.setState({
                      seond_set_of_data: data.all_data_by_month,
                      time_stamp: null,
                    });
                  } else {
                    this.setState({
                      seond_set_of_data: data.all_data_by_year,
                      time_stamp: null,
                    });
                  }
                }
              }}
            >
              <option selected value="">
                Please Choose
              </option>

              <option value="all">All</option>
              <option value="by_date">By Date</option>
              <option value="by_month">By Month</option>
              <option value="by_year">By Year</option>
            </select>
          </div>
        </div>
      );
    }
    const all_data_by_survey_name = _.map(
      data.all_data_by_survey_name,
      (val, uid) => {
        return val;
      }
    );

    const array_specific = _.map(data.array_specific, (val, uid) => {
      return val;
    });

    var list_of_programs = [];
    // console.log(this.state.user_data.all_programs);

    // var temp_suvey_access_list=[]
    var access_programs = _.map(
      this.state.user_data.all_programs,
      (val, uid) => {
        if (this.state.selected_program === val.access) {
          //    temp_suvey_access_list.push({programName:val.access, survey_access})

          var tempAccess = _.map(val.survey_access, (val, uid) => {
            return { ...val, uid };
          });
          //  console.log(tempAccess);

          var tempval = _.map(val.survey_access, (val, uid) => {
            return val;
          });

          console.log(tempval);
          return { programName: val.access, survey_access: tempAccess };
        }
      }
    );

    access_programs.forEach((element_) => {
      var array = element_.survey_access;

      array.forEach((element_access) => {
        all_data_by_survey_name.forEach((element, i) => {
          var use_name = "";
          if (element_access.access_choice_funnel) {
            use_name =
              element_access.uid + " : " + element_access.access_choice_funnel;
          } else {
            use_name = element_access.uid;
          }
          if (use_name === element.uid) {
            list_of_programs.push(<option value={i}>{element.uid}</option>);
          }
        });
      });
    });
    /* element_.survey_access((element_access) => {
        all_data_by_survey_name.forEach((element, i) => {
          if (element_access === element.uid) {
            list_of_programs.push(<option value={i}>{element.uid}</option>);
          }
        });
      });*/

    return (
      <div>
        <div className="form-group">
          <label>Select Event Name:</label>
          <select
            className="form-control"
            onChange={(event) => {
              event.preventDefault();
              var value = event.target.value;
              var name = all_data_by_survey_name[value].uid;

              this.setState({
                // second_filter, //second
                third_filter: "", //third
                fourth_filter: "", //fourth

                second_filter: name,
              });

              this.setState({
                seond_set_of_data: {
                  all_data_by_survey_name: all_data_by_survey_name[value],
                  array_specific: array_specific[value],
                },
              });
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            {list_of_programs}
          </select>
        </div>
      </div>
    );
  }

  third_filter() {
    //  const { data, first_filter, second_filter, seond_set_of_data } = this.state;
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (second_filter === "") {
      return null;
    } else {
      if (first_filter === "all") {
        if (second_filter === "all") {
          //show all data - functionality to show all data is in second_filter()

          return null;
        }

        const all_programs = _.map(seond_set_of_data, (val, uid) => {
          return val;
        });

        var list_of_programs = [];

        all_programs.forEach((element, i) => {
          list_of_programs.push(<option value={i}>{element.uid}</option>);
        });

        var show_label = "";
        if (second_filter === "by_month") {
          show_label = "Select Month:";
        } else if (second_filter === "by_year") {
          show_label = "Select Year:";
        } else {
          show_label = "Select Date:";
        }

        return (
          <div>
            <div className="form-group">
              <label>{show_label}</label>
              <select
                className="form-control"
                onChange={(event) => {
                  //show all data by filter choice
                  event.preventDefault();
                  var value = event.target.value;

                  this.setState({ showcase_data: all_programs[value] });
                }}
              >
                <option selected value="">
                  Please Choose
                </option>
                {list_of_programs}
              </select>
            </div>
          </div>
        );
      } else {
        //  console.log(seond_set_of_data.array_specific.specific_data_by_date);
        return (
          <div>
            <div className="form-group">
              <label>Select Filter:</label>
              <select
                className="form-control"
                value={this.state.third_filter}
                onChange={(event) => {
                  event.preventDefault();
                  var value = event.target.value;

                  this.setState({
                    third_filter: event.target.value,
                  });

                  if (value !== "") {
                    if (value === "all") {
                      //show all data - add functionality

                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.all_data_by_survey_name,
                        time_stamp: null,

                        // .combined_data,
                      });

                      this.setState({
                        showcase_data:
                          seond_set_of_data.all_data_by_survey_name,
                      });

                      //  this.setState({ showcase_data: all_programs[value] });
                    } else if (value === "by_date") {
                      /*var combined_data =
                        seond_set_of_data.array_specific
                          .specific_data_by_date[0].combined_data;
                      console.log(combined_data);

                      combined_data = _.map(combined_data, (val, uid) => {
                        return { ...val, uid };
                      });

                      var time = "";
                      if (combined_data.length > 0) {
                        //   time = combined_data[0].combined_data;
                        console.log(combined_data[0].survey_submitted_on);
                      }*/
                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.array_specific
                            .specific_data_by_date,
                        time_stamp: "show",

                        /* time_stamp: moment(
                          seond_set_of_data.array_specific
                            .specific_data_by_date[0].combined_data
                            .survey_submitted_on
                        ).format(
                          "LLLL"
                        ),*/

                        /*   
                        moment(seond_set_of_data.array_specific.specific_data_by_date[0].combined_data.survey_submitted_on)).format(
                         "MMMM Do YYYY, h:mm"
                         // "dddd, MMMM Do YYYY"
                        )
                        */
                      });
                    } else if (value === "by_month") {
                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.array_specific
                            .specific_data_by_month,
                        time_stamp: null,
                      });
                    } else {
                      this.setState({
                        third_set_of_data:
                          seond_set_of_data.array_specific
                            .specific_data_by_year,
                        time_stamp: null,
                      });
                    }
                  }
                }}
              >
                <option selected value="">
                  Please Choose
                </option>

                <option value="all">All</option>
                <option value="by_date">By Date</option>
                <option value="by_month">By Month</option>
                <option value="by_year">By Year</option>
              </select>
            </div>
          </div>
        );
      }
    }
  }

  fourth_filter() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (third_filter === "" || first_filter === "all") {
      return null;
    } else {
      if (third_filter === "all") {
        //show all data - functionality to show all data is in third_filter()
        return null;
      }

      const all_programs = _.map(third_set_of_data, (val, uid) => {
        return val;
      });

      var list_of_programs = [];

      all_programs.forEach((element, i) => {
        list_of_programs.push(<option value={i}>{element.uid}</option>);
      });

      var show_label = "";
      if (third_filter === "by_month") {
        show_label = "Select Month:";
      } else if (third_filter === "by_year") {
        show_label = "Select Year:";
      } else {
        show_label = "Select Date:";
      }

      return (
        <div>
          <div className="form-group">
            <label>{show_label}</label>
            <select
              className="form-control"
              onChange={(event) => {
                event.preventDefault();
                var value = event.target.value;
                //show all data by filter choice

                var time = null;
                if (show_label === "Select Date:") {
                  var combined_data = all_programs[value].combined_data;

                  combined_data = _.map(combined_data, (val, uid) => {
                    return { ...val, uid };
                  });

                  var time = "";
                  if (combined_data.length > 0) {
                    //   time = combined_data[0].combined_data;
                    //   console.log(combined_data[0].survey_submitted_on);

                    time = moment(combined_data[0].survey_submitted_on).format(
                      "LT"
                    );
                  }

                  // console.log(all_programs[value]);
                } else {
                  time = null;
                }

                /* time_stamp: moment(
                          seond_set_of_data.array_specific
                            .specific_data_by_date[0].combined_data
                            .survey_submitted_on
                        ).format(
                          "LLLL"
                        ),*/

                /*   
                        moment(seond_set_of_data.array_specific.specific_data_by_date[0].combined_data.survey_submitted_on)).format(
                         "MMMM Do YYYY, h:mm"
                         // "dddd, MMMM Do YYYY"
                        )
                        */

                this.setState({
                  showcase_data: all_programs[value],
                  time_stamp: time,
                });
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              {list_of_programs}
            </select>
          </div>
        </div>
      );
    }
  }

  showcase_data() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    var multiple_choice_item_render = [];
    var open_text_item_render = [];

    var multiple_choice = [];
    var open_text = [];

    var data_output = [];
    if (first_filter === "all" && second_filter === "all") {
      var data_output = raw_all_data;
    } else {
      var data_output = _.map(showcase_data.combined_data, (val, uid) => {
        return { ...val, uid };
      });
    }

    data_output.forEach((first_element) => {
      var questionnaire_result = first_element.questionnaire_result;
      var transaction_info = first_element.transaction_info;
      var survey_submitted_on = first_element.survey_submitted_on;

      questionnaire_result.forEach((second_element) => {
        var answer = second_element.answer;
        var question = second_element.question;
        var type_of_question = second_element.type_of_question;
        if (
          type_of_question === "MCQ" ||
          type_of_question === "ConditionalMCQ"
        ) {
          multiple_choice.push({
            question,
            data: {
              question,
              answer,
              transaction_info,
              survey_submitted_on,
              type_of_question,
            },
          });
        } else {
          open_text.push({
            question,
            data: {
              question,
              answer,
              transaction_info,
              survey_submitted_on,
              type_of_question,
            },
          });
        }
      });
    });

    //
    var seen_multiple_choice = new Map();
    var cluster_multiple_choice = multiple_choice.filter((entry) => {
      var previous;
      if (seen_multiple_choice.hasOwnProperty(entry.question)) {
        previous = seen_multiple_choice[entry.question];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_multiple_choice[entry.question] = entry;

      return true;
    });
    //

    //
    var seen_open_text = new Map();
    var cluster_open_text = open_text.filter((entry) => {
      var previous;
      if (seen_open_text.hasOwnProperty(entry.question)) {
        previous = seen_open_text[entry.question];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_open_text[entry.question] = entry;

      return true;
    });

    //Bottom Only for multiple choice START
    cluster_multiple_choice.forEach((first_element, p) => {
      var array = [];
      first_element.data.forEach((second_element) => {
        array.push(second_element.answer);
      });

      array.sort();
      var array_uniqBy_and_count = [];
      var array_uniqBy_choice_only = [];
      var array_uniqBy_count_only = [];

      var current = null;
      var cnt = 0;

      array.forEach((third_element) => {
        if (third_element != current) {
          if (cnt > 0) {
            array_uniqBy_and_count.push({
              choice: current,
              qty: cnt,
            });

            array_uniqBy_choice_only.push(current);
            array_uniqBy_count_only.push(cnt);
          }
          current = third_element;
          cnt = 1;
        } else {
          cnt++;
        }
      });

      if (cnt > 0) {
        array_uniqBy_and_count.push({
          choice: current,
          qty: cnt,
        });
        array_uniqBy_choice_only.push(current);
        array_uniqBy_count_only.push(cnt);
      }

      multiple_choice_item_render.push(
        <div class="col-lg-6" key={p}>
          <div className="card mb-3">
            <div className="card-header">
              <h3>{first_element.question}</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <Doughnut
                  data={{
                    labels: array_uniqBy_choice_only,
                    datalabels: {
                      color: "black",
                    },
                    datasets: [
                      {
                        data: array_uniqBy_count_only,
                        /* backgroundColor: [
                            "#00B8D4",
                            "#00E5FF",
                            "#18FFFF",
                            "#84FFFF",
                            "#B2EBF2",
                            "#E0F7FA",
                          ],*/
                        /*  backgroundColor: [
                          "#3F51B5",
                          "#5C6BC0",
                          "#7986CB",
                          "#9FA8DA",
                          "#C5CAE9",
                          "E8EAF6",
                        ],*/

                        /* backgroundColor: [
                          "#673AB7",
                          "#7E57C2",
                          "#9575CD",
                          "#B39DDB",
                          "#D1C4E9",
                          "#EDE7F6",
                        ],
                        */

                        backgroundColor: [
                          "#998ce3",
                          "#39c2c9",
                          "#fcce01",
                          "#fba71a",
                          "#6aedc7",
                          "#f866b9",
                        ],

                        // backgroundColor: array_color,
                      },
                    ],
                  }}
                  options={{ responsive: true }}
                />
              </div>
            </div>
            <div className="card-footer small text-muted">
              Multiple Choice Response
            </div>
          </div>
        </div>
      );
    });
    //TOP Only for multiple choice END

    cluster_open_text.forEach((first_element, q) => {
      var open_text_data = [];

      first_element.data.forEach((second_element, p) => {
        open_text_data.push({
          id: p + 1,
          response: second_element.answer,
          workshop: second_element.transaction_info.survey_name,
          date: moment(second_element.survey_submitted_on).format(
            "dddd, MMMM Do YYYY"
          ),
        });
      });

      open_text_item_render.push(
        <div key={1}>
          <div style={{ paddingRight: 5, paddingLeft: 5 }}>
            <div className="card mb-3">
              <div className="card-header">
                <h3>{first_element.question}</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div style={{ paddingBottom: 85 }}>
                    <ToolkitProvider
                      keyField="Open_Field"
                      data={open_text_data}
                      columns={[
                        {
                          dataField: "id",
                          text: "ID",
                          sort: true,
                        },
                        {
                          dataField: "response",
                          text: "Response",
                          sort: true,
                        },
                        {
                          dataField: "workshop",
                          text: "Workshop",
                          sort: true,
                        },
                        {
                          dataField: "date",
                          text: "Date",
                          sort: true,
                        },
                      ]}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div
                          style={{
                            paddingRight: 20,
                            paddingLeft: 20,
                          }}
                        >
                          <SearchBar {...props.searchProps} />
                          <hr />
                          <ExportCSVButton
                            {...props.csvProps}
                            style={{ backgroundColor: "gray" }}
                          >
                            Export CSV!!
                          </ExportCSVButton>
                          <hr />
                          <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory({
                              paginationSize: 4,
                              pageStartIndex: 0,
                              firstPageText: "First",
                              prePageText: "Back",
                              nextPageText: "Next",
                              lastPageText: "Last",
                              nextPageTitle: "First page",
                              prePageTitle: "Pre page",
                              firstPageTitle: "Next page",
                              lastPageTitle: "Last page",
                              showTotal: true,
                              paginationTotalRenderer: customTotal,

                              sizePerPageList: [
                                {
                                  text: "5",
                                  value: 5,
                                },
                                {
                                  text: "25",
                                  value: 25,
                                },
                                {
                                  text: "All",
                                  value: open_text_data.length, //Open_Field_Questions.length
                                },
                              ],
                              // A numeric array is also available. the purpose of above example is custom the text
                            })}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
              <div className="card-footer small text-muted">
                Open Field Response
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div class="container">
          <div style={{ paddingBottom: 25 }}>
            <div
              className="card-header"
              style={{
                paddingBottom: 25,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <h3>Multiple Choice Response</h3>
            </div>
          </div>
          <div class="row">{multiple_choice_item_render}</div>
        </div>

        <div class="container">
          <div style={{ paddingBottom: 25 }}>
            <div
              className="card-header"
              style={{
                paddingBottom: 25,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <h3>Open Field Response</h3>
            </div>
          </div>
          {open_text_item_render}
        </div>
      </div>
    );
  }

  render_loading() {
    const defaultOptions_countdown = {
      loop: true,
      autoplay: true,
      animationData: require("./loading.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div>
        <Lottie options={defaultOptions_countdown} height={300} width={300} />
      </div>
    );
  }
  render() {
    const { user_data, data, showcase_data } = this.state;

    if (user_data === null) {
      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container">
              <div>
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a>LOADING</a>
                  </li>
                </ol>
              </div>
            </div>
            <Footer />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container">{this.top_portion()}</div>

            <div className="container">
              <div className="row">
                <div class="col-xl-12 col-sm-12 mb-12">
                  {showcase_data === null ? (
                    <div>
                      {this.selection_for_program()}
                      {/*this.first_filter()*/}

                      {this.state.loading === false ? (
                        <div>
                          {this.second_filter()}
                          {this.third_filter()}
                          {this.fourth_filter()}
                        </div>
                      ) : (
                        <div>{this.render_loading()}</div>
                      )}
                    </div>
                  ) : (
                    <div>{this.showcase_data()}</div>
                  )}
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Dashboard;

/*
import _ from "lodash";
import React, { Component } from "react";
import Footer from ".././Footer";
import * as firebase from "firebase";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
import moment_time_zone from "moment-timezone";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total">
    Showing {from} to {to} of {size} Results
  </span>
);

class Dashboard extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      // data: {},
      data_array: [],
      initial_data_pull: {},
      MCQ_Array: [],
      Open_Field_Array: [],
      MCQ_Array_temp_answers: [],
      Open_Field_Array_temp_answers: [],
      MCQ_Array_col_answers_count: [],
      MCQ_Array_col_answers_shrinked: [],
      MCQ_Questions: [],
      Open_Field_Array_col_answers: [],
      Open_Field_Questions: [],
      store_selected: "",
      name_of_the_survey: "",

      data_initial_pull: {},
      second_pull_all_questionnaire: null,
      view_selection: "",
      // user_data: {},
      // selected_program: "",
      survey_access_array: [],
      filter_name: "",
      surveys_status: null,

      user_data: null,
      raw_all_data: null,
      data: null,
      seond_set_of_data: null,
      third_set_of_data: null,
      showcase_data: null,
      selected_program: "",
      first_filter: "all",
      second_filter: "",
      third_filter: "",
      fourth_filter: "",
      loading: false,
      RAW: {},
    };
  }

  componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function (snapshot) {
          var user_data = snapshot.val();

          console.log(user_data);
          this.setState({ user_data });
        }.bind(this)
      );
  }

  goBackToDashboard() {
    this.setState({
      data: {},
      data_array: [],
      initial_data_pull: {},
      MCQ_Array: [],
      Open_Field_Array: [],
      MCQ_Array_temp_answers: [],
      Open_Field_Array_temp_answers: [],
      MCQ_Array_col_answers_count: [],
      MCQ_Array_col_answers_shrinked: [],
      MCQ_Questions: [],
      Open_Field_Array_col_answers: [],
      Open_Field_Questions: [],
      store_selected: "",
      name_of_the_survey: "",
      data_initial_pull: {},
      second_pull_all_questionnaire: null,
      view_selection: "",
      selected_program: "",
      survey_access_array: [],
      filter_name: "",
    });
  }

  selection_for_program_first() {
    const { user_data } = this.state;
    const all_programs = _.map(user_data.all_programs, (val, uid) => {
      return val;
    });

    var list_of_programs = [];
    for (var p = 0; p < all_programs.length; p++) {
      list_of_programs.push(
        <option
          value={p}
          //value={all_programs[p].access}
        >
          {all_programs[p].access}
        </option>
      );
    }
    return (
      <div>
        <div className="form-group">
          <label>Select the Program:</label>
          <select
            className="form-control"
            // value={this.state.selected_program}
            onChange={(event) => {
              event.preventDefault();

              var user = firebase.auth().currentUser;
              var program = all_programs[parseInt(event.target.value)];
              var survey_access = program.survey_access;

              var survey_access_array = [];

              survey_access_array = _.map(survey_access, (val, uid) => {
                return uid;
              });

              firebase
                .database()
                .ref(
                  `QR_Code/Survey_Submitted/${user_data.company_or_institution_name}/${program.access}/All_Responses_Combined/all_questionnaire/All` ///All_Responses_Combined/all_questionnaire/All`
                )
                .once("value", (snapshot) => {
                  this.setState({
                    RAW: snapshot.val(),
                  });
                });

              this.setState({
                selected_program: program,
                survey_access_array,
              });
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            {list_of_programs}
          </select>
        </div>
      </div>
    );
  }

  goBackToDashboard() {
    this.setState({
      //  user_data: null,
      // raw_all_data: null,
      // data: null,
      // seond_set_of_data: null,
      // third_set_of_data: null,
      showcase_data: null,
      //  selected_program: "",
      //   first_filter: "",
      //  second_filter: "",
      //  third_filter: "",
      //  fourth_filter: "",
    });
  }

  top_portion() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    var first = "";
    var second = "";
    var third = "";

    if (showcase_data !== null) {
      if (first_filter === "all") {
        console.log({ first_filter }); //all
        console.log({ second_filter }); //by_month, by_date, by_year, all
        console.log({ showcase_data }); //showcase_data.uid is the specific month

        first = "All Surveys";
        //  third = "";
      } else {
        console.log({ first_filter }); //specific
        console.log({ second_filter }); //Name of the survey
        console.log({ third_filter }); //by_month, by_date, by_year, all
        console.log({ showcase_data }); //showcase_data.uid is the specific month

        first = second_filter;
        //    third = showcase_data.uid;
      }
      third = showcase_data.uid;
      if (second_filter === "by_month" || third_filter === "by_month") {
        //
        second = "By Month";
      } else if (second_filter === "by_date" || third_filter === "by_date") {
        //
        second = "By Date";
      } else if (second_filter === "by_year" || third_filter === "by_year") {
        //
        second = "By Year";
      } else {
        //
        second = "All to Date";
      }
    }

    return (
      <div>
        {showcase_data === null ? (
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a>Dashboard</a>
            </li>
          </ol>
        ) : (
          <ol class="breadcrumb">
            <li

            //class="breadcrumb-item"
            >
              <a
                style={{ color: "#0277BD", cursor: "pointer" }}
                onClick={this.goBackToDashboard.bind(this)}
              >
                GO BACK
              </a>
            </li>

            <li style={{ paddingRight: 10, paddingLeft: 10 }}> | </li>

            <li class="breadcrumb-item">{first}</li>
            <li class="breadcrumb-item">{second}</li>
            {second === "All to Date" ? null : (
              <li class="breadcrumb-item">{third}</li>
            )}
          </ol>
        )}
      </div>
    );
  }
  selection_for_program() {
    const { user_data, survey_access_array, selected_program } = this.state;
    var array_specific = [];

    //console.log(survey_access_array);
    const all_programs = survey_access_array;

    var list_of_programs = [];
    all_programs.forEach((element) => {
      list_of_programs.push(<option value={element}>{element}</option>);
    });
    if (selected_program === "") {
      return null;
    }
    return (
      <div>
        <div className="form-group">
          <label>Select the Workshop/Seminar/Category:</label>
          <select
            className="form-control"
            // value={this.state.selected_program}
            onChange={(event) => {
              event.preventDefault();
              this.setState({ loading: true });
              var value = event.target.value;
              var company_or_institution_name =
                user_data.company_or_institution_name;

              var raw_all_data = _.map(this.state.RAW, (val, uid) => {
                return { ...val, uid };
              });
              this.setState({
                raw_all_data,
              });

              /// console.log(raw_all_data);

              var all_data = _.map(this.state.RAW, (val, uid) => {
                var all = {
                  label: uid,
                  data: {
                    uid,
                    val: { ...val },
                  },
                };

                return all;
              });

              var all_data_by_date = _.map(this.state.RAW, (val, uid) => {
                var date_local = moment_time_zone(val.survey_submitted_on)
                  .tz("America/Toronto")
                  .format("MMMM Do YYYY");

                var all_data_by_date_as_label = {
                  label: date_local,
                  data: {
                    uid,
                    val: { ...val },
                  },
                };

                return all_data_by_date_as_label;
              });

              var all_data_by_month = _.map(this.state.RAW, (val, uid) => {
                var month = moment_time_zone(val.survey_submitted_on)
                  .tz("America/Toronto")
                  .format("MMMM");

                var all_data_by_month_as_label = {
                  label: month,
                  data: {
                    uid,
                    val: { ...val },
                  },
                };

                return all_data_by_month_as_label;
              });

              var all_data_by_year = _.map(this.state.RAW, (val, uid) => {
                var year = moment_time_zone(val.survey_submitted_on)
                  .tz("America/Toronto")
                  .format("YYYY");

                var all_data_by_year_as_label = {
                  label: year,
                  data: {
                    uid,
                    val: { ...val },
                  },
                };

                return all_data_by_year_as_label;
              });

              var all_data_by_survey_name = _.map(
                this.state.RAW,
                (val, uid) => {
                  var all_data_by_survey_name_as_label = {
                    label: val.transaction_info.survey_name,
                    data: {
                      uid,
                      val: { ...val },
                    },
                  };

                  return all_data_by_survey_name_as_label;
                }
              );

              //
              var seen_all_data = new Map();
              var seen_all_data_by_date = new Map();
              var seen_all_data_by_month = new Map();
              var seen_all_data_by_year = new Map();
              var seen_all_data_by_survey_name = new Map();

              all_data = all_data.filter((entry) => {
                var previous;
                if (seen_all_data.hasOwnProperty(entry.label)) {
                  previous = seen_all_data[entry.label];
                  previous.data.push(entry.data);

                  return false;
                }
                if (!Array.isArray(entry.data)) {
                  entry.data = [entry.data];
                }
                seen_all_data[entry.label] = entry;

                return true;
              });
              //

              //
              all_data_by_date = all_data_by_date.filter((entry) => {
                var previous;
                if (seen_all_data_by_date.hasOwnProperty(entry.label)) {
                  previous = seen_all_data_by_date[entry.label];
                  previous.data.push(entry.data);

                  return false;
                }
                if (!Array.isArray(entry.data)) {
                  entry.data = [entry.data];
                }
                seen_all_data_by_date[entry.label] = entry;

                return true;
              });
              //

              //
              all_data_by_month = all_data_by_month.filter((entry) => {
                var previous;
                if (seen_all_data_by_month.hasOwnProperty(entry.label)) {
                  previous = seen_all_data_by_month[entry.label];
                  previous.data.push(entry.data);

                  return false;
                }
                if (!Array.isArray(entry.data)) {
                  entry.data = [entry.data];
                }
                seen_all_data_by_month[entry.label] = entry;

                return true;
              });
              //

              //
              all_data_by_year = all_data_by_year.filter((entry) => {
                var previous;
                if (seen_all_data_by_year.hasOwnProperty(entry.label)) {
                  previous = seen_all_data_by_year[entry.label];
                  previous.data.push(entry.data);

                  return false;
                }
                if (!Array.isArray(entry.data)) {
                  entry.data = [entry.data];
                }
                seen_all_data_by_year[entry.label] = entry;

                return true;
              });
              //

              //
              all_data_by_survey_name = all_data_by_survey_name.filter(
                (entry) => {
                  var previous;
                  if (
                    seen_all_data_by_survey_name.hasOwnProperty(entry.label)
                  ) {
                    previous = seen_all_data_by_survey_name[entry.label];
                    previous.data.push(entry.data);

                    return false;
                  }
                  if (!Array.isArray(entry.data)) {
                    entry.data = [entry.data];
                  }
                  seen_all_data_by_survey_name[entry.label] = entry;

                  return true;
                }
              );
              //

              all_data = _.fromPairs(
                _.map(all_data, (i) => {
                  return [i.label, i.data];
                })
              );

              all_data = _.map(all_data, (val, uid) => {
                const array_converted = _.fromPairs(
                  _.map(val, (i) => {
                    return [i.uid, i.val];
                  })
                );

                return { uid, combined_data: { ...array_converted } };
              });

              all_data_by_date = _.fromPairs(
                _.map(all_data_by_date, (i) => {
                  return [i.label, i.data];
                })
              );

              all_data_by_date = _.map(all_data_by_date, (val, uid) => {
                const array_converted = _.fromPairs(
                  _.map(val, (i) => {
                    return [i.uid, i.val];
                  })
                );

                return { uid, combined_data: { ...array_converted } };
              });

              all_data_by_month = _.fromPairs(
                _.map(all_data_by_month, (i) => {
                  return [i.label, i.data];
                })
              );

              all_data_by_month = _.map(all_data_by_month, (val, uid) => {
                const array_converted = _.fromPairs(
                  _.map(val, (i) => {
                    return [i.uid, i.val];
                  })
                );

                return { uid, combined_data: { ...array_converted } };
              });

              all_data_by_year = _.fromPairs(
                _.map(all_data_by_year, (i) => {
                  return [i.label, i.data];
                })
              );

              all_data_by_year = _.map(all_data_by_year, (val, uid) => {
                const array_converted = _.fromPairs(
                  _.map(val, (i) => {
                    return [i.uid, i.val];
                  })
                );

                return { uid, combined_data: { ...array_converted } };
              });

              all_data_by_survey_name = _.fromPairs(
                _.map(all_data_by_survey_name, (i) => {
                  return [i.label, i.data];
                })
              );

              all_data_by_survey_name = _.map(
                all_data_by_survey_name,
                (val, uid) => {
                  const array_converted = _.fromPairs(
                    _.map(val, (i) => {
                      return [i.uid, i.val];
                    })
                  );

                  return { uid, combined_data: { ...array_converted } };
                }
              );

              all_data_by_survey_name.forEach((survey_name_data) => {
                var specific_data_by_date = _.map(
                  survey_name_data.combined_data,
                  (val, uid) => {
                    var date_local = moment_time_zone(val.survey_submitted_on)
                      .tz("America/Toronto")
                      .format("MMMM Do YYYY");

                    var specific_data_by_date_as_label = {
                      label: date_local,
                      data: {
                        uid,
                        val: { ...val },
                      },
                    };

                    return specific_data_by_date_as_label;
                  }
                );

                var specific_data_by_month = _.map(
                  survey_name_data.combined_data,
                  (val, uid) => {
                    var month = moment_time_zone(val.survey_submitted_on)
                      .tz("America/Toronto")
                      .format("MMMM");

                    var specific_data_by_month_as_label = {
                      label: month,
                      data: {
                        uid,
                        val: { ...val },
                      },
                    };

                    return specific_data_by_month_as_label;
                  }
                );

                var specific_data_by_year = _.map(
                  survey_name_data.combined_data,
                  (val, uid) => {
                    var year = moment_time_zone(val.survey_submitted_on)
                      .tz("America/Toronto")
                      .format("YYYY");

                    var specific_data_by_year_as_label = {
                      label: year,
                      data: {
                        uid,
                        val: { ...val },
                      },
                    };

                    return specific_data_by_year_as_label;
                  }
                );

                var seen_specific_data_by_date = new Map();
                var seen_specific_data_by_month = new Map();
                var seen_specific_data_by_year = new Map();

                //
                specific_data_by_date = specific_data_by_date.filter(
                  (entry) => {
                    var previous;
                    if (
                      seen_specific_data_by_date.hasOwnProperty(entry.label)
                    ) {
                      previous = seen_specific_data_by_date[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_specific_data_by_date[entry.label] = entry;

                    return true;
                  }
                );
                //

                //
                specific_data_by_month = specific_data_by_month.filter(
                  (entry) => {
                    var previous;
                    if (
                      seen_specific_data_by_month.hasOwnProperty(entry.label)
                    ) {
                      previous = seen_specific_data_by_month[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_specific_data_by_month[entry.label] = entry;

                    return true;
                  }
                );
                //

                //
                specific_data_by_year = specific_data_by_year.filter(
                  (entry) => {
                    var previous;
                    if (
                      seen_specific_data_by_year.hasOwnProperty(entry.label)
                    ) {
                      previous = seen_specific_data_by_year[entry.label];
                      previous.data.push(entry.data);

                      return false;
                    }
                    if (!Array.isArray(entry.data)) {
                      entry.data = [entry.data];
                    }
                    seen_specific_data_by_year[entry.label] = entry;

                    return true;
                  }
                );
                //

                specific_data_by_date = _.fromPairs(
                  _.map(specific_data_by_date, (i) => {
                    return [i.label, i.data];
                  })
                );

                specific_data_by_date = _.map(
                  specific_data_by_date,
                  (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  }
                );

                specific_data_by_month = _.fromPairs(
                  _.map(specific_data_by_month, (i) => {
                    return [i.label, i.data];
                  })
                );

                specific_data_by_month = _.map(
                  specific_data_by_month,
                  (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  }
                );

                specific_data_by_year = _.fromPairs(
                  _.map(specific_data_by_year, (i) => {
                    return [i.label, i.data];
                  })
                );

                specific_data_by_year = _.map(
                  specific_data_by_year,
                  (val, uid) => {
                    const array_converted = _.fromPairs(
                      _.map(val, (i) => {
                        return [i.uid, i.val];
                      })
                    );

                    return { uid, combined_data: { ...array_converted } };
                  }
                );

                array_specific.push({
                  survey_name: survey_name_data.uid,
                  specific_data_by_date,
                  specific_data_by_month,
                  specific_data_by_year,
                });
              });
              this.setState({
                data: {
                  array_specific,
                  all_data,
                  all_data_by_date,
                  all_data_by_month,
                  all_data_by_year,
                  all_data_by_survey_name,
                },
                loading: false,
              });
              // });
            }}
          >
            <option selected value="">
              Please Choose
            </option>
            {list_of_programs}
          </select>
        </div>
      </div>
    );
  }

  first_filter() {
    const {
      selected_program,
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (selected_program === "") {
      return null;
    } else {
      if (this.state.loading === true) {
        const defaultOptions_countdown = {
          loop: true,
          autoplay: true,
          animationData: require("./loading.json"),

          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        };

        return (
          <div>
            <p>LOADING</p>
          </div>
        );
      }

      return (
        <div>
          <div className="form-group">
            <label>Select how you want to view the results:</label>
            <select
              className="form-control"
              value={this.state.first_filter}
              onChange={(event) => {
                event.preventDefault();

                this.setState({
                  first_filter: event.target.value,
                });
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              <option value="specific">Surveys By Name</option>
              <option value="all">All Surveys</option>
            </select>
          </div>
        </div>
      );
    }
  }

  second_filter() {
    // const { data, first_filter, seond_set_of_data } = this.state;
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (raw_all_data === null) {
      return null;
    } else if (raw_all_data.length === 0) {
      return <div></div>;
    } else {
      if (first_filter === "") {
        return null;
      } else if (first_filter === "all") {
        return (
          <div>
            <div className="form-group">
              <label>Select Filter:</label>
              <select
                className="form-control"
                value={this.state.second_filter}
                onChange={(event) => {
                  event.preventDefault();
                  var value = event.target.value;

                  this.setState({
                    second_filter: event.target.value,
                  });
                  if (value !== "") {
                    if (value === "all") {
                      //show all data - add functionality
                      this.setState({
                        seond_set_of_data: data.all_data,
                      });

                      this.setState({ showcase_data: raw_all_data });
                    } else if (value === "by_date") {
                      this.setState({
                        seond_set_of_data: data.all_data_by_date,
                      });
                    } else if (value === "by_month") {
                      this.setState({
                        seond_set_of_data: data.all_data_by_month,
                      });
                    } else {
                      this.setState({
                        seond_set_of_data: data.all_data_by_year,
                      });
                    }
                  }
                }}
              >
                <option selected value="">
                  Please Choose
                </option>

                <option value="all">All</option>
                <option value="by_date">By Date</option>
                <option value="by_month">By Month</option>
                <option value="by_year">By Year</option>
              </select>
            </div>
          </div>
        );
      }
      const all_data_by_survey_name = _.map(
        data.all_data_by_survey_name,
        (val, uid) => {
          return val;
        }
      );

      const array_specific = _.map(data.array_specific, (val, uid) => {
        return val;
      });

      var list_of_programs = [];

      all_data_by_survey_name.forEach((element, i) => {
        list_of_programs.push(<option value={i}>{element.uid}</option>);
      });

      return (
        <div>
          <div className="form-group">
            <label>Select Survey Name:</label>
            <select
              className="form-control"
              onChange={(event) => {
                event.preventDefault();
                var value = event.target.value;
                var name = all_data_by_survey_name[value].uid;

                this.setState({
                  second_filter: name,
                });

                this.setState({
                  seond_set_of_data: {
                    all_data_by_survey_name: all_data_by_survey_name[value],
                    array_specific: array_specific[value],
                  },
                });
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              {list_of_programs}
            </select>
          </div>
        </div>
      );
    }
  }

  third_filter() {
    //  const { data, first_filter, second_filter, seond_set_of_data } = this.state;
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (raw_all_data === null) {
      return null;
    } else if (raw_all_data.length === 0) {
      return (
        <div>
          <p style={{ fontWeight: "bold", textAlign: "center" }}>No Surveys!</p>
        </div>
      );
    } else {
      if (second_filter === "") {
        return null;
      } else {
        if (first_filter === "all") {
          if (second_filter === "all") {
            //show all data - functionality to show all data is in second_filter()

            return null;
          }

          const all_programs = _.map(seond_set_of_data, (val, uid) => {
            return val;
          });

          var list_of_programs = [];

          all_programs.forEach((element, i) => {
            list_of_programs.push(<option value={i}>{element.uid}</option>);
          });

          var show_label = "";
          if (second_filter === "by_month") {
            show_label = "Select Month:";
          } else if (second_filter === "by_year") {
            show_label = "Select Year:";
          } else {
            show_label = "Select Date:";
          }

          return (
            <div>
              <div className="form-group">
                <label>{show_label}</label>
                <select
                  className="form-control"
                  onChange={(event) => {
                    //show all data by filter choice
                    event.preventDefault();
                    var value = event.target.value;

                    this.setState({ showcase_data: all_programs[value] });
                  }}
                >
                  <option selected value="">
                    Please Choose
                  </option>
                  {list_of_programs}
                </select>
              </div>
            </div>
          );
        } else {
          return (
            <div>
              <div className="form-group">
                <label>Select Filter:</label>
                <select
                  className="form-control"
                  // value={this.state.third_filter}
                  onChange={(event) => {
                    event.preventDefault();
                    var value = event.target.value;

                    this.setState({
                      third_filter: event.target.value,
                    });

                    if (value !== "") {
                      if (value === "all") {
                        //show all data - add functionality

                        this.setState({
                          third_set_of_data:
                            seond_set_of_data.all_data_by_survey_name,
                          // .combined_data,
                        });

                        this.setState({
                          showcase_data:
                            seond_set_of_data.all_data_by_survey_name,
                        });

                        //  this.setState({ showcase_data: all_programs[value] });
                      } else if (value === "by_date") {
                        this.setState({
                          third_set_of_data:
                            seond_set_of_data.array_specific
                              .specific_data_by_date,
                        });
                      } else if (value === "by_month") {
                        this.setState({
                          third_set_of_data:
                            seond_set_of_data.array_specific
                              .specific_data_by_month,
                        });
                      } else {
                        this.setState({
                          third_set_of_data:
                            seond_set_of_data.array_specific
                              .specific_data_by_year,
                        });
                      }
                    }
                  }}
                >
                  <option selected value="">
                    Please Choose
                  </option>

                  <option value="all">All</option>
                  <option value="by_date">By Date</option>
                  <option value="by_month">By Month</option>
                  <option value="by_year">By Year</option>
                </select>
              </div>
            </div>
          );
        }
      }
    }
  }

  fourth_filter() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    if (third_filter === "" || first_filter === "all") {
      return null;
    } else {
      if (third_filter === "all") {
        //show all data - functionality to show all data is in third_filter()
        return null;
      }

      const all_programs = _.map(third_set_of_data, (val, uid) => {
        return val;
      });

      var list_of_programs = [];

      all_programs.forEach((element, i) => {
        list_of_programs.push(<option value={i}>{element.uid}</option>);
      });

      var show_label = "";
      if (third_filter === "by_month") {
        show_label = "Select Month:";
      } else if (third_filter === "by_year") {
        show_label = "Select Year:";
      } else {
        show_label = "Select Date:";
      }

      return (
        <div>
          <div className="form-group">
            <label>{show_label}</label>
            <select
              className="form-control"
              onChange={(event) => {
                event.preventDefault();
                var value = event.target.value;
                //show all data by filter choice
                this.setState({ showcase_data: all_programs[value] });
              }}
            >
              <option selected value="">
                Please Choose
              </option>
              {list_of_programs}
            </select>
          </div>
        </div>
      );
    }
  }

  showcase_data() {
    const {
      data,
      seond_set_of_data,
      third_set_of_data,
      raw_all_data,
      showcase_data,
      first_filter, //first
      second_filter, //second
      third_filter, //third
      fourth_filter, //fourth
    } = this.state;

    var multiple_choice_item_render = [];
    var open_text_item_render = [];

    var multiple_choice = [];
    var open_text = [];

    var data_output = [];
    if (first_filter === "all" && second_filter === "all") {
      var data_output = raw_all_data;
    } else {
      var data_output = _.map(showcase_data.combined_data, (val, uid) => {
        return { ...val, uid };
      });
    }

    data_output.forEach((first_element) => {
      var questionnaire_result = first_element.questionnaire_result;
      var transaction_info = first_element.transaction_info;
      var survey_submitted_on = first_element.survey_submitted_on;

      questionnaire_result.forEach((second_element) => {
        var answer = second_element.answer;
        var question = second_element.question;
        var type_of_question = second_element.type_of_question;
        if (type_of_question === "MCQ") {
          multiple_choice.push({
            question,
            data: {
              question,
              answer,
              transaction_info,
              survey_submitted_on,
              type_of_question,
            },
          });
        } else {
          open_text.push({
            question,
            data: {
              question,
              answer,
              transaction_info,
              survey_submitted_on,
              type_of_question,
            },
          });
        }
      });
    });

    //
    var seen_multiple_choice = new Map();
    var cluster_multiple_choice = multiple_choice.filter((entry) => {
      var previous;
      if (seen_multiple_choice.hasOwnProperty(entry.question)) {
        previous = seen_multiple_choice[entry.question];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_multiple_choice[entry.question] = entry;

      return true;
    });
    //

    //
    var seen_open_text = new Map();
    var cluster_open_text = open_text.filter((entry) => {
      var previous;
      if (seen_open_text.hasOwnProperty(entry.question)) {
        previous = seen_open_text[entry.question];
        previous.data.push(entry.data);

        return false;
      }
      if (!Array.isArray(entry.data)) {
        entry.data = [entry.data];
      }
      seen_open_text[entry.question] = entry;

      return true;
    });

    //Bottom Only for multiple choice START
    cluster_multiple_choice.forEach((first_element, p) => {
      var array = [];
      first_element.data.forEach((second_element) => {
        array.push(second_element.answer);
      });

      array.sort();
      var array_uniqBy_and_count = [];
      var array_uniqBy_choice_only = [];
      var array_uniqBy_count_only = [];

      var current = null;
      var cnt = 0;

      array.forEach((third_element) => {
        if (third_element != current) {
          if (cnt > 0) {
            array_uniqBy_and_count.push({
              choice: current,
              qty: cnt,
            });

            array_uniqBy_choice_only.push(current);
            array_uniqBy_count_only.push(cnt);
          }
          current = third_element;
          cnt = 1;
        } else {
          cnt++;
        }
      });

      if (cnt > 0) {
        array_uniqBy_and_count.push({
          choice: current,
          qty: cnt,
        });
        array_uniqBy_choice_only.push(current);
        array_uniqBy_count_only.push(cnt);
      }

      multiple_choice_item_render.push(
        <div class="col-lg-6" key={p}>
          <div className="card mb-3">
            <div className="card-header">
              <h3>{first_element.question}</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <Doughnut
                  data={{
                    labels: array_uniqBy_choice_only,
                    datalabels: {
                      color: "black",
                    },
                    datasets: [
                      {
                        data: array_uniqBy_count_only,

                        backgroundColor: [
                          "#998ce3",
                          "#39c2c9",
                          "#fcce01",
                          "#fba71a",
                          "#6aedc7",
                          "#f866b9",
                        ],
                      },
                    ],
                  }}
                  options={{ responsive: true }}
                />
              </div>
            </div>
            <div className="card-footer small text-muted">
              Multiple Choice Response
            </div>
          </div>
        </div>
      );
    });
    //TOP Only for multiple choice END

    cluster_open_text.forEach((first_element, q) => {
      var open_text_data = [];

      first_element.data.forEach((second_element, p) => {
        open_text_data.push({
          id: p + 1,
          response: second_element.answer,
          workshop: second_element.transaction_info.survey_name,
          date: moment(second_element.survey_submitted_on).format(
            "dddd, MMMM Do YYYY"
          ),
        });
      });

      open_text_item_render.push(
        <div key={1}>
          <div style={{ paddingRight: 5, paddingLeft: 5 }}>
            <div className="card mb-3">
              <div className="card-header">
                <h3>{first_element.question}</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <div style={{ paddingBottom: 85 }}>
                    <ToolkitProvider
                      keyField="Open_Field"
                      data={open_text_data}
                      columns={[
                        {
                          dataField: "id",
                          text: "ID",
                          sort: true,
                        },
                        {
                          dataField: "response",
                          text: "Response",
                          sort: true,
                        },
                        {
                          dataField: "workshop",
                          text: "Workshop",
                          sort: true,
                        },
                        {
                          dataField: "date",
                          text: "Date",
                          sort: true,
                        },
                      ]}
                      search
                      exportCSV
                    >
                      {(props) => (
                        <div
                          style={{
                            paddingRight: 20,
                            paddingLeft: 20,
                          }}
                        >
                          <SearchBar {...props.searchProps} />
                          <hr />
                          <ExportCSVButton
                            {...props.csvProps}
                            style={{ backgroundColor: "gray" }}
                          >
                            Export CSV!!
                          </ExportCSVButton>
                          <hr />
                          <BootstrapTable
                            {...props.baseProps}
                            pagination={paginationFactory({
                              paginationSize: 4,
                              pageStartIndex: 0,
                              firstPageText: "First",
                              prePageText: "Back",
                              nextPageText: "Next",
                              lastPageText: "Last",
                              nextPageTitle: "First page",
                              prePageTitle: "Pre page",
                              firstPageTitle: "Next page",
                              lastPageTitle: "Last page",
                              showTotal: true,
                              paginationTotalRenderer: customTotal,

                              sizePerPageList: [
                                {
                                  text: "5",
                                  value: 5,
                                },
                                {
                                  text: "25",
                                  value: 25,
                                },
                                {
                                  text: "All",
                                  value: open_text_data.length, //Open_Field_Questions.length
                                },
                              ],
                              // A numeric array is also available. the purpose of above example is custom the text
                            })}
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </div>
                </div>
              </div>
              <div className="card-footer small text-muted">
                Open Field Response
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div class="container">
          <div style={{ paddingBottom: 25 }}>
            <div
              className="card-header"
              style={{
                paddingBottom: 25,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <h3>Multiple Choice Response</h3>
            </div>
          </div>
          <div class="row">{multiple_choice_item_render}</div>
        </div>

        <div class="container">
          <div style={{ paddingBottom: 25 }}>
            <div
              className="card-header"
              style={{
                paddingBottom: 25,
                borderRadius: 10,
                borderWidth: 1,
              }}
            >
              <h3>Open Field Response</h3>
            </div>
          </div>
          {open_text_item_render}
        </div>
      </div>
    );
  }

  render() {
    const { user_data, data, showcase_data } = this.state;

    // if (user_data === null) {

    console.log(user_data);

    if (user_data === null) {
      return null;
    } else if (user_data.dashboard_access.access === false) {
      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container text-center">
              <p style={{ color: "red" }}>
                Your access is still in pending. Please ask your
                manager/supervisor for authorization
              </p>
            </div>
            <Footer />
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container">{this.top_portion()}</div>

            <div className="container">
              <div className="row">
                <div class="col-xl-12 col-sm-12 mb-12">
                  {showcase_data === null ? (
                    <div>
                      {this.selection_for_program_first()}
                      {this.selection_for_program()}
                      {this.second_filter()}
                      {this.third_filter()}
                      {this.fourth_filter()}
                    </div>
                  ) : (
                    <div>{this.showcase_data()}</div>
                  )}
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </React.Fragment>
      );
    }
  }
}

export default Dashboard;


*/
