import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";
import logo_for_qr from "./logo_for_qr.svg";

import "../../vendor/new-age.min.css";
import "../../vendor/bootstrap.css";

import Lottie from "react-lottie";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { QRCode } from "react-qr-svg";

//import QRCode from "qrcode.react";

class AddNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      qr_screen: false,
      draw_number: "",
      draw: false,
      countdown: false,
      selected_program: null,
      questionnaire: null,
      selected_institution_or_organization: null
    };
  }

  componentWillMount() {
    var prep_bootcamps = {
      draw: true,
      email_required: false,
      general_prize_draw: "",
      in_person_draw: true,
      subText:
        "How did you like the  workshop? Tell us in this quick survey, and win a prize! (Psst! It takes less than 2 minutes to complete!)",
      surveyFor: "Students",
      survey_expiry: "",
      survey_name: "",
      type_of_email: "",
      user_prize_draw: "",

      questions: {
        0: {
          0: {
            question: "Overall, how satisfied were you with today’s bootcamp?"
          },
          1: {
            questionnaire_type: "MCQ"
          },
          2: {
            0: { choice: "Very satisfied" },
            1: { choice: "Satisfied" },
            2: { choice: "Neutral" },
            3: { choice: "Dissatisfied" },
            4: { choice: "Very dissatisfied" }
          }
        },
        1: {
          0: {
            question: "How did the session meet your expectations?"
          },
          1: {
            questionnaire_type: "MCQ"
          },
          2: {
            0: { choice: "Greatly exceeded my expectations" },
            1: { choice: "Exceeded my expectations" },
            2: { choice: "Met my expectations" },
            3: { choice: "Less than I expected" },
            4: { choice: "Much less than I expected" }
          }
        },
        2: {
          0: {
            question: "How informative and knowledgeable was the facilitator?"
          },
          1: {
            questionnaire_type: "MCQ"
          },
          2: {
            0: { choice: "Extremely informative" },
            1: { choice: "Very informative" },
            2: { choice: "Somewhat informative" },
            3: { choice: "Not so informative" },
            4: { choice: "Not at all informative" }
          }
        },
        3: {
          0: {
            question: "How clearly did your facilitator explain the material?"
          },
          1: {
            questionnaire_type: "MCQ"
          },
          2: {
            0: { choice: "Extremely clearly" },
            1: { choice: "Very clearly" },
            2: { choice: "Somewhat clearly" },
            3: { choice: "Not so clearly" },
            4: { choice: "Not at all clearly" }
          }
        },
        4: {
          0: {
            question:
              "What was your favourite part of the session/ what did you find most valuable?"
          },
          1: {
            questionnaire_type: "Open Field"
          }
        },
        5: {
          0: {
            question:
              "What was your least favourite part of the session/ what did you find least valuable?"
          },
          1: {
            questionnaire_type: "Open Field"
          }
        },
        6: {
          0: {
            question:
              "How can we improve and make the session better for next time?"
          },
          1: {
            questionnaire_type: "Open Field"
          }
        }
      }
    };

    // console.log(prep_bootcamps);

    /*  firebase
      .database()
      .ref(
        `QR_Code/questionnaire_templates/Ryerson/TRSM BootCamp/Prep Bootcamp`
      )
      .update(prep_bootcamps);*/

    firebase
      .database()
      .ref(`QR_Code/questionnaire/`)

      .once(
        "value",
        function(snapshot) {
          var questionnaire = snapshot.val();
          this.setState({ questionnaire });
        }.bind(this)
      );
  }

  drawNowButtonPressed() {
    confirmAlert({
      title: "Do the draw!",
      message: "Are you sure?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            this.setState({
              draw_number: "",
              draw: false,
              countdown: true
            });

            const { data } = this.state;

            var department_database = data[0].department;
            var survey_name_database = data[0].survey_name;
            var company_or_institution_name_database =
              data[0].company_or_institution_name;
            var survey_id =
              data[0].Dynamic_Link_Details.survey_id_for_QR_creation_push_id;
            firebase
              .database()
              .ref(
                `QR_Code/unique/${company_or_institution_name_database}/${department_database}/${survey_name_database}/${survey_id}/current/`
              )

              .once(
                "value",
                function(snapshot) {
                  var uniquie = _.map(snapshot.val(), (val, uid) => {
                    return { ...val };
                  });

                  if (uniquie.length === 0) {
                    alert("No participants yet!");
                  } else {
                    var randomenumber;

                    var checking;
                    var found;

                    do {
                      randomenumber = Math.floor(
                        Math.random() * Math.floor(200)
                      );
                      checking = randomenumber;
                      found = uniquie.find(element => {
                        return checking === element.randomNumber;
                      });
                    } while (found === undefined);

                    this.setState({
                      draw_number: randomenumber,
                      draw: true
                    });

                    setTimeout(() => {
                      this.setState({
                        countdown: false
                      });
                    }, 6000); //10000);

                    if (found === undefined) {
                      console.log("good to go");
                    } else {
                      console.log("generate a new number");
                    }
                  }
                }.bind(this)
              );
          }
        },
        {
          label: "No",
          onClick: () => console.log("Click No")
        }
      ]
    });
  }

  coundown_and_draw_render() {
    //countdown,playing_phone

    const defaultOptions_countdown = {
      loop: true,
      autoplay: true,
      animationData: require("./countdown.json"),

      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    if (this.state.countdown === true) {
      return (
        <div className="text-center">
          <Lottie options={defaultOptions_countdown} height={300} width={300} />
        </div>
      );
    }

    return (
      <div className="text-center">
        <h1 style={{ color: "black", fontSize: 165, fontWeight: "bold" }}>
          {this.state.draw_number}
        </h1>
      </div>
    );
  }

  render_institution_or_organization() {
    const questionnaire_id = _.map(this.state.questionnaire, (val, uid) => {
      return uid;
    });

    const questionnaire_val = _.map(this.state.questionnaire, (val, uid) => {
      return val;
    });

    var options = [];
    if (questionnaire_id.length !== undefined) {
      for (var i = 0; i < questionnaire_id.length; i++) {
        var optionValue = questionnaire_id[i];
        options.push(<option value={i}>{optionValue}</option>);
      }
    }

    return (
      <div>
        <div className="form-group">
          <label style={{ paddingBottom: 2 }}>
            Select the Institution/Organization:
          </label>
          <select
            className="form-control"
            // value={this.state.selected_program}
            onChange={event => {
              event.preventDefault();

              //  console.log(questionnaire_val[parseInt(event.target.value)]);
              this.setState({
                selected_institution_or_organization:
                  questionnaire_val[parseInt(event.target.value)]
              });

              /* this.setState({
              selected_program: event.target.value
            });
            var user = firebase.auth().currentUser;

            firebase
              .database()
              .ref(`partner/users/${user.uid}/`)
              .on(
                "value",
                function(snapshot) {
                  var database_val = snapshot.val();

                  firebase
                    .database()
                    .ref(
                      `QR_Code/questionnaire/${database_val.company_or_institution_name}/${event.target.value}`
                    )
                    .on(
                      "value",
                      function(snap) {
                        var data_initial_pull = snap.val();
                        if (
                          data_initial_pull !==
                            undefined &&
                          data_initial_pull !== null
                        ) {
                          this.setState({
                            data_initial_pull
                          });
                        } else {
                          this.setState({
                            data_initial_pull
                          });
                        }
                      }.bind(this)
                    );
                }.bind(this)
              );*/
            }}
          >
            <option selected value="">
              Select....
            </option>
            {options}
          </select>
        </div>
      </div>
    );
  }

  render_program() {
    if (this.state.selected_institution_or_organization !== null) {
      const selected_institution_or_organization_id = _.map(
        this.state.selected_institution_or_organization,
        (val, uid) => {
          return uid;
        }
      );

      const selected_institution_or_organization_val = _.map(
        this.state.selected_institution_or_organization,
        (val, uid) => {
          return val;
        }
      );

      var options = [];
      if (selected_institution_or_organization_id.length !== undefined) {
        for (
          var i = 0;
          i < selected_institution_or_organization_id.length;
          i++
        ) {
          var optionValue = selected_institution_or_organization_id[i];
          options.push(<option value={i}>{optionValue}</option>);
        }
      }

      return (
        <div>
          <div className="form-group">
            <label style={{ paddingBottom: 2 }}>Select the Program:</label>
            <select
              className="form-control"
              // value={this.state.selected_program}
              onChange={event => {
                event.preventDefault();

                /* console.log(
                  selected_institution_or_organization_val[
                    parseInt(event.target.value)
                  ]
                );*/

                this.setState({
                  selected_program:
                    selected_institution_or_organization_val[
                      parseInt(event.target.value)
                    ]
                });
              }}
            >
              <option selected value="">
                Select....
              </option>
              {options}
            </select>
          </div>
        </div>
      );
    }
    return null;
  }

  render_workshop_or_stores() {
    if (this.state.selected_program !== null) {
      const selected_program_id = _.map(
        this.state.selected_program,
        (val, uid) => {
          return uid;
        }
      );

      const selected_program_val = _.map(
        this.state.selected_program,
        (val, uid) => {
          return val;
        }
      );

      var options = [];
      if (selected_program_id.length !== undefined) {
        for (var i = 0; i < selected_program_id.length; i++) {
          var optionValue = selected_program_id[i];
          options.push(<option value={i}>{optionValue}</option>);
        }
      }

      return (
        <div>
          <div className="form-group">
            <label style={{ paddingBottom: 2 }}>Select Event/Workshop:</label>
            <select
              className="form-control"
              // value={this.state.selected_program}
              onChange={event => {
                event.preventDefault();

                //   console.log(selected_program_val[parseInt(event.target.value)]);

                const data = _.map(
                  selected_program_val[parseInt(event.target.value)],
                  (val, uid) => {
                    return val;
                  }
                );

                const {
                  company_or_institution_name,
                  department,
                  survey_name
                } = data[0];

                firebase
                  .database()
                  .ref(
                    `QR_Code/unique/${company_or_institution_name}/${department}/${survey_name}`
                  )

                  .once(
                    "value",
                    function(snapshot) {
                      const id = _.map(snapshot.val(), (val, uid) => {
                        return uid;
                      });

                      const value = _.map(snapshot.val(), (val, uid) => {
                        return val;
                      });

                      if (value[0].current !== undefined) {
                        firebase
                          .database()
                          .ref(
                            `QR_Code/unique/${company_or_institution_name}/${department}/${survey_name}/${
                              id[0]
                            }/old`
                          )
                          .update(value[0].current)
                          .then(() => {
                            firebase
                              .database()
                              .ref(
                                `QR_Code/unique/${company_or_institution_name}/${department}/${survey_name}/${
                                  id[0]
                                }/current`
                              )
                              .remove();
                          })
                          .catch(() => {
                            alert("Something Went Wrong! Please try again.");
                          });
                      }
                    }.bind(this)
                  );

                this.setState({
                  data,
                  qr_screen: true
                });

                /*this.setState({
                  data:
                    selected_institution_or_organization_val[
                      parseInt(event.target.value)
                    ]
                });*/
              }}
            >
              <option selected value="">
                Select....
              </option>
              {options}
            </select>
          </div>
        </div>
      );
    }
    return null;
  }
  showDrawNowButton({ questionnaire_data }) {
    if (
      questionnaire_data.draw === false ||
      questionnaire_data.in_person_draw === false
    ) {
      return null;
    }
    return (
      <a
        onClick={() => {
          this.drawNowButtonPressed();
        }}
        className="btn_1 btn-outline_1 btn-xl_1 js-scroll-trigger"
      >
        Draw now!
      </a>
    );
  }

  render() {
    const data_initial_pull_val = _.map(
      this.state.data_initial_pull,
      (val, uid) => {
        return val;
      }
    );

    const data_initial_pull = _.map(
      this.state.data_initial_pull,
      (val, uid) => {
        return uid;
      }
    );
    var options = [];
    if (data_initial_pull.length !== undefined) {
      for (var i = 0; i < data_initial_pull.length; i++) {
        var optionValue = data_initial_pull[i];
        options.push(<option value={i}>{optionValue}</option>);
      }
    }
    const { data } = this.state;

    const all_programs = [
      "something",
      "number2"
    ]; /* _.map(user_data.all_programs, (val, uid) => {
        return val;
      });*/

    var list_of_programs = [];
    for (var p = 0; p < all_programs.length; p++) {
      list_of_programs.push(
        <option value={all_programs[p].access}>{all_programs[p].access}</option>
      );
    } //else {

    /*if (this.state.selected_program === "") {
      return (
        <header className="masthead_login">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <div className="text-center">
                    <img
                      src={logo_for_qr}
                      className="qr-screen-logo"
                      alt="logo"
                    />

                    <div
                      className="site-subscribe"
                      style={{
                        paddingTop: 10
                      }}
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 mx-auto text-center">
                            <div>
                              <div className="form-group">
                                <label style={{ paddingBottom: 2 }}>
                                  Select the Program:
                                </label>
                                <select
                                  className="form-control"
                                  value={this.state.selected_program}
                                  onChange={event => {
                                    event.preventDefault();

                                    this.setState({
                                      selected_program: event.target.value
                                    });
                                    var user = firebase.auth().currentUser;

                                    firebase
                                      .database()
                                      .ref(`partner/users/${user.uid}/`)
                                      .on(
                                        "value",
                                        function(snapshot) {
                                          var database_val = snapshot.val();

                                          firebase
                                            .database()
                                            .ref(
                                              `QR_Code/questionnaire/${database_val.company_or_institution_name}/${event.target.value}`
                                            )
                                            .on(
                                              "value",
                                              function(snap) {
                                                var data_initial_pull = snap.val();
                                                if (
                                                  data_initial_pull !==
                                                    undefined &&
                                                  data_initial_pull !== null
                                                ) {
                                                  this.setState({
                                                    data_initial_pull
                                                  });
                                                } else {
                                                  this.setState({
                                                    data_initial_pull
                                                  });
                                                }
                                              }.bind(this)
                                            );
                                        }.bind(this)
                                      );
                                  }}
                                >
                                  <option selected value="">
                                    Select....
                                  </option>
                                  {list_of_programs}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      );
    } */ if (
      this.state.qr_screen === true
    ) {
      var link;
      if (
        this.state.data.length !== 0 &&
        this.state.data.length !== undefined
      ) {
        link = this.state.data[this.state.data.length - 1].Dynamic_Link_Details
          .QR_Code_Link;
      }

      return (
        <header className="masthead_login">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <div className="text-center">
                    <h1 //className="mb-5"
                    >
                      YOUR FEEDBACK
                    </h1>
                    <h2 //className="mb-5"
                    >
                      {data[0].survey_name}
                    </h2>
                    <div className="site-subscribe">
                      <div className="container">
                        <div className="row">
                          <div className="col-lg-12 mx-auto text-center">
                            {/** */}

                            <div className="device-container">
                              <div className="device-mockup iphone6_plus portrait white">
                                <div className="device">
                                  <div className="screen">
                                    {this.state.draw ? (
                                      <div //className="countdown_and_draw_style"
                                        className="shadow-lg p-3 mb-5 bg-white rounded"
                                      >
                                        {this.coundown_and_draw_render()}
                                      </div>
                                    ) : (
                                      <div
                                        className="text-center"
                                        style={{
                                          padding: 15,

                                          backgroundColor: "white"
                                        }}
                                      >
                                        <QRCode
                                          bgColor="#FFFFFF"
                                          fgColor="#000000"
                                          level="Q"
                                          className="size_qr"
                                          // style={{ width: 500 }}
                                          cellClassPrefix="my-qr-code"
                                          value={link}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h1 //className="mb-5"
                      >
                        survey.getluup.com
                      </h1>
                      {this.showDrawNowButton({
                        questionnaire_data: data[0]
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      );
      /* return (
        <header className="masthead">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-7 my-auto">
                <div className="header-content mx-auto">
                  <div style={{ paddingBottom: 5 }}>
                    <img
                      src={logo_for_qr}
                      className="qr-screen-logo"
                      alt="logo"
                    />
                  </div>

                  <h1 className="mb-5">YOUR FEEDBACK MATTERS</h1>
                  <h2 className="mb-5">{data[0].survey_name}</h2>

                  {this.showDrawNowButton({ questionnaire_data: data[0] })}
                </div>
              </div>
              <div className="col-lg-5 my-auto">
                <div className="device-container">
                  <div className="device-mockup iphone6_plus portrait white">
                    <div className="device">
                      <div className="screen">
                        {this.state.draw ? (
                          <div className="shadow-lg p-3 mb-5 bg-white rounded">
                            {this.coundown_and_draw_render()}
                          </div>
                        ) : (
                          <div
                            className="text-center"
                            style={{
                              padding: 15,

                              backgroundColor: "white"
                            }}
                          >
                            <QRCode
                              bgColor="#FFFFFF"
                              fgColor="#000000"
                              level="Q"
                              className="size_qr"
                              // style={{ width: 500 }}
                              cellClassPrefix="my-qr-code"
                              value={link}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      );*/
    }

    return (
      <header className="masthead_login">
        <div className="container h-100">
          <div className="row h-100">
            <div className="col-lg-12 my-auto">
              <div className="header-content mx-auto">
                <div className="text-center">
                  <img
                    src={logo_for_qr}
                    className="qr-screen-logo"
                    alt="logo"
                  />

                  <div
                    className="site-subscribe"
                    style={{
                      paddingTop: 10
                    }}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-12 mx-auto text-center">
                          {this.render_institution_or_organization()}
                        </div>
                        <div className="col-lg-12 mx-auto text-center">
                          {this.render_program()}
                        </div>
                        <div className="col-lg-12 mx-auto text-center">
                          {this.render_workshop_or_stores()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
    // }
  }
}
export default AddNotification;
