import _ from "lodash";
import React, { Component } from "react";
import Footer from "./Footer";
import * as firebase from "firebase";
import moment from "moment";
import { Button, Modal, Form } from "react-bootstrap";
import "../vendor/sb-admin.css";
import logo_for_login from "./logo_for_login.svg";
import Login from "./Login";

class SignUp extends Component {
  //    this.handle_submit = this.handle_submit.bind(this);
  constructor(props, context) {
    super(props, context);
    this.handle_submit = this.handle_submit.bind(this);

    this.state = {
      email: "",
      password: "",
      user: {},
      back_to_login_page: false,
      name: "",
      retail_industry: ""
    };
  }

  handle_submit = event => {
    event.preventDefault();

    const { email, password, name } = this.state;

    console.log(email, password, name);
    /*  firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...

        alert(errorMessage);
      });*/
  };

  render() {
    const { back_to_login_page, retail_industry } = this.state;

    if (back_to_login_page === true) {
      return <Login />;
    }
    if (retail_industry === "") {
      return (
        <React.Fragment>
          <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div id="content-wrapper">
                      <div>
                        <div className="container">
                          <div className="card card-login mx-auto mt-5">
                            <div className="text-center">
                              <img
                                src={logo_for_login}
                                className="qr-screen-logo"
                                alt="logo"
                              />
                            </div>
                            <div className="card-header text-center">
                              <p style={{ color: "black", paddingTop: 5 }}>
                                Sign Up
                              </p>
                            </div>
                            <div className="card-body">
                              <form
                                role="form"
                                onSubmit={event => {
                                  event.preventDefault();

                                  console.log();
                                }}
                              >
                                <div className="form-group text-center">
                                  <div className="form-label-group">
                                    <div className="form-group">
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Are you from a Retail Industry?
                                          <div class="row text-center">
                                            <button
                                              //    type="submit"
                                              className="btn btn-secondary btn-block"
                                              // style={{ width: 175 }}
                                              onClick={() => {
                                                this.setState({
                                                  retail_industry: true
                                                });
                                              }}
                                            >
                                              YES
                                            </button>
                                            <button
                                              //    type="submit"
                                              className="btn btn-secondary btn-block"
                                              // style={{ width: 175 }}
                                              onClick={() => {
                                                this.setState({
                                                  retail_industry: false
                                                });
                                              }}
                                            >
                                              NO
                                            </button>
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </form>
                              <div style={{ paddingTop: 5 }}>
                                <button
                                  //    type="submit"
                                  className="btn btn-light btn-block"
                                  onClick={() => {
                                    this.setState({
                                      back_to_login_page: true
                                    });
                                  }}
                                >
                                  Back to Login
                                </button>
                              </div>
                            </div>
                            <div
                              style={{ paddingTop: 2.5 }}
                              className="text-center"
                            >
                              <p style={{ color: "black", fontSize: 13 }}>
                                Luup Partner Portal <br />
                                ©Copyright 2019 Luup
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </React.Fragment>
      );
    } else if (retail_industry === true) {
      return (
        <React.Fragment>
          <header className="masthead_login">
            <div className="container h-100">
              <div className="row h-100">
                <div className="col-lg-12 my-auto">
                  <div className="header-content mx-auto">
                    <div id="content-wrapper">
                      <div>
                        <div className="container">
                          <div className="card card-login mx-auto mt-10">
                            <div className="text-center">
                              <img
                                src={logo_for_login}
                                className="qr-screen-logo"
                                alt="logo"
                              />
                            </div>
                            <div className="card-header text-center">
                              <p style={{ color: "black", paddingTop: 5 }}>
                                Contact Us
                              </p>
                            </div>
                            <div className="card-body">
                              <form role="form" onSubmit={this.handle_submit}>
                                <div className="form-group">
                                  <div className="form-label-group">
                                    <div className="form-group">
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Thank you for your interest!
                                          Onboarding a retail partner requires a
                                          bit more information. Please provide
                                          us with the info below and
                                          representative will get in touch with
                                          you as soon as possible!
                                        </label>
                                      </div>

                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Full Name
                                        </label>
                                        <input
                                          className="form-control"
                                          // type="email"
                                          placeholder="Full Name"
                                          required
                                          onChange={event => {
                                            event.preventDefault();
                                            this.setState({
                                              name: event.target.value
                                            });
                                          }}
                                        />
                                      </div>

                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Email address
                                        </label>
                                        <input
                                          className="form-control"
                                          type="email"
                                          placeholder="Email address"
                                          required
                                          onChange={event => {
                                            event.preventDefault();
                                            this.setState({
                                              email: event.target.value
                                            });
                                          }}
                                        />
                                      </div>
                                      <div className="form-group">
                                        <label style={{ color: "black" }}>
                                          Company Name
                                        </label>
                                        <input
                                          required
                                          className="form-control"
                                          type="password"
                                          placeholder="Password"
                                          onChange={event => {
                                            event.preventDefault();
                                            this.setState({
                                              password: event.target.value
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="submit"
                                  className="btn btn-primary btn-block"
                                >
                                  Sign Up
                                </button>
                              </form>

                              <form
                                role="form"
                                onSubmit={event => {
                                  event.preventDefault();

                                  console.log();
                                }}
                              />
                              <div style={{ paddingTop: 5 }}>
                                <button
                                  //    type="submit"
                                  className="btn btn-light btn-block"
                                  onClick={() => {
                                    this.setState({
                                      back_to_login_page: true
                                    });
                                  }}
                                >
                                  Back to Login
                                </button>
                              </div>
                            </div>
                            <div
                              style={{ paddingTop: 2.5 }}
                              className="text-center"
                            >
                              <p style={{ color: "black", fontSize: 13 }}>
                                Luup Partner Portal <br />
                                ©Copyright 2019 Luup
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <header className="masthead_login">
          <div className="container h-100">
            <div className="row h-100">
              <div className="col-lg-12 my-auto">
                <div className="header-content mx-auto">
                  <div id="content-wrapper">
                    <div>
                      <div className="container">
                        <div className="card card-login mx-auto mt-5">
                          <div className="text-center">
                            <img
                              src={logo_for_login}
                              className="qr-screen-logo"
                              alt="logo"
                            />
                          </div>
                          <div className="card-header text-center">
                            <p style={{ color: "black", paddingTop: 5 }}>
                              Sign Up
                            </p>
                          </div>
                          <div className="card-body">
                            <form role="form" onSubmit={this.handle_submit}>
                              <div className="form-group">
                                <div className="form-label-group">
                                  <div className="form-group">
                                    <div className="form-group">
                                      <label style={{ color: "black" }}>
                                        Full Name
                                      </label>
                                      <input
                                        className="form-control"
                                        // type="email"
                                        placeholder="Full Name"
                                        required
                                        onChange={event => {
                                          event.preventDefault();
                                          this.setState({
                                            name: event.target.value
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label style={{ color: "black" }}>
                                        Email address
                                      </label>
                                      <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Email address"
                                        required
                                        onChange={event => {
                                          event.preventDefault();
                                          this.setState({
                                            email: event.target.value
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label style={{ color: "black" }}>
                                        Password
                                      </label>
                                      <input
                                        required
                                        className="form-control"
                                        type="password"
                                        placeholder="Password"
                                        onChange={event => {
                                          event.preventDefault();
                                          this.setState({
                                            password: event.target.value
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <button
                                type="submit"
                                className="btn btn-primary btn-block"
                              >
                                Sign Up
                              </button>
                            </form>
                            <div style={{ paddingTop: 5 }}>
                              <button
                                //    type="submit"
                                className="btn btn-light btn-block"
                                onClick={() => {
                                  this.setState({
                                    back_to_login_page: true
                                  });
                                }}
                              >
                                Back to Login
                              </button>
                            </div>
                          </div>
                          <div
                            style={{ paddingTop: 2.5 }}
                            className="text-center"
                          >
                            <p style={{ color: "black", fontSize: 13 }}>
                              Luup Partner Portal <br />
                              ©Copyright 2019 Luup
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </React.Fragment>
    );
  }
}

export default SignUp;
