import _ from "lodash";
import React, { Component } from "react";
import Footer from ".././Footer";
import * as firebase from "firebase";
import moment from "moment";
import { Button, Modal, Form } from "react-bootstrap";
import moment_time_zone from "moment-timezone";

class AddUsers extends Component {
  constructor(props, context) {
    super(props, context);
    //this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.handleClose_accepted = this.handleClose.bind(this);

    this.state = {
      data: {},
      show: false,
      rejectedReason: "",
      i: 0,

      show_accepted: false,
      link: "",
    };
  }

  componentDidMount() {
    firebase
      .database()
      .ref(`Admin/Perks/Redeeming_GiftCard_Request`)
      .on(
        "value",
        function(snapshot) {
          var data = snapshot.val();
          this.setState({ data });
        }.bind(this),
        function(error) {}
      );
  }

  confirmed() {
    const { i, link } = this.state;
    const data = _.map(this.state.data, (val, uid) => {
      return { ...val, uid };
    });
    var uid = data[i].uid;
    var date_local = moment_time_zone()
      .tz("America/Toronto")
      .format("MM/DD/YYYY");
    var time_local = " 12:00";
    var Date_Time = date_local + time_local;
    var response_date = moment_time_zone
      .tz(Date_Time, "MM/DD/YYYY H:m", "America/Toronto")
      .valueOf();
    var status = "Completed";
    if (link !== "") {
      firebase
        .database()
        .ref(`Admin/Perks/Redeeming_GiftCard_Request/${uid}`)
        .update({ status, response_date, link })
        .then(() => {
          this.setState({ show_accepted: false, link: "" });
          alert("Success");
        })
        .catch(() => {
          this.setState({ show_accepted: false, link: "" });
          alert("Error");
        });
    } else {
      alert("Please enter the link before proceeding!");
    }
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleShow(i) {
    this.setState({ i: i });
    this.setState({ show: true });
  }

  handleClose_accepted() {
    console.log("Pressed");
    this.setState({ show_accepted: false });
  }

  handleShow_accepted(i) {
    this.setState({ i: i });
    this.setState({ show_accepted: true });
  }

  render() {
    const data = _.map(this.state.data, (val, uid) => {
      return { ...val, uid };
    });

    let items = [];
    if (data.length !== 0) {
      // console.log(data[0].uid);

      for (let i = 0; i < data.length; i++) {
        var user_name = data[i].userDetails.name;
        var user_email = data[i].userDetails.email;
        var user_phone_number = data[i].userDetails.phone_number;
        // var uid = data[i].uid;
        items.push(
          <tr key={i}>
            <th scope="row">{i + 1}</th>
            <td>{user_name}</td>
            <td>{user_email}</td>
            <td>{user_phone_number}</td>
            <td>{data[i].name}</td>

            <td>$ {data[i].luup_points_to_redeem / 1000}</td>
            <td>{moment(data[i].date).format("LL")}</td>

            <td>
              <>
                <Button
                  variant="btn btn-success" //onClick={this.handleShow}
                  onClick={() => this.handleShow_accepted(i)}
                >
                  Send &amp; Confirm
                </Button>

                <Modal
                  show={this.state.show_accepted}
                  onHide={() => {
                    console.log("Pressed");
                    this.setState({ show_accepted: false });
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Gift Card Website</Modal.Title>
                  </Modal.Header>
                  <div className="form-group" style={{ padding: 20 }}>
                    <label>Website:</label>

                    <input
                      className="form-control"
                      //id="disabledInput"
                      type="text"
                      placeholder="Website"
                      //  disabled
                      // value={this.state.file_name}
                      onChange={(event) => {
                        event.preventDefault();
                        this.setState({
                          link: event.target.value,
                        });
                      }}
                    />
                  </div>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        console.log("Pressed");
                        this.setState({ show_accepted: false });
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      variant="btn btn-success"
                      onClick={() => this.confirmed()}
                    >
                      Send &amp; Confirm
                    </Button>
                  </Modal.Footer>
                </Modal>
              </>
            </td>
          </tr>
        );
      }
    }

    return (
      <React.Fragment>
        <div id="content-wrapper">
          <div class="container">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">Giftcard Request</li>
            </ol>
          </div>
          <div class="container">
            {data.length !== 0 ? (
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone Number</th>
                    <th scope="col">Giftcard</th>
                    <th scope="col">Price</th>
                    <th scope="col">Date</th>

                    <th scope="col">Send &amp; Confirmed</th>
                  </tr>
                </thead>
                <tbody>{items}</tbody>
              </table>
            ) : (
              <div>
                <p style={{ fontWeight: "bold", textAlign: "center" }}>
                  No Requests!
                </p>
              </div>
            )}
          </div>

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default AddUsers;
