import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";
import "../vendor/new-age.min.css";
import "../vendor/bootstrap.css";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import QR_Institute_Project_Manager from "././QR/QR_Institute_Project_Manager";
import QR_Institute_Director from "././QR/QR_Institute_Director";
import QR_Retail_Project_Manager from "././QR/QR_Retail_Project_Manager";
import QR_Retail_Director from "././QR/QR_Retail_Director";
import QR_Facilitator_Institute from "././QR/QR_Facilitator_Institute";
import QR_Facilitator_Retail from "././QR/QR_Facilitator_Retail";
import QR_Admin from "././QR/QR_Admin";

class AddNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_data: {}
    };
  }

  componentWillMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var user_data = snapshot.val();

          if (user_data === null) {
            firebase
              .database()
              .ref(`Admin/admin_user/${user.uid}`)
              .on(
                "value",
                function(snapData) {
                  if (snapData.val() === null) {
                    this.logout();
                  } else {
                    user_data = snapData.val();
                    this.setState({ user_data });
                  }
                }.bind(this)
              );
          } else {
            this.setState({ user_data });
          }
        }.bind(this)
      );
  }

  render() {
    const { user_data } = this.state;

    if (user_data !== null) {
      if (user_data.user_type === "institute") {
        if (user_data.role === "Program Manager") {
          return <QR_Institute_Project_Manager />;
        } else if (user_data.role === "Director") {
          return <QR_Institute_Director />;
        } else {
          return <QR_Facilitator_Institute />;
        }
      } else if (user_data.user_type === "retail") {
        if (user_data.role === "Program Manager") {
          return <QR_Retail_Project_Manager />;
        } else if (user_data.role === "Director") {
          return <QR_Retail_Director />;
        } else {
          return <QR_Facilitator_Retail />;
        }
      } else if (user_data.userStatus.role === "admin") {
        return <QR_Admin />;
      }
    }
    return <div></div>;
  }
}
export default AddNotification;
