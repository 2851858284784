import _ from "lodash";
import React, { Component } from "react";
import Footer from ".././Footer";
import * as firebase from "firebase";
import { Button } from "react-bootstrap";

class Help_Institute extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
      partial_url: "",
      list_of_access_items: {},
      user_data: {},
      selected_program: null,
      templates: null,
      template_data_selected: null,
      survey_name: ""
    };
  }

  componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var user_data = snapshot.val();
          this.setState({ user_data });
        }.bind(this)
      );
  }

  render_question_template() {
    if (this.state.selected_program !== null) {
      const templates_names = _.map(this.state.templates, (val, uid) => {
        return uid;
      });

      const templates_date = _.map(this.state.templates, (val, uid) => {
        return val;
      });

      var list_of_templates = [];
      for (var p = 0; p < templates_names.length; p++) {
        list_of_templates.push(<option value={p}>{templates_names[p]}</option>);
      }

      if (templates_names.length === 0) {
        return (
          <div className="form-group">
            <p>No templates available at the moment for this program.</p>
          </div>
        );
      }
      return (
        <div className="form-group">
          <label style={{ paddingBottom: 2 }}>Select the template:</label>
          <select
            className="form-control"
            onChange={event => {
              event.preventDefault();

              if (event.target.value === "") {
                this.setState({ template_data_selected: null });
              } else {
                this.setState({
                  template_data_selected:
                    templates_date[parseInt(event.target.value)]
                });
              }
            }}
          >
            <option selected value="">
              Select....
            </option>
            {list_of_templates}
          </select>
        </div>
      );
    }
    return null;
  }

  survey_question_name() {
    //
    const { template_data_selected, user_data } = this.state;
    if (template_data_selected === null) {
      return null;
    }
    return (
      <div>
        <div className="form-group">
          <label style={{ paddingBottom: 2 }}>Enter Survey Name:</label>
          <input
            className="form-control"
            onChange={event => {
              event.preventDefault();
              this.setState({
                survey_name: event.target.value
              });
            }}
          />
        </div>
        <Button
          onClick={() => {
            var company_or_institution_name =
              user_data.company_or_institution_name;
            var department = this.state.selected_program;
            var survey_name = this.state.survey_name;

            var regex = /[^\w\s]/g;
            if (survey_name === "") {
              alert("Please Enter the name before continuing");
            } else {
              if (survey_name.search(regex) !== -1) {
                alert("Please avoid using Special Characters.");
              } else {
                firebase
                  .database()
                  .ref(
                    `QR_Code/questionnaire/${company_or_institution_name}/${department}/${survey_name}`
                  )
                  .once(
                    "value",
                    function(snapshot) {
                      if (snapshot.val() === null) {
                        var surveyID = firebase
                          .database()
                          .ref(
                            `QR_Code/questionnaire/${company_or_institution_name}/${department}/${survey_name}`
                          )
                          .push().key;

                        firebase
                          .database()
                          .ref(
                            `QR_Code/questionnaire/${company_or_institution_name}/${department}/${survey_name}/${surveyID}`
                          )
                          .update({
                            draw: template_data_selected.draw,
                            email_required:
                              template_data_selected.email_required,
                            general_prize_draw:
                              template_data_selected.general_prize_draw,
                            in_person_draw:
                              template_data_selected.in_person_draw,
                            questions: template_data_selected.questions,
                            subText: template_data_selected.subText,
                            surveyFor: template_data_selected.surveyFor,
                            survey_expiry: template_data_selected.survey_expiry,
                            type_of_email: template_data_selected.type_of_email,
                            user_prize_draw:
                              template_data_selected.user_prize_draw,
                            company_or_institution_name,
                            department,
                            survey_name,
                            unique_entry_required:
                              template_data_selected.unique_entry_required
                          })
                          .then(() => {
                            var deep_link_url =
                              "https://survey.getluup.com/?survey=" + surveyID;
                            var dynamic_link_name = "surveyID: " + surveyID;
                            var data = {
                              draw: template_data_selected.draw,
                              email_required:
                                template_data_selected.email_required,
                              general_prize_draw:
                                template_data_selected.general_prize_draw,
                              in_person_draw:
                                template_data_selected.in_person_draw,
                              questions: template_data_selected.questions,
                              subText: template_data_selected.subText,
                              surveyFor: template_data_selected.surveyFor,
                              survey_expiry:
                                template_data_selected.survey_expiry,
                              type_of_email:
                                template_data_selected.type_of_email,
                              user_prize_draw:
                                template_data_selected.user_prize_draw,
                              company_or_institution_name,
                              department,
                              survey_name,
                              unique_entry_required:
                                template_data_selected.unique_entry_required
                            };

                            firebase
                              .database()
                              .ref(
                                `QR_Code/survey_id_for_QR_creation/${surveyID}`
                              )
                              .update({
                                surveyID,
                                data,
                                deep_link_url,
                                dynamic_link_name //, link_part
                              })
                              .then(() => {
                                alert("Success");
                              })
                              .catch(error => {
                                console.log(error);
                                alert("Error");
                              });

                            // alert("Success");
                          })
                          .catch(error => {
                            console.log(error);

                            alert("Error");
                          });

                        /*  firebase
                          .database()
                          .ref(
                            `QR_Code/questionnaire/${company_or_institution_name}/${department}/${survey_name}`
                          )
                          .push({
                            draw: template_data_selected.draw,
                            email_required:
                              template_data_selected.email_required,
                            general_prize_draw:
                              template_data_selected.general_prize_draw,
                            in_person_draw:
                              template_data_selected.in_person_draw,
                            questions: template_data_selected.questions,
                            subText: template_data_selected.subText,
                            surveyFor: template_data_selected.surveyFor,
                            survey_expiry: template_data_selected.survey_expiry,
                            type_of_email: template_data_selected.type_of_email,
                            user_prize_draw:
                              template_data_selected.user_prize_draw,
                            company_or_institution_name,
                            department,
                            survey_name
                          })
                          .then(() => {
                            alert("Survey and QR created successfully!");
                          })
                          .catch(() => {
                            alert(
                              "There was an error proccessing your request. Please try again!"
                            );
                          });*/
                      } else {
                        alert("You already have a QR and Survey built!");
                      }
                    }.bind(this)
                  );
              }
            }
          }}
          color="primary"
        >
          Add Survey and Generate QR
        </Button>
      </div>
    );
  }

  render() {
    const { user_data } = this.state;
    const { data } = this.state;

    if (user_data.all_programs !== undefined) {
      const all_programs = _.map(user_data.all_programs, (val, uid) => {
        return val;
      });

      var list_of_programs = [];
      for (var p = 0; p < all_programs.length; p++) {
        list_of_programs.push(
          <option value={all_programs[p].access}>
            {all_programs[p].access}
          </option>
        );
      }

      return (
        <React.Fragment>
          <div id="content-wrapper">
            <div class="container">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">Create Survey</li>
              </ol>
            </div>

            <div class="container">
              <div className="form-group">
                <label style={{ paddingBottom: 2 }}>Select the Program:</label>
                <select
                  className="form-control"
                  // value={this.state.selected_program}
                  onChange={event => {
                    event.preventDefault();

                    var user = firebase.auth().currentUser;
                    if (event.target.value === "") {
                      this.setState({
                        selected_program: null
                      });
                    } else {
                      firebase
                        .database()
                        .ref(
                          `QR_Code/questionnaire_templates/${user_data.company_or_institution_name}/${event.target.value}`
                        )
                        .once(
                          "value",
                          function(snapshot) {
                            var templates = snapshot.val();
                            if (templates !== undefined) {
                              this.setState({ templates });
                            }
                          }.bind(this)
                        );

                      this.setState({
                        selected_program: event.target.value
                      });
                    }
                  }}
                >
                  <option selected value="">
                    Select....
                  </option>
                  {list_of_programs}
                </select>
              </div>
              {this.render_question_template()}
              {this.survey_question_name()}
            </div>

            <Footer />
          </div>
        </React.Fragment>
      );
    }
    return null;
  }
}

export default Help_Institute;
