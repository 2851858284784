import _ from "lodash";
import React, { Component } from "react";
import logo from ".././logo.svg";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Survey_QR from ".././Survey_QR";
import Dashboard from ".././Dashboard";
import "../../vendor/sb-admin.css";
import Access_Page from ".././Access_Page";
import * as firebase from "firebase";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Help from ".././Help";
import Change_Password from ".././Change_Password";

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: "",
    };
  }

  static defaultProps = {
    displayTitle: true,
    displayLegend: true,
    legendPosition: "right",
    location: "City",
  };

  componentWillMount() {
    var user = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var userInfo = snapshot.val();
          if (userInfo === null) {
            this.logout();
          }
          /* if (
            userInfo.user_type !== "institute" ||
            userInfo.user_type !== "retail"
          ) {
            this.logout();
          }*/
          this.setState({ userInfo });
        }.bind(this)
      );
  }
  logout() {
    firebase
      .auth()
      .signOut()
      .then(
        function() {
          // Sign-out successful.
          console.log("success");
        },
        function(error) {
          alert(error);
        }
      );
  }

  render() {
    const { userInfo } = this.state;
    //  return null;
    if (userInfo.user_type === "institute") {
      if (
        userInfo.dashboard_access.access === true &&
        userInfo.qr_access_granted.access === true
      ) {
        return (
          <BrowserRouter>
            <div>
              <Navbar
                collapseOnSelect
                expand="lg"
                style={{ backgroundColor: "#311c92" }}
                variant="dark"
              >
                <Navbar.Brand
                // href="#home"
                >
                  <img src={logo} className="App-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto"></Nav>
                  <Nav>
                    <Nav.Link style={{ color: "white" }} href="" eventKey={1}>
                      <Link to={"/"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Dashboard
                        </span>
                      </Link>
                    </Nav.Link>

                    <Nav.Link style={{ color: "white" }} eventKey={2}>
                      <Link style={{ color: "white" }} to={"/QR"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Survey QR
                        </span>
                      </Link>
                    </Nav.Link>
                    <Nav.Link style={{ color: "white" }} eventKey={3}>
                      <Link style={{ color: "white" }} to={"/Help"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Help
                        </span>
                      </Link>
                    </Nav.Link>

                    <Nav.Link style={{ color: "white" }} eventKey={3}>
                      <Link style={{ color: "white" }} to={"/ChangePassword"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Change Password
                        </span>
                      </Link>
                    </Nav.Link>

                    <Nav.Link
                      style={{ color: "white" }}
                      eventKey={3}
                      onClick={this.logout.bind(this)}
                    >
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Logout
                      </span>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              {/*<nav
                className="navbar navbar-expand static-top"
                style={{ background: "#311b92" }}
              >
                <img src={logo} className="App-logo" />

                <div class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                  <div class="input-group">
                    <div class="input-group-append">
                      <a
                        style={{
                          background: "transparent",
                          border: "transparent",
                          cursor: "pointer"
                        }}
                        // onClick={this.logout.bind(this)}
                      >
                        <Link style={{ color: "white" }} to={"/"}>
                          <span style={{ paddingLeft: 5, color: "white" }}>
                            Dashboard
                          </span>
                        </Link>
                      </a>

                      <a
                        style={{
                          background: "transparent",
                          border: "transparent",
                          cursor: "pointer",
                          paddingRight: 10,
                          paddingLeft: 10
                        }}
                        // onClick={this.logout.bind(this)}
                      >
                        <Link style={{ color: "white" }} to={"/QR"}>
                          <span style={{ paddingLeft: 5, color: "white" }}>
                            Survey QR
                          </span>
                        </Link>
                      </a>
                      <a
                        style={{
                          background: "transparent",
                          border: "transparent",
                          cursor: "pointer"
                        }}
                        onClick={this.logout.bind(this)}
                      >
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Logout
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </nav>*/}

              <div id="wrapper">
                <Route
                  path="/"
                  exact
                  strict
                  render={() => {
                    return <Dashboard />;
                  }}
                />
                <Route
                  path="/QR"
                  exact
                  strict
                  render={() => {
                    return <Survey_QR />;
                  }}
                />
                <Route
                  path="/Help"
                  exact
                  strict
                  render={() => {
                    return <Help />;
                  }}
                />
                <Route
                  path="/ChangePassword"
                  exact
                  strict
                  render={() => {
                    return <Change_Password />;
                  }}
                />
              </div>
            </div>
          </BrowserRouter>
        );
      } else if (
        userInfo.dashboard_access.access === false &&
        userInfo.qr_access_granted.access === true
      ) {
        return (
          <BrowserRouter>
            <div>
              <Navbar
                collapseOnSelect
                expand="lg"
                style={{ backgroundColor: "#311c92" }}
                variant="dark"
              >
                <Navbar.Brand href="#home">
                  <img src={logo} className="App-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto"></Nav>
                  <Nav>
                    <Nav.Link style={{ color: "white" }} eventKey={1}>
                      <Link style={{ color: "white" }} to={"/"}>
                        <span style={{ color: "white" }}>Survey QR</span>
                      </Link>
                    </Nav.Link>
                    {/*<Nav.Link style={{ color: "white" }} eventKey={3}>
                      <Link style={{ color: "white" }} to={"/Help"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Help
                        </span>
                      </Link>
                    </Nav.Link>*/}
                    <Nav.Link style={{ color: "white" }} eventKey={3}>
                      <Link style={{ color: "white" }} to={"/ChangePassword"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Change Password
                        </span>
                      </Link>
                    </Nav.Link>

                    <Nav.Link
                      style={{ color: "white" }}
                      eventKey={2}
                      onClick={this.logout.bind(this)}
                    >
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Logout
                      </span>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              {/* <nav
                className="navbar navbar-expand static-top"
                style={{ background: "#311b92" }}
              >
                <img src={logo} className="App-logo" />

                <div class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                  <div class="input-group">
                    <div class="input-group-append">
                      <a
                        style={{
                          background: "transparent",
                          border: "transparent",
                          cursor: "pointer"
                        }}
                        // onClick={this.logout.bind(this)}
                      >
                        <Link style={{ color: "white" }} to={"/"}>
                          <span style={{ color: "white" }}>Survey QR</span>
                        </Link>
                      </a>

                      <a
                        style={{
                          background: "transparent",
                          border: "transparent",
                          cursor: "pointer",
                          paddingLeft: 5
                        }}
                        onClick={this.logout.bind(this)}
                      >
                        <span style={{ color: "white" }}>Logout</span>
                      </a>
                    </div>
                  </div>
                </div>
                      </nav>*/}

              <div id="wrapper">
                <Route
                  path="/"
                  exact
                  strict
                  render={() => {
                    return <Survey_QR />;
                  }}
                />
                <Route
                  path="/Help"
                  exact
                  strict
                  render={() => {
                    return <Help />;
                  }}
                />

                <Route
                  path="/ChangePassword"
                  exact
                  strict
                  render={() => {
                    return <Change_Password />;
                  }}
                />
              </div>
            </div>
          </BrowserRouter>
        );
      } else if (
        userInfo.dashboard_access.access === true &&
        userInfo.qr_access_granted.access === false
      ) {
        return (
          <BrowserRouter>
            <div>
              {/*<nav
                className="navbar navbar-expand static-top"
                style={{ background: "#311b92" }}
              >
                <img src={logo} className="App-logo" />

                <div class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                  <div class="input-group">
                    <div class="input-group-append">
                      <a
                        style={{
                          background: "transparent",
                          border: "transparent",
                          cursor: "pointer"
                        }}
                        // onClick={this.logout.bind(this)}
                      >
                        <Link style={{ color: "white" }} to={"/"}>
                          <span style={{ color: "white" }}>Dashboard</span>
                        </Link>
                      </a>

                      <a
                        style={{
                          background: "transparent",
                          border: "transparent",
                          cursor: "pointer"
                        }}
                        onClick={this.logout.bind(this)}
                      >
                        <span style={{ color: "white" }}>Logout</span>
                      </a>
                    </div>
                  </div>
                </div>
                      </nav>*/}
              <Navbar
                collapseOnSelect
                expand="lg"
                style={{ backgroundColor: "#311c92" }}
                variant="dark"
              >
                <Navbar.Brand href="#home">
                  <img src={logo} className="App-logo" />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="mr-auto"></Nav>
                  <Nav>
                    <Nav.Link style={{ color: "white" }} href="" eventKey={1}>
                      <Link to={"/"}>
                        <span style={{ color: "white" }}>Dashboard</span>
                      </Link>
                    </Nav.Link>
                    <Nav.Link style={{ color: "white" }} eventKey={3}>
                      <Link style={{ color: "white" }} to={"/Help"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Help
                        </span>
                      </Link>
                    </Nav.Link>

                    <Nav.Link style={{ color: "white" }} eventKey={3}>
                      <Link style={{ color: "white" }} to={"/ChangePassword"}>
                        <span style={{ paddingLeft: 5, color: "white" }}>
                          Change Password
                        </span>
                      </Link>
                    </Nav.Link>

                    <Nav.Link
                      style={{ color: "white" }}
                      eventKey={2}
                      onClick={this.logout.bind(this)}
                    >
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Logout
                      </span>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <div id="wrapper">
                <Route
                  path="/"
                  exact
                  strict
                  render={() => {
                    return <Dashboard />;
                  }}
                />
                <Route
                  path="/Help"
                  exact
                  strict
                  render={() => {
                    return <Help />;
                  }}
                />
                <Route
                  path="/ChangePassword"
                  exact
                  strict
                  render={() => {
                    return <Change_Password />;
                  }}
                />
              </div>
            </div>
          </BrowserRouter>
        );
      }
      return (
        <BrowserRouter>
          <div>
            <Navbar
              collapseOnSelect
              expand="lg"
              style={{ backgroundColor: "#311c92" }}
              variant="dark"
            >
              <Navbar.Brand href="#home">
                <img src={logo} className="App-logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"></Nav>
                <Nav>
                  <Nav.Link style={{ color: "white" }} eventKey={3}>
                    <Link style={{ color: "white" }} to={"/Help"}>
                      <span style={{ paddingRight: 5, color: "white" }}>
                        Help
                      </span>
                    </Link>
                  </Nav.Link>

                  <Nav.Link style={{ color: "white" }} eventKey={3}>
                    <Link style={{ color: "white" }} to={"/ChangePassword"}>
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Change Password
                      </span>
                    </Link>
                  </Nav.Link>

                  <Nav.Link
                    style={{ color: "white" }}
                    eventKey={4}
                    onClick={this.logout.bind(this)}
                  >
                    <span style={{ color: "white" }}>Logout</span>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            {/*<nav
              className="navbar navbar-expand static-top"
              style={{ background: "#311b92" }}
            >
              <img src={logo} className="App-logo" />

              <div class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
                <div class="input-group">
                  <div class="input-group-append">
                    <a
                      style={{
                        background: "transparent",
                        border: "transparent",
                        cursor: "pointer"
                      }}
                      onClick={this.logout.bind(this)}
                    >
                      <span style={{ color: "white" }}>Logout</span>
                    </a>
                  </div>
                </div>
              </div>
                    </nav>*/}

            <Route
              path="/Help"
              exact
              strict
              render={() => {
                return <Help />;
              }}
            />

            <div id="wrapper">
              <Route
                path="/"
                exact
                strict
                render={() => {
                  return <Dashboard />;
                }}
              />
              <Route
                path="/ChangePassword"
                exact
                strict
                render={() => {
                  return <Change_Password />;
                }}
              />
            </div>
          </div>
        </BrowserRouter>
      );
    } else {
      return null;
    }
  }
}

export default NavigationBar;
