import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";
import { Button } from "react-bootstrap";
import moment from "moment";
import moment_timezone from "moment-timezone";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import Footer from ".././Footer";

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

class AddRedeemOffer extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      image_file: null,
      name: "",
      points_required: null,
      type: "",
      appearance: "",
      existing_offers: {},
      existing_partners: {},
      non_partners: {},
      existing_partners_list: {},
      rejected_partners: {},
      partner_add_from_non_partner_list: "",
      linked_card_status: [],
      request_update: "",
      number_of_linked_cards: "",
      total_points_earned: "",
      totalPoints: [],

      all_users: [],
      users_uid: [],
    };
  }

  componentWillMount() {
    var all_user_array = [];
    firebase
      .database()
      .ref(`Admin/List_Of_Users`)
      .once(
        "value",
        function(snapshot) {
          if (snapshot.val() !== null) {
            const users = _.map(snapshot.val(), (val, uid) => {
              return { uid };
            });
            this.setState({ users_uid: users });
            for (var x = 0; x < users.length; x++) {
              firebase
                .database()
                .ref(`users/${users[x].uid}`)
                .once(
                  "value",
                  function(snap) {
                    //   if (snap.val() !== null)
                    {
                      var i = "hi";
                      all_user_array.push(i);

                      var tempArray = {};

                      var total = 0;
                      var obj = snap.val().Survey_Submitted.pointsEarned;

                      var totalPoints = 0;
                      if (obj != null) {
                        var count = 0;
                        for (var property in obj) {
                          if (
                            Object.prototype.hasOwnProperty.call(obj, property)
                          ) {
                            count++;
                          }
                        }
                        var productsID = Object.keys(obj);
                        for (var i = 0; i < count; i++) {
                          var productDetails = obj[Object.keys(obj)[i]];
                          var points_gathered = productDetails.points;
                          total = total + points_gathered;
                        }
                        //  this.setState({ totalPoints: total });
                        totalPoints = total;
                      }

                      //console.log(totalPoints);

                      //all_user_array.length
                      if (
                        this.state.all_users[all_user_array.length - 1] ===
                        undefined
                      ) {
                        this.setState({
                          all_users: [
                            ...this.state.all_users,
                            { data: snap.val(), totalPoints },
                          ],
                        });
                      } else {
                        var newArray = [...this.state.all_users];
                        newArray[this.state.all_users] = {
                          data: snap.val(),
                          totalPoints,
                        };
                        this.setState({ all_users: newArray });
                      }
                    }
                  }.bind(this),
                  function(error) {
                    console.log(error);
                  }.bind(this)
                );
            }
          }
        }.bind(this),
        function(error) {
          console.log(error);
        }.bind(this)
      );
  }
  buttonPress(data_object) {
    return (
      <Button
        variant="success" //onClick={this.handleShow}
        onClick={() => {
          console.log(data_object);
        }}
      >
        Send Notification
      </Button>
    );
  }
  render() {
    //console.log(all_users.length);
    // console.log(this.state.all_users);
    var data_array = [];

    if (this.state.users_uid.length === this.state.all_users.length) {
      if (this.state.all_users.length !== 0) {
        const { all_users } = this.state;
        for (let i = 0; i < all_users.length; i++) {
          //  console.log(all_users);
          var name = all_users[i].data.userDetails.displayName;
          var phoneNumber = all_users[i].data.userDetails.phoneNumber;
          var card_status = "";
          var number_of_linked_cards = "";
          var total_number_of_cards_linked_by_user = all_users[i].totalPoints;
          var surveys_expired_variable = "-";
          var surveys_filled_variable = "-";
          var pushNotificationToken_variable = "-";
          var surveys_filled_date_variable = "";

          //surveys_expired,surveys_filled

          const Linked_Cards = _.map(
            all_users[i].data.plaid_link_card,
            (val, uid) => {
              return { uid, val };
            }
          );
          // console.log(Linked_Cards);

          if (Linked_Cards.length === 0) {
            card_status = "-";
            number_of_linked_cards = "-";
          } else {
            surveys_expired_variable = _.map(
              all_users[i].data.expired_survey,
              (val, uid) => {
                return { uid, val };
              }
            ).length;

            surveys_filled_variable =
              _.map(
                all_users[i].data.Survey_Submitted.pointsEarned,
                (val, uid) => {
                  return { uid, val };
                }
              ).length - 2;

            // card_status=""
            // total_number_of_cards_linked_by_user=""

            card_status = "Linked";
            number_of_linked_cards = Linked_Cards.length;
            for (var g = 0; g < Linked_Cards.length; g++) {
              if (Linked_Cards[g].val.status === "Unlinked") {
                card_status = "Unlinked";
              }
            }

            surveys_filled_date_variable = moment(
              _.map(
                all_users[i].data.Survey_Submitted.pointsEarned,
                (val, uid) => {
                  return val.survey_submitted_on;
                }
              )[
                _.map(
                  all_users[i].data.Survey_Submitted.pointsEarned,
                  (val, uid) => {
                    return val.survey_submitted_on;
                  }
                ).length - 1
              ]
            ).from(moment_timezone.tz("America/Toronto").format("YYYY-MM-DD"));
          }

          /* console.log(
            _.map(
              all_users[i].data.Survey_Submitted.pointsEarned,
              (val, uid) => {
                return { val };
              }
            )[surveys_filled_variable + 1] //.val.survey_submitted_on
          );*/

          if (all_users[i].data.pushNotificationToken !== undefined) {
            pushNotificationToken_variable =
              all_users[i].data.pushNotificationToken.token;
          }
          var data_object = {
            id_field: i + 1,
            name_field: name,
            phone_number_field: phoneNumber,
            linked_card_status_field: card_status,
            number_of_linked_cards: number_of_linked_cards,
            total_points_earned_field: total_number_of_cards_linked_by_user,
            surveys_filled: surveys_filled_variable,
            surveys_expired: surveys_expired_variable,
            last_survey_filled_out: surveys_filled_date_variable,

            user_email: all_users[i].data.userDetails.email,
            push_notification_token: pushNotificationToken_variable,
          };
          data_array.push({
            id_field: i + 1,
            name_field: name,
            phone_number_field: phoneNumber,
            linked_card_status_field: card_status,
            number_of_linked_cards: number_of_linked_cards,
            total_points_earned_field: total_number_of_cards_linked_by_user,
            surveys_filled: surveys_filled_variable,
            surveys_expired: surveys_expired_variable,
            last_survey_filled_out: surveys_filled_date_variable,

            user_email: all_users[i].data.userDetails.email,
            push_notification_token: pushNotificationToken_variable,
            button_test: this.buttonPress(data_object),
          });
        }
      }
    }

    return (
      <React.Fragment>
        <div id="content-wrapper">
          <div class="container">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">Users</li>
            </ol>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-lg-12">
                <div className="card mb-3">
                  <div className="card-header">
                    <h3>App Users</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <ToolkitProvider
                        keyField="id"
                        data={data_array}
                        columns={[
                          {
                            dataField: "id_field",
                            text: "ID",
                            sort: true,
                          },
                          {
                            dataField: "name_field",
                            text: "Name",
                            sort: true,
                          },
                          {
                            dataField: "phone_number_field",
                            text: "Phone#",
                            sort: true,
                          },
                          {
                            dataField: "linked_card_status_field",
                            text: "Linked Card Status",
                            sort: true,
                          },
                          {
                            dataField: "number_of_linked_cards",
                            text: "# of Linked Cards",
                            sort: true,
                          },
                          {
                            dataField: "total_points_earned_field",
                            text: "Total Points Earned",
                            sort: true,
                          },
                          {
                            dataField: "surveys_filled",
                            text: "Surveys Done",
                            sort: true,
                          },
                          {
                            dataField: "surveys_expired",
                            text: "Surveys Expired",
                            sort: true,
                          },
                          {
                            dataField: "last_survey_filled_out",
                            text: "Last Survey Filled",
                            sort: true,
                          },
                          {
                            dataField: "user_email",
                            text: "Email",
                            sort: true,
                          },
                          {
                            dataField: "push_notification_token",
                            text: "Notification Token",
                            sort: true,
                          },
                          {
                            dataField: "button_test",
                            text: "Send Notification",
                            sort: true,
                          },
                        ]}
                        exportCSV
                        search
                      >
                        {(props) => (
                          <div>
                            <Button
                              variant="outline-secondary"
                              style={{ padding: 0 }}
                            >
                              <ExportCSVButton {...props.csvProps}>
                                Export CSV!!
                              </ExportCSVButton>
                            </Button>

                            <SearchBar {...props.searchProps} />

                            <hr />
                            <BootstrapTable {...props.baseProps} />
                          </div>
                        )}
                      </ToolkitProvider>
                    </div>
                  </div>
                  <div className="card-footer small text-muted">Users</div>
                </div>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default AddRedeemOffer;
