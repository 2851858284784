import _ from "lodash";
import React, { Component } from "react";
import Footer from ".././Footer";
import * as firebase from "firebase";
import ReactPlayer from "react-player";

class Help_Institute extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      somehting: "Help_Institute",
      videos: {}
    };
  }
  componentWillMount() {
    firebase
      .database()
      .ref(`Admin/Tutorials/Institute`)
      .on(
        "value",
        function(snapshot) {
          //

          this.setState({ videos: snapshot.val() });
        }.bind(this)
      );
  }
  render() {
    const videos_data = _.map(this.state.videos, (val, uid) => {
      return val;
    });

    var item = [];

    if (videos_data.length !== 0) {
      for (var i = 0; i < videos_data.length; i++) {
        item.push(
          <div class="col-lg-6" key={i}>
            <div className="card mb-3">
              <div className="card-header">
                <h3>{videos_data[i].name}</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <ReactPlayer url={videos_data[i].url} width />
                </div>
              </div>
              <div className="card-footer small text-muted">Video</div>
            </div>
          </div>
        );
      }

      /*
    
    
          //  console.log(sum_array_number_of_responses);
                  item.push(
                    <div class="col-lg-6" key={i}>
                      <div className="card mb-3">
                        <div className="card-header">
                          <h3>{MCQ_Questions[i]}</h3>
                        </div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <Doughnut
                              data={{
                                labels: label_in_percentage, //MCQ_Array_col_answers_shrinked[i],
                                datalabels: {
                                  color: "black"
                                },
                                datasets: [
                                  {
                                    data: MCQ_Array_col_answers_count[i], //label_in_percentage, //MCQ_Array_col_answers_count[i],
                                    backgroundColor: array_color
                                  }
                                ]
                              }}
                              options={{ responsive: true }}
                            />
                          </div>
                        </div>
                        <div className="card-footer small text-muted">
                          Multiple Choice Response
                        </div>
                      </div>
                    </div>
                  );
                  
                  
                  */
    }

    return (
      <React.Fragment>
        <div id="content-wrapper">
          <div class="container">
            <ol class="breadcrumb">
              <li class="breadcrumb-item active">Tutorial Videos</li>
            </ol>
          </div>
          <div class="container">
            {/*  <div style={{ paddingBottom: 25 }}>
              <div
                className="card-header"
                style={{
                  paddingBottom: 25,
                  borderRadius: 10,
                  borderWidth: 1
                }}
              >
                <h3>Tutorial Videos</h3>
              </div>
              </div>*/}
            <div class="row">{item}</div>
          </div>

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Help_Institute;
