import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";
import NavigationBar_Admin from "././NavigationBar/NavigationBar_Admin";
import NavigationBar_Managers_Institute from "././NavigationBar/NavigationBar_Managers_Institute";
import NavigationBar_Facilitator_Institute from "././NavigationBar/NavigationBar_Facilitator_Institute";
import NavigationBar_Directors_Institute from "././NavigationBar/NavigationBar_Directors_Institute";
import NavigationBar_Managers_Retail from "././NavigationBar/NavigationBar_Managers_Retail";
import NavigationBar_Directors_Retail from "././NavigationBar/NavigationBar_Directors_Retail";
import NavigationBar_Facilitator_Retail from "././NavigationBar/NavigationBar_Facilitator_Retail";

class NavigationBar extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user_data: null
    };
  }

  componentWillMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var user_data = snapshot.val();
          if (user_data === null) {
            firebase
              .database()
              .ref(`Admin/admin_user/${user.uid}`)
              .on(
                "value",
                function(snapData) {
                  if (snapData.val() === null) {
                    this.logout();
                  } else {
                    user_data = snapData.val();
                    this.setState({ user_data });
                  }
                }.bind(this)
              );
          } else {
            this.setState({ user_data });
          }
        }.bind(this)
      );
  }

  logout() {
    firebase
      .auth()
      .signOut()
      .then(
        function() {
          // Sign-out successful.
          console.log("success");
        },
        function(error) {
          alert(error);
        }
      );
  }

  render() {
    const { user_data } = this.state;
    if (user_data !== null) {
      if (user_data.user_type === "institute") {
        if (user_data.role === "Program Manager") {
          return <NavigationBar_Managers_Institute />;
        } else if (user_data.role === "Director") {
          return <NavigationBar_Directors_Institute />;
        } else {
          return <NavigationBar_Facilitator_Institute />;
        }
      } else if (user_data.user_type === "retail") {
        if (user_data.role === "Program Manager") {
          return <NavigationBar_Managers_Retail />;
        } else if (user_data.role === "Director") {
          return <NavigationBar_Directors_Retail />;
        } else {
          return <NavigationBar_Facilitator_Retail />;
        }
      } else if (user_data.userStatus.role === "admin") {
        return <NavigationBar_Admin />;
      }
    }
    return <div></div>;
  }
}

/*
 if (user_data.userStatus.role === "admin") {
        return <NavigationBar_Admin />;
      }
      
*/

export default NavigationBar;
