import _ from "lodash";
import React, { Component } from "react";
import * as firebase from "firebase";

import Unlink_Feedback from "././Unlink_Feedback/Unlink_Feedback";

class Help extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      user_data: {},
    };
  }

  componentWillMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var user_data = snapshot.val();

          if (user_data === null) {
            firebase
              .database()
              .ref(`Admin/admin_user/${user.uid}`)
              .on(
                "value",
                function(snapData) {
                  if (snapData.val() === null) {
                    this.logout();
                  } else {
                    user_data = snapData.val();
                    this.setState({ user_data });
                  }
                }.bind(this)
              );
          } else {
            this.setState({ user_data });
          }
        }.bind(this)
      );
  }

  render() {
    const { user_data } = this.state;

    return <Unlink_Feedback />;
  }
}

export default Help;

/*import _ from "lodash";
import React, { Component } from "react";
import Footer from "./Footer";
import * as firebase from "firebase";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
  CSVExport
} from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";
import moment from "moment";
const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

class Access_Page extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      data: {},
      partial_url: "",
      list_of_access_items: {},
      user_data: {},
      selected_program: ""
    };
  }

  componentDidMount() {
    var user = firebase.auth().currentUser;
    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var user_data = snapshot.val();
          this.setState({ user_data });
        }.bind(this)
      );
  }
  render_td_QR_Access({ access_granted, i, pushID }) {
    return (
      <div className="form-group">
        <select
          className="form-control"
          value={access_granted}
          onChange={event => {
            event.preventDefault();

            firebase
              .database()
              .ref(`${this.state.partial_url}/${pushID}`)
              .update({ access_granted: event.target.value })
              .then(() => {
                console.log("working");
              })
              .catch(err => {
                console.log(err);
              });
            //event.target.value
          }}
        >
          <option value="true">Give QR Code Access</option>
          <option value="false">Revoke QR Code Access</option>
        </select>
      </div>
    );
  }

  render_td_dashboard({ dashboard_access, i, pushID }) {
    return (
      <div className="form-group">
        <select
          className="form-control"
          value={dashboard_access}
          onChange={event => {
            event.preventDefault();

            firebase
              .database()
              .ref(`${this.state.partial_url}/${pushID}`)
              .update({ dashboard_access: event.target.value })
              .then(() => {
                console.log("working");
              })
              .catch(err => {
                console.log(err);
              });
            //event.target.value
          }}
        >
          <option value="true">Give Dashboard Access</option>
          <option value="false">Revoke Dashboard Access</option>
        </select>
      </div>
    );
  }

  render_program_names({ i, pushID, data }) {
    var items = [];
    const { list_of_access_items } = this.state;
    for (var p = 0; p < list_of_access_items.length; p++) {
      items.push(
        <option value={list_of_access_items[p]}>
          {list_of_access_items[p]}
        </option>
      );
    }

    return (
      <div className="form-group">
        <select
          className="form-control"
          //  value={dashboard_access}
          onChange={event => {
            event.preventDefault();
            if (event.target.value !== "") {
              firebase
                .database()
                .ref(`${this.state.partial_url}/${pushID}/added_programs/`)
                .push({ access: event.target.value })
                .then(() => {
                  console.log("working");
                })
                .catch(err => {
                  console.log(err);
                });
            }
          }}
        >
          <option value="">Please Choose...</option>
          {items}
        </select>
      </div>
    );
  }
  render_added_programs_list({ p, access, pushID, added_programs_id }) {
    return (
      <div class="input-group mb-1" key={p}>
        <div class="input-group-prepend">
          <span class="input-group-text">{access}</span>
        </div>

        <div class="input-group-append">
          <button
            onClick={() => {
              //console.log(pushID, access);

              firebase
                .database()
                .ref(
                  `${this.state.partial_url}/${pushID}/added_programs/${added_programs_id[p]}`
                )
                .remove()
                .then(() => {
                  console.log("working");
                })
                .catch(err => {
                  console.log(err);
                });
            }}
          >
            <div class="close" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </div>
          </button>
        </div>
      </div>
    );
  }
  render_added_programs({ i, pushID, data }) {
    var added_programs = _.map(data.added_programs, (val, uid) => {
      return val;
    });
    var added_programs_id = _.map(data.added_programs, (val, uid) => {
      return uid;
    });

    if (added_programs.length === 0) {
      return null;
    }

    var items = [];
    for (var p = 0; p < added_programs.length; p++) {
      items.push(
        <div>
          {this.render_added_programs_list({
            p,
            access: added_programs[p].access,
            pushID,
            added_programs_id
          })}
        </div>
      );
    }

    return items;
  }
  render() {
    const { user_data } = this.state;
    const { data } = this.state;

    const data_initial_pull_val = _.map(data, (val, uid) => {
      return val;
    });

    //Initial Pull Start
    const data_initial_pull = _.map(data, (val, uid) => {
      return uid;
    });

    if (user_data.all_programs !== undefined) {
      const all_programs = _.map(user_data.all_programs, (val, uid) => {
        return val;
      });

      var list_of_programs = [];
      for (var p = 0; p < all_programs.length; p++) {
        list_of_programs.push(
          <option value={all_programs[p].access}>
            {all_programs[p].access}
          </option>
        );
      }

      if (this.state.selected_program === "") {
        return (
          <React.Fragment>
            <div id="content-wrapper">
              <div class="container">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active">Access Page</li>
                </ol>
              </div>

              <div class="container">
                <div className="form-group">
                  <label style={{ paddingBottom: 2 }}>
                    Select the Program:
                  </label>
                  <select
                    className="form-control"
                    value={this.state.selected_program}
                    onChange={event => {
                      event.preventDefault();

                      var user = firebase.auth().currentUser;

                      firebase
                        .database()
                        .ref(`partner/users/${user.uid}`)
                        .on(
                          "value",
                          function(snapshot) {
                            var database_val = snapshot.val();
                            //console.log(database_val);
                            if (database_val.role === "Program Manager") {
                              var partial_url =
                                "partner/user_access/" +
                                database_val.user_type +
                                "/" +
                                database_val.company_or_institution_name +
                                "/" +
                                event.target.value +
                                "/Facilitator";
                              this.setState({ partial_url });

                              firebase
                                .database()
                                .ref(
                                  `partner/user_access/${database_val.user_type}/${database_val.company_or_institution_name}/${event.target.value}/Facilitator`
                                )
                                .on(
                                  "value",
                                  function(snapshot) {
                                    var data = snapshot.val();

                                    this.setState({ data });
                                  }.bind(this)
                                );

                              firebase
                                .database()
                                .ref(
                                  `QR_Code/questionnaire/${database_val.company_or_institution_name}/${event.target.value}/`
                                )
                                .on(
                                  "value",
                                  function(snap) {
                                    const list_of_access_items = _.map(
                                      snap.val(),
                                      (val, uid) => {
                                        return uid;
                                      }
                                    );
                                    this.setState({ list_of_access_items });
                                  }.bind(this)
                                );
                            } else if (database_val.role === "Director") {
                              var partial_url =
                                "partner/user_access/" +
                                database_val.user_type +
                                "/" +
                                database_val.company_or_institution_name +
                                "/" +
                                event.target.value +
                                "/Program Manager";
                              this.setState({ partial_url });

                              firebase
                                .database()
                                .ref(
                                  `partner/user_access/${database_val.user_type}/${database_val.company_or_institution_name}/${event.target.value}/Program Manager`
                                )
                                .on(
                                  "value",
                                  function(snapshot) {
                                    var data = snapshot.val();

                                    this.setState({ data });
                                  }.bind(this)
                                );

                              var list_of_access_items = [];
                              list_of_access_items[0] = event.target.value;

                              this.setState({ list_of_access_items });
                            } else {
                              console.log("WRITE SOMETHING HERE");
                            }
                          }.bind(this)
                        );

                      this.setState({
                        selected_program: event.target.value
                      });
                    }}
                  >
                    <option selected value="">
                      Select....
                    </option>
                    {list_of_programs}
                  </select>
                </div>
              </div>

              <Footer />
            </div>
          </React.Fragment>
        );
      } else {
        var items = [];
        for (var i = 0; i < data_initial_pull_val.length; i++) {
          items.push(
            <tr key={i}>
              <td>{data_initial_pull_val[i].name}</td>
              <td>
                {this.render_td_QR_Access({
                  access_granted: data_initial_pull_val[i].access_granted,
                  i,
                  pushID: data_initial_pull[i]
                })}
              </td>
              <td>
                {this.render_td_dashboard({
                  dashboard_access: data_initial_pull_val[i].dashboard_access,
                  i,
                  pushID: data_initial_pull[i]
                })}
              </td>
              <td>
                {this.render_program_names({
                  i,
                  pushID: data_initial_pull[i],
                  data: data_initial_pull_val[i]
                })}
                <div class="rows">
                  {this.render_added_programs({
                    i,
                    pushID: data_initial_pull[i],
                    data: data_initial_pull_val[i]
                  })}
                </div>
              </td>
            </tr>
          );
        }

        return (
          <React.Fragment>
            <div id="content-wrapper">
              <div class="container">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item active">Access Page</li>
                </ol>
              </div>

              <div class="container text-center">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">QR Code Access</th>
                      <th scope="col">Dashboard Access</th>
                      <th scope="col">Program Names</th>
                    </tr>
                  </thead>
                  <tbody>{items}</tbody>
                </table>
              </div>

              <Footer />
            </div>
          </React.Fragment>
        );
      }
    }

    return (
      <React.Fragment>
        <div id="content-wrapper">
          <div class="container text-center">
            <p style={{ color: "red" }}>
              Your access is still in pending. Please ask your
              manager/supervisor for authorization
            </p>
          </div>

          <Footer />
        </div>
      </React.Fragment>
    );
  }
}

export default Access_Page;
*/
