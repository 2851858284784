import _ from "lodash";
import React, { Component } from "react";
import logo from ".././logo.svg";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Survey_QR from ".././Survey_QR";
import Dashboard from ".././Dashboard";
import Attendance from ".././Attendance";

import "../../vendor/sb-admin.css";
import Access_Page from ".././Access_Page";
import * as firebase from "firebase";

import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import Help from ".././Help";

import Create_Survey from ".././Create_Survey";
import Change_Password from ".././Change_Password";

class NavigationBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userInfo: "",
    };
  }

  static defaultProps = {
    displayTitle: true,
    displayLegend: true,
    legendPosition: "right",
    location: "City",
  };

  componentWillMount() {
    var user = firebase.auth().currentUser;

    firebase
      .database()
      .ref(`partner/users/${user.uid}`)
      .on(
        "value",
        function(snapshot) {
          var userInfo = snapshot.val();
          if (userInfo === null) {
            this.logout();
          }
          /* if (
            userInfo.user_type !== "institute" ||
            userInfo.user_type !== "retail"
          ) {
            this.logout();
          }*/
          this.setState({ userInfo });
        }.bind(this)
      );
  }
  logout() {
    firebase
      .auth()
      .signOut()
      .then(
        function() {
          // Sign-out successful.
          console.log("success");
        },
        function(error) {
          alert(error);
        }
      );
  }

  render() {
    const { userInfo } = this.state;
    //  return null;
    if (userInfo.user_type === "retail") {
      return (
        <BrowserRouter>
          <div>
            <Navbar
              collapseOnSelect
              expand="lg"
              style={{ backgroundColor: "#311c92" }}
              variant="dark"
            >
              <Navbar.Brand
              //href="#home"
              >
                <img src={logo} className="App-logo" />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className="mr-auto"></Nav>
                <Nav>
                  <Nav.Link style={{ color: "white" }} href="" eventKey={1}>
                    <Link to={"/"}>
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Dashboard
                      </span>
                    </Link>
                  </Nav.Link>

                  {/* <Nav.Link style={{ color: "white" }} eventKey={3}>
                    <Link style={{ color: "white" }} to={"/Help"}>
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Help
                      </span>
                    </Link>
                  </Nav.Link>*/}

                  <Nav.Link style={{ color: "white" }} eventKey={3}>
                    <Link style={{ color: "white" }} to={"/ChangePassword"}>
                      <span style={{ paddingLeft: 5, color: "white" }}>
                        Change Password
                      </span>
                    </Link>
                  </Nav.Link>

                  <Nav.Link
                    style={{ color: "white" }}
                    eventKey={4}
                    onClick={this.logout.bind(this)}
                  >
                    <span style={{ paddingLeft: 5, color: "white" }}>
                      Logout
                    </span>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>

            <div id="wrapper">
              <Route
                path="/"
                exact
                strict
                render={() => {
                  return <Dashboard />;
                }}
              />

              <Route
                path="/Help"
                exact
                strict
                render={() => {
                  return <Help />;
                }}
              />
              <Route
                path="/ChangePassword"
                exact
                strict
                render={() => {
                  return <Change_Password />;
                }}
              />
            </div>
          </div>
        </BrowserRouter>
      );
    } else {
      return null;
    }
  }
}

export default NavigationBar;
